import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon/Icon'
import green from '@material-ui/core/colors/green'
import amber from '@material-ui/core/colors/amber'

const variantIcon = {
  success: 'check_circle',
  warning: 'warning',
  error: 'error',
  info: 'info',
}

const styles1 = (theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
})

function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props
  const iconName = variantIcon[variant]

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)}>
            {iconName}
          </Icon>
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <Icon className={classNames(classes.icon)}>close</Icon>
        </IconButton>,
      ]}
      {...other}
    />
  )
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
}

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent)

const styles2 = (theme) => ({
  margin: {
    margin: theme.spacing.unit,
  },
})

class CustomSnackbar extends React.Component {
  constructor(props) {
    super()

    this.state = {}
  }

  static defaultProps = {
    open: true,
    variant: 'sucess',
    text: 'my message',
  }

  componentDidMount() {
    this.setState({
      open: !!this.props.open,
    })
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    this.setState({
      open: false,
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      this.setState({
        open: !!this.props.open,
      })
    }
  }

  render() {
    const { alert } = this.props

    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={this.state.open}
          autoHideDuration={6000}
          onClose={this.handleClose}
        >
          <MySnackbarContentWrapper onClose={this.handleClose} {...alert} />
        </Snackbar>
      </div>
    )
  }
}

CustomSnackbar.propTypes = {
  classes: PropTypes.object.isRequired,
  alert: PropTypes.object.isRequired,
}

export default withStyles(styles2)(CustomSnackbar)

export { MySnackbarContentWrapper as CustomSnackBarContent }
