import React from 'react'
import Colors from './colors'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import { emphasize } from '@material-ui/core/styles/colorManipulator'
import { isIE } from '../../utils'

const UNIT = 12

let direction = 'ltr'
try {
  direction = document.body.getAttribute('dir')
} catch (e) {}

let breakpoints = createBreakpoints({})

const palette = {
  common: {
    white: Colors.White,
    black: Colors.Black,
  },
  secondary: {
    main: Colors.Aqua,
    dark: Colors.Aqua,
    contrastText: Colors.AquaContrast,
  },
  primary: {
    main: Colors.Black06,
    dark: Colors.Black,
    contrastText: Colors.Black,
  },
  error: {
    main: Colors.Red,
  },
  action: {
    selected: 'rgba(0, 0, 0, 0.05)',
    active: Colors.Black080,
  },
  text: {
    primary: Colors.Black080,
    secondary: Colors.Black080,
  },
}

const THEME = {
  palette,
  direction,
  typography: {
    useNextVariants: true,
    secondaryFontFamily: 'Cambay, Roboto',
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    body2: {
      fontSize: '0.875rem',
      lineHeight: 1.43,
      letterSpacing: '0.016rem',
      color: palette.text.primary,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.75,
      letterSpacing: '0.016rem',
      color: palette.text.secondary,
    },
    h6: {
      fontSize: '1.25rem',
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      lineHeight: 1.2,
      letterSpacing: '0.025rem',
      color: palette.text.primary,
      [breakpoints.down('xs')]: {
        fontSize: '1rem',
        letterSpacing: '0.016rem',
      },
    },
    h5: {
      fontSize: '1.25rem',
      fontFamily: 'Cambay, Roboto',
      fontWeight: 'bold',
      lineHeight: 1.4,
      letterSpacing: '0.313rem',
      textTransform: 'uppercase',
      [breakpoints.down('xs')]: {
        fontSize: '0.95rem',
        letterSpacing: '0.25rem',
      },
    },
    h4: {
      fontSize: '1.5rem',
      fontFamily: 'Cambay, Roboto',
      fontWeight: 'bold',
      letterSpacing: '0.375rem',
      lineHeight: 1.33,
      color: palette.text.primary,
      textTransform: 'uppercase',
      [breakpoints.down('xs')]: {
        fontSize: '1.125rem',
        letterSpacing: '0.275rem',
      },
    },
    h3: {
      fontSize: '2rem',
      fontFamily: 'Cambay, Roboto',
      fontWeight: 'bold',
      color: palette.text.primary,
      lineHeight: 1.25,
      letterSpacing: '0.5rem',
      textTransform: 'uppercase',
      [breakpoints.down('xs')]: {
        fontSize: '1.5rem',
        letterSpacing: '0.375rem',
      },
    },
    h2: {
      fontFamily: 'Cambay, Roboto',
      fontWeight: 'bold',
      fontSize: '2.5rem',
      letterSpacing: '0.625rem',
      lineHeight: 1.2,
      textTransform: 'uppercase',
      [breakpoints.down('xs')]: {
        fontSize: '1.875rem',
        letterSpacing: '0.5rem',
      },
    },
    h1: {
      fontFamily: 'Cambay, Roboto',
      fontWeight: 'bold',
      fontSize: '3.37rem',
      letterSpacing: '0.844rem',
      lineHeight: 1.19,
      textTransform: 'uppercase',
      [breakpoints.down('xs')]: {
        fontSize: '2.5rem',
        letterSpacing: '0.633rem',
      },
    },
    button: {
      fontSize: '0.875rem',
      lineHeight: 1.43,
      letterSpacing: '0.088rem',
      textAlign: 'center',
      textTransform: 'none',
      color: palette.text.primary,
    },
    caption: {
      fontSize: '0.75rem',
      lineHeight: 1.33,
      letterSpacing: '0.025rem',
      color: 'rgba(0,0,0,0.6)',
    },
    subtitle1: {
      fontFamily: 'Roboto',
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: '0.016rem',
      color: palette.text.secondary,
    },
    subtitle2: {
      fontFamily: 'Roboto',
      fontSize: '0.875rem',
      lineHeight: 1.71,
      letterSpacing: '0.016rem',
    },
    overline: {
      fontFamily: 'Roboto',
      fontSize: 10,
      lineHeight: 1.6,
      letterSpacing: '0.094rem',
    },
    numeric: {
      fontFamily: 'Cambay, Roboto',
      letterSpacing: 'normal',
    },
  },
  spacing: {
    unit: UNIT,
  },
  overrides: {
    MuiAppBar: {
      root: {
        boxShadow: 'none',
        padding: 0,
        paddingLeft: UNIT * 4,
      },
    },
    MuiStepConnector: {
      alternativeLabel: {
        top: 19,
      },
    },
    MuiStepper: {
      root: {
        paddingTop: 0,
        paddingBottom: UNIT,
      },
    },
    MuiChip: {
      root: {
        justifyContent: 'flex-start',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 4,
      },
      // outlined: {
      //   border: '1px solid',
      //   borderColor: Colors.Black012
      // }
    },
    MuiInput: {
      input: {
        fontSize: '1.0625rem',
        letterSpacing: 0.2,
        color: Colors.DarkGrey,
      },
      inputType: {
        height: 'inherit',
      },
      underline: {
        '&:before': {
          borderBottomColor: Colors.DarkGrey,
        },
        '&:after': {
          borderBottomColor: Colors.Brand,
        },
      },
    },
    MuiSelect: {
      select: {
        fontSize: 'inherit',
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: '1.0625rem',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '1.41em',
        letterSpacing: 0.2,

        '&$focused': {
          color: Colors.Brand,
        },
      },
      // '&$focused': {
      //   color: Colors.Brand,
      // },
    },
    MuiListItemIcon: {
      root: {
        marginRight: UNIT,
        color: Colors.DarkGrey,
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: Colors.Grey008,
        },
      },
    },
    MuiStepIcon: {
      root: {
        fontSize: '2.5rem',
      },
      active: {
        color: Colors.Grey + ' !important',
      },
      completed: {
        color: Colors.GrassGreen + ' !important',
      },
      text: {
        fill: Colors.White,
      },
    },
    MuiMenuItem: {
      root: {
        paddingLeft: UNIT * 2,
        '&$selected': {
          backgroundColor: Colors.Aqua020 + ' !important',
        },
      },
    },
    MuiButton: {
      outlinedPrimary: {
        color: Colors.TextBrand,
        borderColor: Colors.Black,
        '&:hover': {
          backgroundColor: emphasize(Colors.Black, 0.05),
          borderColor: Colors.Black,
        },
      },
      outlinedSecondary: {
        color: Colors.Black,
        borderColor: Colors.Black,
        '&:hover': {
          backgroundColor: emphasize(Colors.White, 0.05),
          borderColor: Colors.Black,
        },
      },
      outlined: {
        backgroundColor: Colors.White,
        borderColor: Colors.Black,
        textTransform: 'uppercase',
      },
      contained: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        padding: `${isIE ? UNIT : 8}px ${UNIT * 2}px`,
        backgroundColor: Colors.DarkGrey,
        color: Colors.White,
      },
      containedPrimary: {
        color: Colors.White,
        backgroundColor: Colors.Black,
        // boxShadow: 'none',
        '&:hover': {
          backgroundColor: emphasize(Colors.Black, 0.05),
        },
      },
      containedSecondary: {
        color: Colors.White,
        border: '1px solid',
        // boxShadow: 'none'
      },
      text: {
        boxShadow: 'none',
        fontFamily: 'Roboto',
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: 1.43,
        letterSpacing: 1,
      },
      textPrimary: {
        color: Colors.Black080,
        backgroundColor: Colors.White,
        '&:hover': {
          backgroundColor: Colors.Black005,
        },
      },
      textSecondary: {
        color: Colors.TextBrand,
        backgroundColor: Colors.White,
        '&:hover': {
          backgroundColor: Colors.Black005,
        },
      },
      sizeSmall: {
        fontSize: '0.93125rem',
      },
      label: {
        lineHeight: '1.08em',
      },
    },
    MuiToggleButton: {
      root: {
        backgroundColor: Colors.LightGrey,
        '&$selected': {
          color: Colors.Black,
          backgroundColor: 'transparent',
          opacity: 1,
          '&:after': {
            backgroundColor: Colors.White,
          },
        },
      },
    },
    MuiTouchRipple: {
      root: {
        color: Colors.White,
      },
    },
    MuiTableCell: {
      head: {
        fontSize: '0.85rem',
      },
      paddingDense: {
        [breakpoints.down('xs')]: {
          padding: `${UNIT / 3}px ${UNIT}px`,
          paddingRight: `${UNIT}px !important`,
        },
      },
    },
    MuiTab: {
      label: {
        fontWeight: 'bold',
      },
      root: {
        maxWidth: 'initial',
      },
      fullWidth: {
        flexShrink: 0,
      },
    },
    MuiTableRow: {
      head: {
        height: UNIT * 3,
      },
    },
    MuiTypography: {
      root: {
        '& a': {
          color: Colors.Brand,
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
    MuiExpansionPanel: {
      root: {
        '&:first-child': {
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
        },
        '&:last-child': {
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
        },
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        [breakpoints.down('xs')]: {
          padding: `${UNIT / 2}px ${UNIT}px ${UNIT}px`,
        },
      },
    },
    MuiExpansionPanelSummary: {
      expandIcon: {
        [breakpoints.down('xs')]: {
          right: 0,
        },
      },
    },
  },
}
const PortalTheme = React.createContext({
  theme: THEME,
  onThemeChange: () => {},
})

const withThemeSwitch = (Component) => (props) => {
  return (
    <PortalTheme.Consumer>
      {(onThemeChange) => (
        <Component {...props} onThemeChange={onThemeChange} />
      )}
    </PortalTheme.Consumer>
  )
}

// export default createMuiTheme(THEME)
export default PortalTheme
export { UNIT, THEME, withThemeSwitch }
