import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import Icon from '@material-ui/core/Icon'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Tooltip from '@material-ui/core/Tooltip'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import SelectWithIconButton from '../../atom/SelectWithIconButton'
import SelectMenuItem, { MenuProps } from '../../atom/SelectMenuItem'
import IconButton from '@material-ui/core/IconButton/IconButton'
import { getIconAndColorByStatus } from './StateHelper'

const styles = (theme) => ({
  head: {
    whiteSpace: 'nowrap',
  },
  actionCell: {
    width: theme.spacing.unit * 4,
  },
})

class EnhancedTableHead extends React.Component {
  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property)
  }

  handleEnumFilterChange = (col) => (event) => {
    let { value } = event.target

    if (!Array.isArray(value)) {
      const { selectedEnumFilters } = this.props
      let currentValue = selectedEnumFilters && selectedEnumFilters[col.name]
      // user clicked the same option so the selection is reset
      if (currentValue === value) value = undefined
    }

    const { onEnumFilterChangeRequested } = this.props
    onEnumFilterChangeRequested && onEnumFilterChangeRequested(col.name, value)
  }

  render() {
    const {
      classes,
      orderDir,
      orderBy,
      columnData,
      selectedColumns,
      selectedEnumFilters,
      isRowDeletable,
      isRowDuplicatable,
      isRowSelectable,
      onSelectAllRequested,
      numSelectedRows,
      numRows,
      supportedLanguages,
      selectedLanguage,
      visibleRows,
    } = this.props

    return (
      <TableHead>
        <TableRow>
          {isRowDeletable && (
            <TableCell padding="none" className={classes.actionCell} />
          )}

          {isRowDuplicatable && (
            <TableCell padding="none" className={classes.actionCell} />
          )}

          {isRowSelectable && (
            <TableCell padding="none" className={classes.actionCell}>
              <Checkbox
                disabled={numRows === 0}
                indeterminate={numSelectedRows > 0 && numSelectedRows < numRows}
                checked={numRows > 0 && numSelectedRows === numRows}
                onChange={(e) => onSelectAllRequested(e, visibleRows)}
              />
            </TableCell>
          )}

          {columnData.map((col) => {
            let {
              settings: { multilang } = {},
              name: colName,
              label: colLabel,
              ident = false,
              type: colType,
              options,
            } = col
            if (!selectedColumns.includes(colName)) {
              return null
            }

            let label = colLabel || colName
            if (multilang && supportedLanguages.length > 1)
              label = `${label} (${selectedLanguage.id})`

            let enumFitlter
            if (options) {
              let { settings: { variant } = {} } = col
              let isMultiple = !['date', 'datetime', 'timestamp'].includes(
                colType,
              )
              let selected = selectedEnumFilters && selectedEnumFilters[colName]
              let filterActive = isMultiple
                ? selected && selected.length > 0
                : selected !== undefined
              enumFitlter = (
                <SelectWithIconButton
                  multiple={isMultiple}
                  renderButton={({ classes }) => (
                    <IconButton className={classes.button}>
                      <Icon
                        style={
                          !filterActive
                            ? { color: `rgba(0, 0, 0, 0.26)` }
                            : null
                        }
                        className={classes.icon}
                      >
                        filter_list
                      </Icon>
                    </IconButton>
                  )}
                  value={isMultiple ? selected || [] : selected}
                  onChange={this.handleEnumFilterChange(col)}
                  MenuProps={MenuProps}
                >
                  {options.map((option) => {
                    let { value, label } = option
                    label = label || value

                    if (variant === 'status') {
                      const { icon, color } = getIconAndColorByStatus(value)
                      if (icon) {
                        label = (
                          <span
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Icon style={{ color, marginRight: 6 }}>
                              {icon}
                            </Icon>
                            {label}
                          </span>
                        )
                      }
                    } else if (colType === 'boolean') {
                      label = (
                        <Icon color={value ? 'primary' : 'disabled'}>
                          {value ? 'check' : 'close'}
                        </Icon>
                      )
                    }

                    return (
                      <SelectMenuItem
                        key={value}
                        checked={
                          isMultiple
                            ? selected && selected.includes(value)
                            : selected === value
                        }
                        value={value}
                        label={label}
                      />
                    )
                  })}
                </SelectWithIconButton>
              )
            }

            return (
              <TableCell
                key={colName}
                numeric={col.numeric}
                padding="dense"
                className={classes.head}
                sortDirection={
                  orderBy === colName
                    ? orderDir === -1
                      ? 'desc'
                      : 'asc'
                    : false
                }
              >
                {enumFitlter}
                <Tooltip
                  title="Sort"
                  placement={col.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === colName}
                    direction={orderDir === -1 ? 'desc' : 'asc'}
                    onClick={this.createSortHandler(colName)}
                  >
                    {ident === true && (
                      <Icon style={{ marginRight: 5, fontSize: 'inherit' }}>
                        vpn_key
                      </Icon>
                    )}
                    {label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            )
          }, this)}
        </TableRow>
      </TableHead>
    )
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  orderDir: PropTypes.number.isRequired,
  orderBy: PropTypes.string.isRequired,
  selectedColumns: PropTypes.array.isRequired,
}

export default withStyles(styles)(EnhancedTableHead)
