import React, { Component } from 'react'
import NumberField from '../../../shared/components/NumberField'

class CustomNumber extends Component {
  handleBlur = (event) => {
    const { onChange } = this.props
    onChange && onChange(event)
  }

  handleChange = (event) => {
    const { onUpdate } = this.props
    onUpdate && onUpdate(event.target.value)
  }

  render() {
    return (
      <NumberField
        {...this.props}
        value={this.props.value ?? 0}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
      />
    )
  }
}

export default CustomNumber
