/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react'
import FormHelperText from '@material-ui/core/FormHelperText'
import Typography from '@material-ui/core/Typography'

import EditorWrapper from './EditorWrapper'

export default function CustomRichText(props) {
  const { label, helperText, disabled, value, onUpdate, settings: { toolbar, brand } = {} } = props

  const handleUpdate = useCallback(
    (newValue) => {
      if (disabled || JSON.stringify(value) === JSON.stringify(newValue)) return
      onUpdate && onUpdate(newValue)
    },
    [disabled, onUpdate],
  )

  return (
    <>
      <Typography>{label}</Typography>
      <EditorWrapper
        brand={brand}
        content={value}
        editable={!disabled}
        toolbar={toolbar}
        onUpdate={handleUpdate}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </>
  )
}
