import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Avatar from '@material-ui/core/Avatar'

const styles = () => ({
  avatar: {
    boxShadow: `inset 0 0 0 1px rgba(0,0,0,0.25)`,
  },
})

const ColorDrop = ({ classes, className, color }) => {
  return (
    <Avatar
      className={`${className} ${classes.avatar}`}
      style={{ backgroundColor: color }}
    />
  )
}

export default withStyles(styles)(ColorDrop)
