import React from 'react'
import Grid from '@material-ui/core/Grid'
import DashboardTile from './DashboardTile'

const AdminDashboard = (props) => {
  const { data = [] } = props

  return (
    <Grid container justify="center" spacing={24} alignItems={'stretch'}>
      {data.map((item, k) => {
        return (
          <Grid item lg={item.columns} sm={12} md={item.columns > 6 ? item.columns : 6} key={k}>
            <DashboardTile {...item} />
          </Grid>
        )
      })}
    </Grid>
  )
}

export default AdminDashboard
