import React from 'react'
import StructuredJsonBlock from './StructuredJsonBlock'
import { getDefaultJsonValue } from './jsonFieldHelpers'

export default function StructuredJson(props) {
  const { name, value, onUpdate, settings: { schema, max } = {} } = props
  const jsonData = !value ? getDefaultJsonValue(schema) : value
  return (
    <div id={`StructuredJson_${name}`}>
      <StructuredJsonBlock jsonData={jsonData} name={name} schema={schema} max={max} onUpdate={onUpdate} />
    </div>
  )
}

