/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react'
import moment from 'moment'
import { Typography } from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import withStyles from '@material-ui/core/styles/withStyles'
import { CustomDate } from '../../../shared/components/atoms/CustomDatePicker/CustomPickers'
import { loadFiltersFromLocalStorage, storeFiltersToLocalStorage } from './atlasHelper'

const styles = () => ({
  root: {
    padding: '0 2.4rem 0.5rem 2.4rem',
    display: 'flex',
    alignItems: 'end',
    gap: '1rem',
    minHeight: '40px',
    background: 'linear-gradient(90deg, #EAF9FF 0%, #efefef 100%)',
  },
  title: {},
  dateContainer: { display: 'flex', gap: '1rem' },
  chip: { marginRight: '8px', textTransform: 'capitalize' },
})

function DashboardFilters({ dashboardId, dashboard, classes }) {
  const [filters, setFilters] = useState(() => {
    return loadFiltersFromLocalStorage(dashboardId)
  })

  const updateFilters = useCallback(
    (filters) => {
      setFilters((prevFilters) => {
        if (typeof filters === 'function') filters = filters(prevFilters)
        const newFilters = { ...prevFilters, ...filters }

        for (const [key, value] of Object.entries(newFilters)) {
          if (value === undefined) delete newFilters[key]
        }

        if (dashboard) dashboard.setFilter(newFilters)

        storeFiltersToLocalStorage(dashboardId, newFilters)

        return newFilters
      })
    },
    [dashboard],
  )

  const onDateChange = useCallback(
    ({ target: { name, value } }) => {
      let isStart = name === 'start'
      let date = value
        ? isStart
          ? new moment(value).startOf('day').toDate()
          : new moment(value).endOf('day').toDate()
        : null

      updateFilters((prevFilters) => {
        let dateObj = { ...prevFilters?.date }
        const key = isStart ? '$gte' : '$lt'
        if (date) dateObj[key] = date
        else delete dateObj[key]

        if (Object.keys(dateObj).length === 0) dateObj = undefined

        return { date: dateObj }
      })
    },
    [dashboard],
  )

  useEffect(() => {
    if (!dashboard) return

    let charts
    if (filters) dashboard.setFilter(filters)

    attachEventListeners().finally()

    return () => {
      if (!charts) return
      charts.forEach((chart) => chart.removeEventListener('click', handleClick))
    }

    async function attachEventListeners() {
      charts = await dashboard.getAllCharts()
      charts.forEach((chart) => chart.addEventListener('click', handleClick))
    }
  }, [dashboard])

  const handleClick = useCallback(
    ({ selectionFilter }) => {
      updateFilters(selectionFilter)
    },
    [dashboard],
  )

  const handleFilterRemove = useCallback(
    (field) => {
      updateFilters({ [field]: undefined })
    },
    [dashboard],
  )

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h5">
        Filters:
      </Typography>
      <div className={classes.dateContainer}>
        <CustomDate
          shrink={true}
          name="start"
          value={filters?.date?.$gte}
          label={'Start'}
          onChange={onDateChange}
        />
        <CustomDate name="end" value={filters?.date?.$lt} label={'End'} onChange={onDateChange} />
      </div>
      {filters &&
        Object.entries(filters)?.map((filter) => {
          let [field, value] = filter

          if (field === 'date') return null
          if (typeof value === 'boolean') value = field + ': ' + value.toString() // More explicit boolean filters
          return (
            <Chip
              key={field}
              className={classes.chip}
              label={value}
              onDelete={() => handleFilterRemove(field)}
              color="secondary"
            />
          )
        })}
    </div>
  )
}

export default withStyles(styles)(DashboardFilters)
