import React, { Component } from 'react'

import withStyles from '@material-ui/core/styles/withStyles'
import PaperBox from '../../atoms/PaperBox'
import FormElementsGroup from '../FormElementsGroup'

class Form extends Component {
  _fieldsRef

  render() {
    const {
      classes,
      title,
      buttonLabel,
      isSubmitting,
      elements,
      externalErrors,
    } = this.props

    return (
      <form noValidate className={classes.root}>
        <PaperBox
          title={title}
          pending={isSubmitting}
          buttonLabel={buttonLabel}
          onButtonClick={this._handleButtonClick}
          classes={{ headline: classes.headline }}
        >
          <FormElementsGroup
            externalErrors={externalErrors}
            elements={elements}
            innerRef={(ref) => {
              this._fieldsRef = ref
            }}
          />
        </PaperBox>
      </form>
    )
  }

  _handleButtonClick = (e) => {
    e.preventDefault()

    if (!this._fieldsRef || !this._fieldsRef.checkValidity()) {
      return
    }

    const { onSubmitClick } = this.props
    onSubmitClick && onSubmitClick(this._fieldsRef.getValues())
  }
}

const styles = () => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  headline: {
    textTransform: 'inherit',
    textAlign: 'center',
  },
})

export default withStyles(styles)(Form)
