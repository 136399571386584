import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import {
  emphasize,
  fade,
  lighten,
} from '@material-ui/core/styles/colorManipulator'
import Icon from '@material-ui/core/Icon'
import Chip from '@material-ui/core/Chip'

import { SortableHandle } from 'react-sortable-hoc'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import Colors from '../../shared/assets/styles/colors'
import { withLanguage } from '../../LanguageContext'

const styles = (theme) => {
  const iconColor = '#ffffff'
  const { unit } = theme.spacing
  const backgroundColor = lighten(theme.palette.secondary.main, 0.35)
  const backgroundColorV = lighten(Colors.CoolGrey, 0.35)

  return {
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: backgroundColor,
    },
    rootV: {
      backgroundColor: backgroundColorV,
    },
    variantSmall: {
      height: unit * 2,
    },
    chipLabel: {
      overflow: 'hidden',
    },
    labelContainer: {
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
    },
    label: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    clickable: {
      '&:hover': {
        backgroundColor: emphasize(backgroundColor, 0.12),
      },
      '&:focus': {
        backgroundColor: backgroundColor,
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: backgroundColor,
      },
    },
    clickableV: {
      '&:hover': {
        backgroundColor: emphasize(backgroundColorV, 0.12),
      },
      '&:focus': {
        backgroundColor: backgroundColorV,
      },
      '&:active': {
        backgroundColor: backgroundColorV,
      },
    },
    deleteIcon: {
      marginRight: unit * 0.5,
      color: fade(iconColor, 0.7),
      '&:hover': {
        color: iconColor,
      },
    },
    icon: {
      color: fade(Colors.Grey, 0.6),
      marginRight: unit * 0.25,
    },
    notVisible: {
      backgroundColor: Colors.Orange,
      '&:hover': {
        backgroundColor: emphasize(Colors.Orange, 0.12),
      },
      '&:focus': {
        backgroundColor: emphasize(Colors.Orange, 0.12),
      },
    },
    unpublished: {
      backgroundColor: Colors.Red,
      '&:hover': {
        backgroundColor: emphasize(Colors.Red, 0.12),
      },
      '&:focus': {
        backgroundColor: emphasize(Colors.Red, 0.12),
      },
    },
  }
}

const DragHandle = withStyles(styles)(
  SortableHandle(({ classes }) => (
    <Icon
      onMouseDown={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
      className={classes.deleteIcon}
    >
      drag_handle
    </Icon>
  )),
)

const translateItem = (element, selectedLanguage) => {
  return {
    ...element,
    title: element[`title__${selectedLanguage.id}`] || element['title'],
    _name: element[`_name__${selectedLanguage.id}`] || element['_name'],
  }
}

const ReferenceChip = withLanguage(
  withStyles(styles)(
    ({
      classes,
      className,
      style,
      value,
      settings = {},
      onDelete,
      sortable,
      link = true,
      variant = 'normal',
      selectedLanguage,
      
      // skipped properties
      supportedLanguages,
      registerLanguageSwitchInterceptor,
      onLanguageChange,

      ...rest
    }) => {
      const { collection, detail, popup } = settings

      let {
        name,
        _name,
        title = _name || name,
        _id,
        publishedAt,
        enabled,
      } = translateItem(value, selectedLanguage)

      const linkProps = {}
      if (link && collection && style !== 'vreference') {
        linkProps.component = Link

        // TODO we need a better check
        if (popup || collection.startsWith('mod')) {
          linkProps.to = `#~/${detail || collection}/${_id}?page=true`
        } else linkProps.to = `/~/${detail || collection}/${_id}`
      }

      const deleteProps = {}
      if (onDelete) {
        deleteProps.onDelete = (e) => {
          e.preventDefault()
          e.stopPropagation()
          onDelete && onDelete(e)
        }
      }

      // console.log('ReferenceChip., Line 146: rest >', rest)

      const isVReference = style === 'vreference'
      const unpublished = publishedAt === null
      const notVisible = !unpublished && enabled === false

      return (
        <Chip
          classes={{
            root: classNames(classes.root, { [classes.rootV]: isVReference }),
            label: classes.chipLabel,
            deleteIcon: classes.deleteIcon,
            clickable: classNames(classes.clickable, {
              [classes.clickableV]: isVReference,
            }),
          }}
          className={classNames(className, {
            [classes.variantSmall]: variant === 'small',
            [classes.unpublished]: unpublished,
            [classes.notVisible]: !unpublished && notVisible,
          })}
          clickable
          onClick={(e) => {
            e.stopPropagation()
          }}
          label={
            <div className={classes.labelContainer}>
              {sortable && <DragHandle />}
              {unpublished && (
                // TODO : label ids shouldn't be hardcoded
                <Icon
                  title={'No published version'}
                  fontSize="small"
                  className={classes.icon}
                >
                  error
                </Icon>
              )}
              {notVisible && (
                <Icon
                  title={'Not visible'}
                  fontSize="small"
                  className={classes.icon}
                >
                  visibility_off
                </Icon>
              )}
              <span className={classNames(classes.label)}>
                {_name || title}
              </span>
            </div>
          }
          {...deleteProps}
          {...linkProps}
          {...rest}
        />
      )
    },
  ),
)

export { translateItem }
export default ReferenceChip
