import React from 'react'
import ModelList from '../ModelList/ModelList'
import ModelDetails from '../ModelDetails/ModelDetails'
import withStyles from '@material-ui/core/styles/withStyles'
import ModelInfoTile from '../../molecules/ModelInfoTile/ModelInfoTile'
import ACTION_NAMES from './actionNames'
import CustomMedia from '../../atom/FieldFactory/CustomMedia/CustomMedia'
import ModelStats from './ModelStats'

const findAction = (actions, actionName) =>
  actions && actions.find(({ action }) => action === actionName)

class ModelViewWrapper extends React.Component {
  constructor() {
    super()
    this.state = { statsData: null }
  }

  componentDidMount() {
    const { statsdata } = this.props
    this.setState({ statsData: statsdata })
  }

  renderList = (hasDetails) => {
    let { classes, elements, actions, columnData, type, ...rest } = this.props

    elements = elements || (columnData && { actions, columnData })

    if (elements) {
      if (type === 'media') {
        return <CustomMedia isRichText isList />
      }

      return (
        <ModelList
          className={classes.item}
          {...elements}
          {...rest}
          onAPICall={this.updateStats}
        />
      )
    }

    return null
  }

  updateStats = (data) => {
    const newStats = (data && data.statsdata) || null
    this.setState({ statsData: newStats })
  }

  render() {
    let {
      classes,
      infoTile,
      structure,
      rawData,
      schemeData,
      actions,
      versionHistory,
      ...rest
    } = this.props

    const { statsData } = this.state
    const hasDetails = structure && rawData && schemeData
    const details = { structure, rawData, schemeData, actions, versionHistory }

    return (
      <React.Fragment>
        {infoTile && <ModelInfoTile {...infoTile} className={classes.item} />}

        {statsData && <ModelStats {...statsData} className={classes.item} />}

        {hasDetails && (
          <ModelDetails className={classes.item} {...details} {...rest} />
        )}

        {this.renderList(hasDetails)}
      </React.Fragment>
    )
  }
}

const styles = ({ spacing: { unit } }) => ({
  item: {
    marginBottom: unit,
    '&:last-child': {
      marginBottom: 0,
    },
  },
})

const processActionRequest = async (action, api, data) => {
  const { action: actionName, url } = action

  switch (actionName) {
    case ACTION_NAMES.GOTO: {
      const { target = '_blank' } = action
      if (target === 'download')
        window.location.href = url
      else
        window.open(url, target)
      return true
    }
    case ACTION_NAMES.DUPLICATE:
    case ACTION_NAMES.POST: {
      const { url, method = 'POST', confirm, addFields = [], prompt = [] } = action
      const { _id = '' } = data || {}
      const body = { _id }
      addFields.forEach((f) => (body[f] = data[f]))

      if (prompt) {
        const ask = Array.isArray(prompt) ? prompt : [prompt]
        for (const p of ask) {
          const { field = 'prompted', message = '', defaultValue } = p
          body[field] = window.prompt(message, defaultValue)
          if (!body[field]) return
        }
      }

      if (confirm) {
        const confirms = Array.isArray(confirm) ? confirm : [confirm]
        for (const c of confirms) {
          if (!window.confirm(c)) return
        }
      }

      return api.connector[method.toLowerCase()](url.replace('[id]', _id), body)
    }
    case ACTION_NAMES.DELETE_SINGLE:
    case ACTION_NAMES.DELETE: {
      if (!window.confirm('Are you sure you want to delete this?')) {
        return null
      }

      const { url, method = 'DELETE' } = action
      const { _id } = data

      return api.connector[method.toLowerCase()](url.replace('[id]', _id))
    }
    case ACTION_NAMES.SSOMETADATA:
    case ACTION_NAMES.DOWNLOAD: {
      const { url } = action
      const { _id } = data

      return api.download(url.replace('[id]', _id))
    }
    default:
      break
  }

  return false
}

export { processActionRequest, findAction }

export default withStyles(styles)(ModelViewWrapper)
