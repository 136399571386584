import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'
import logo from '../../../shared/assets/img/logo-black.svg'
import Colors from '../../../shared/assets/styles/colors'

const styles = ({ spacing: { unit }, breakpoints }) => ({
  logo: {
    marginTop: unit * 0.25,
    marginLeft: unit,
    position: 'relative',
    alignSelf: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    [breakpoints.up('md')]: {
      marginLeft: unit * 5,
    },
  },
  platformName: {
    flip: false,
    fontSize: '0.977em',
    color: Colors.Black,
    alignSelf: 'flex-end',
    bottom: 0,
  },
})

const Logo = ({ classes, platformName }) => (
  <Link
    to={'/'}
    className={classes.logo}
    style={{ flip: false, direction: 'ltr' }}
  >
    <img src={logo} alt="" />
    <Typography
      className={classes.platformName}
      variant={'caption'}
      color={'primary'}
    >
      {platformName}
    </Typography>
  </Link>
)

export default withStyles(styles)(Logo)
