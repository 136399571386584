import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'

const styles = {
  root: {
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
  },
}

class ScrollToBottom extends React.Component {
  state = {
    scrolledToBottom: false,
  }

  containerRef = React.createRef()

  componentDidMount() {
    this._check()
  }

  _check = (target) => {
    const { scrolledToBottom } = this.state

    let div = this.containerRef.current || target

    if (!scrolledToBottom && div) {
      if (Math.ceil(div.scrollTop + div.offsetHeight) >= div.scrollHeight) {
        this.setState({ scrolledToBottom: true })

        const { onScrolledToBottom } = this.props
        if (onScrolledToBottom) onScrolledToBottom()
      }
    }
  }

  _handleScroll = (e) => {
    this._check(e.currentTarget)
  }

  render() {
    const { classes, className, children, component, ...rest } = this.props
    const { scrolledToBottom } = this.state

    const Component = component || 'div'

    return (
      <Component
        className={classNames(classes.root, className)}
        ref={this.containerRef}
        onScroll={!scrolledToBottom ? this._handleScroll : undefined}
        {...rest}
      >
        {children}
      </Component>
    )
  }
}

export default withStyles(styles)(ScrollToBottom)
