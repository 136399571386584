import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import Icon from '@material-ui/core/Icon'
import InputAdornment from '@material-ui/core/InputAdornment'
import Grid from '@material-ui/core/Grid'
import SelectMenuItem, { MenuProps } from '../../atom/SelectMenuItem'
import MenuItem from '@material-ui/core/MenuItem'
import ModelActionBar from '../ModelActionBar/ModelActionBar'

const toolbarStyles = ({ spacing: { unit } }) => ({
  root: {
    padding: unit,
  },
  spacer: {
    flex: '1',
  },
  filters: {
    // height: '2.6rem',
    '&>*': {
      margin: '2px',
    },
  },
})

class EnhancedTableToolbar extends React.Component {
  handleColumnChange = (event) => {
    const { onColumnsChange } = this.props
    onColumnsChange && onColumnsChange(event.target.value)
  }

  handleSearchChange = (event) => {
    const { onSearchChange } = this.props
    onSearchChange && onSearchChange(event.target.value)
  }

  handleActionClick = (action) => {
    const { onActionRequested } = this.props

    if (onActionRequested) return onActionRequested(action)
  }

  handleFiltersSelect = (e) => {
    const {
      target: { value },
    } = e
    const { onSelectFiltersRequested } = this.props

    if (onSelectFiltersRequested) onSelectFiltersRequested(value)
  }

  render() {
    const {
      classes,
      columnData,
      actions,
      disabledActions,
      savedFilters,
      encodedSearchFilters,
      numSelectedRows = 0,
      selectedColumns = [],
      searchText = '',
    } = this.props

    let selectedFilters =
      savedFilters &&
      savedFilters.find(
        ({ filterString }) => filterString === encodedSearchFilters,
      )

    return (
      <Grid container className={classes.root}>
        <Grid item xs={10} sm={8} className={classes.filters}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon>search</Icon>
                </InputAdornment>
              ),
            }}
            placeholder="Search"
            onChange={this.handleSearchChange}
            value={searchText}
          />

          <Select
            multiple
            displayEmpty
            value={selectedColumns}
            onChange={this.handleColumnChange}
            input={<Input />}
            renderValue={() => 'Columns'}
            MenuProps={MenuProps}
          >
            {columnData.map((col, index) => {
              const { name: colName, label: colLabel, hideFromColumns } = col
              return hideFromColumns ? null : (
                <SelectMenuItem
                  key={index}
                  value={colName}
                  label={colLabel || colName}
                  checked={selectedColumns.includes(colName)}
                />
              )
            })}
          </Select>

          {savedFilters && (
            <Select
              displayEmpty
              value={selectedFilters && selectedFilters.filterString}
              onChange={this.handleFiltersSelect}
              renderValue={() =>
                selectedFilters ? selectedFilters.title : 'Saved Filters'
              }
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {savedFilters.map((filter) => {
                const { title, filterString } = filter
                return <MenuItem key={title} value={filterString}>{title}</MenuItem>
              })}
            </Select>
          )}
        </Grid>

        <Grid item xs={2} sm={4}>
          <ModelActionBar
            actions={actions}
            numSelectedRows={numSelectedRows}
            onActionClick={this.handleActionClick}
            disabled={disabledActions}
          />
        </Grid>
      </Grid>
    )
  }
}

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(toolbarStyles)(EnhancedTableToolbar)
