import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { ErrorPage } from '../ErrorPage'
import IntlUtil from '../../../utils/IntlUtil'
import PortalLabels from '../../../../constants/PortalLabels'
import Grid from '@material-ui/core/Grid'
import ReactGA from 'react-ga'

const styles = ({ breakpoints, spacing: { unit } }) => ({})

class ErrorHandler extends Component {
  constructor(props) {
    // console.log('index.js -> constructor -> ', props)
    super(props)

    this.state = {
      error: '',
      errorInfo: {},
    }
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    })

    // console.log('index.js -> componentDidCatch -> ', error)

    ReactGA.exception({
      description: `${error.toString()} \n ${errorInfo.componentStack}`,
      fatal: true,
    })
  }

  render() {
    if (this.state.errorInfo.componentStack) {
      // console.log(
      //   `${this.state.error.toString()} \n ${
      //     this.state.errorInfo.componentStack
      //   }`,
      // )
      return (
        <React.Fragment>
          <Grid container>
            <ErrorPage message={IntlUtil.label(PortalLabels.MSG_ERROR)} />
          </Grid>
        </React.Fragment>
      )
    }
    // Normally, just render children
    return this.props.children
  }
}

export default withStyles(styles)(ErrorHandler)
