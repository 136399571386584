import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { withLanguage } from '../../../LanguageContext'
import Colors from '../../../shared/assets/styles/colors'
import classNames from 'classnames'
import { withThemeSwitch } from '../../../shared/assets/styles/theme'

const styles = ({ spacing: { unit } }) => ({
  root: {
    minWidth: unit * 12,
    color: Colors.Black,
    '&:before': {
      borderBottomColor: `${Colors.Black} !important`,
    },
  },
  selectMenu: {
    color: Colors.Black,
  },
  icon: {
    color: Colors.Black,
  },
})

class LanguageSelector extends React.Component {
  constructor(props) {
    super(props)
    this.state = { direction: props.theme.direction || 'ltr' }
  }

  componentDidMount() {
    const { selectedLanguage } = this.props

    this._handleTextDirectionChange(selectedLanguage)
  }

  render() {
    const {
      supportedLanguages,
      selectedLanguage,
      classes,
      className,
    } = this.props

    if (!selectedLanguage) return null

    return (
      <Select
        className={classNames(classes.root, className)}
        classes={{
          root: classes.root,
          selectMenu: classes.selectMenu,
          icon: classes.icon,
        }}
        value={selectedLanguage.id}
        onChange={this._handleChange}
      >
        {supportedLanguages.map(({ id, label, labelShort }) => (
          <MenuItem key={id} value={id}>
            {`${labelShort} ${label}`}
          </MenuItem>
        ))}
      </Select>
    )
  }

  _handleChange = (event) => {
    const { onLanguageChange, supportedLanguages } = this.props
    const chosenLanguage = supportedLanguages.find(
      ({ id }) => id === event.target.value,
    )

    this._handleTextDirectionChange(chosenLanguage)

    onLanguageChange && onLanguageChange(chosenLanguage)
  }

  _handleTextDirectionChange = (selectedLanguage) => {
    const { onThemeChange } = this.props
    let direction = selectedLanguage.rtl ? 'rtl' : 'ltr'

    if (this.state.direction !== direction) {
      onThemeChange({ direction })
      this.setState({ direction })
    }
  }
}

export default withThemeSwitch(
  withLanguage(withStyles(styles, { withTheme: true })(LanguageSelector)),
)
