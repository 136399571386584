import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'

const styles = (theme) => ({
  root: {},
})

class CustomSwitchField extends Component {
  handleChange = (e) => {
    const { onChange, name } = this.props
    onChange && onChange({ target: { name, value: e.target.checked } })
  }

  render() {
    const {
      classes,
      helperText,
      disabled,
      value,
      name,
      label,
      helperTextClass,
    } = this.props
    return (
      <FormControl className={classes.root}>
        <FormControlLabel
          className={classes.label}
          disabled={disabled}
          control={
            <Switch
              checked={value}
              value={Boolean(value).toString()}
              onChange={this.handleChange}
              name={name}
            />
          }
          label={label}
        />
        {helperText && (
          <FormHelperText className={helperTextClass}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    )
  }
}

export default withStyles(styles)(CustomSwitchField)
