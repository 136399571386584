import queryString from 'query-string'

export const getEncodedFilterFromQuery = () => {
  const params = queryString.parse(window.location.search)
  let { s } = params
  return s
}

export const getUpdatedQuery = (encodedFilters, query) => {
  const params = query ? queryString.parse(query) : {}

  return queryString.stringify({
    ...params,
    s: encodedFilters,
  })
}

export const encodeFilters = (filters) => {
  return window.btoa(unescape(encodeURIComponent(JSON.stringify(filters))))
}

export const decodeFilters = (encodedFilters) => {
  try {
    return JSON.parse(decodeURIComponent(escape(window.atob(encodedFilters))))
  } catch (e) {
    return {}
  }
}
