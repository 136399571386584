module.exports = {
  PASSWORD_STRENGTH_TEXT: 'PASSWORD_STRENGTH_TEXT',
  PASSWORD_STRENGTH_BAD: 'PASSWORD_STRENGTH_BAD',
  PASSWORD_STRENGTH_WEAK: 'PASSWORD_STRENGTH_WEAK',
  PASSWORD_STRENGTH_OKAY: 'PASSWORD_STRENGTH_OKAY',
  PASSWORD_STRENGTH_GOOD: 'PASSWORD_STRENGTH_GOOD',
  DOCUMENT_TYPE_SIZE: 'DOCUMENT_TYPE_SIZE',
  CONTRACTS: 'CONTRACTS',
  TERMS_AND_CONDITIONS: 'TERMS_AND_CONDITIONS',
  GIVE_FEEDBACK: 'GIVE_FEEDBACK',
  CURRENCY_FULL_NAME: 'CURRENCY_FULL_NAME',
  CURRENCY_SHORT_NAME: 'CURRENCY_SHORT_NAME',
  COMING_SOON: 'COMING_SOON',
  SOLD_OUT: 'SOLD_OUT',
  FILTER_ALL: 'FILTER_ALL',
  RESET_FILTERS: 'RESET_FILTERS',
  ALL: 'ALL',
  AMOUNT: 'AMOUNT',
  GREETING: 'GREETING',
  J_POINTS: 'J_POINTS',
  DATE: 'DATE',
  ORDER: 'ORDER',
  ORDER_NOW: 'ORDER_NOW',
  PLACE_ORDER: 'PLACE_ORDER',
  ORDERED: 'ORDERED',
  PRODUCT: 'PRODUCT',
  SEARCH: 'SEARCH',
  RESULT: 'RESULT',
  NEW: 'NEW',
  ADD: 'ADD',
  REMOVE: 'REMOVE',
  CANCEL: 'CANCEL',
  RESET: 'RESET',
  CONFIRM: 'CONFIRM',
  SUBMIT: 'SUBMIT',
  SAVE: 'SAVE',
  SEND: 'SEND',
  YES: 'YES',
  NO: 'NO',
  NEXT: 'NEXT',
  PREVIOUS: 'PREVIOUS',
  START: 'START',
  END: 'END',
  PERIOD: 'PERIOD',
  TOTAL: 'TOTAL',
  NO_ITEMS: 'NO_ITEMS',
  NAME: 'NAME',
  STATUS: 'STATUS',
  LOG_OUT: 'LOG_OUT',
  PRICE: 'PRICE',
  /* labels below are up for clean up */
  ACTION: 'ACTION',
  REWARD: 'REWARD',
  ACTIVE: 'ACTIVE',
  EXPIRED: 'EXPIRED',
  IN_PROGRESS: 'IN_PROGRESS',
  CODE: 'CODE',
  MY_BALANCE: 'MY_BALANCE',
  BALANCE_DATE: 'BALANCE_DATE',
  BALANCE_TIME: 'BALANCE_TIME',
  BALANCE_ACTION: 'BALANCE_ACTION',
  BALANCE: 'BALANCE',
  NUM_JPOINTS_EXPIRING_THIS_MONTH: 'NUM_JPOINTS_EXPIRING_THIS_MONTH',
  ACHIEVEMENT: 'ACHIEVEMENT',
  COMPLIANCE: 'COMPLIANCE',
  DEFAULT_OUT_OF_STOCK: 'DEFAULT_OUT_OF_STOCK',
  DEFAULT_NOT_ENOUGH_IN_STOCK: 'DEFAULT_NOT_ENOUGH_IN_STOCK',
  DEFAULT_NOT_ENOUGH_JPOINTS: 'DEFAULT_NOT_ENOUGH_JPOINTS',
  DEFAULT_AGREEMENT_REQUIRED: 'DEFAULT_AGREEMENT_REQUIRED',
  NEWS_COPYRIGHT: 'NEWS_COPYRIGHT',
  FILTER_PRODUCTS: 'FILTER_PRODUCTS',
  FILTER_REWARDS: 'FILTER_REWARDS',
  FILTER_SALES: 'FILTER_SALES',
  ALL_PRODUCTS_OF: 'ALL_PRODUCTS_OF',
  LEARN_MORE_ABOUT: 'LEARN_MORE_ABOUT',
  TECHNICAL_DATA: 'TECHNICAL_DATA',
  NUMBERS_AND_FACTS: 'NUMBERS_AND_FACTS',
  ORDER_AMOUNT_DISCLAIMER: 'ORDER_AMOUNT_DISCLAIMER',
  ORDER_STATUS: 'ORDER_STATUS',
  ORDER_STATUS_IN_PROGRESS: 'ORDER_STATUS_IN_PROGRESS',
  ORDER_STATUS_SHIPPED: 'ORDER_STATUS_SHIPPED',
  ORDER_STATUS_CANCELLED: 'ORDER_STATUS_CANCELLED',
  SUBMITTED: 'SUBMITTED',
  CANCEL_ORDER: 'CANCEL_ORDER',
  PRODUCTS: 'PRODUCTS',
  ADD_NEW_PRODUCT: 'ADD_NEW_PRODUCT',
  NR_OF_CARTONS: 'NR_OF_CARTONS',
  NR_OF_UNITS: 'NR_OF_UNITS',
  CARTONS: 'CARTONS',
  UNIT: 'UNIT',
  UNITS: 'UNITS',
  RECOMMENDED_RETAIL_PRICE: 'RECOMMENDED_RETAIL_PRICE',
  MSG_ERROR: 'MSG_ERROR',
  TRAY: 'TRAY',
  FACING: 'FACING',
  PACKAGE_PER_UNIT_ABBR: 'PACKAGE_PER_UNIT_ABBR',
  ORDER_AMOUNT: 'ORDER_AMOUNT',
  ORDER_NR: 'ORDER_NR',
  CONFIRM_YOUR_ORDER: 'CONFIRM_YOUR_ORDER',
  PREVIOUS_ORDER: 'PREVIOUS_ORDER',
  PREVIOUS_VALUES: 'PREVIOUS_VALUES',
  ORDER_HISTORY: 'ORDER_HISTORY',
  CARTON: 'CARTON',
  PACK: 'PACK',
  OFFER: 'OFFER',
  MARKET_SHARE: 'MARKET_SHARE',
  MARKET_SHARE_COMPARISON: 'MARKET_SHARE_COMPARISON',
  INFORMATION: 'INFORMATION',
  RULES_AND_CONDITIONS: 'RULES_AND_CONDITIONS',
  SALES_PERFORMANCE: 'SALES_PERFORMANCE',
  SALES_REP_VISITS: 'SALES_REP_VISITS',
  CONSUMER_PROGRAM: 'CONSUMER_PROGRAM',
  FOOTER_COPYRIGHT: 'FOOTER_COPYRIGHT',
  PRODUCT_DETAIL: 'PRODUCT_DETAIL',
  ORDER_DATE: 'ORDER_DATE',
  DELETED_CONTRACTS: 'DELETED_CONTRACTS',
  SUGGESTION: 'SUGGESTION',
  SINGLE_SIGN_ON: 'SINGLE_SIGN_ON',
  NO_PRODUCTS_SELECTED: 'NO_PRODUCTS_SELECTED',
  ALL_PRODUCTS_SELECTED: 'ALL_PRODUCTS_SELECTED',
  FEEDBACK: 'FEEDBACK',
  NO_AGREEMENTS_ITEMS: 'NO_AGREEMENTS_ITEMS',
}
