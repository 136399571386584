const widthBySize = {
  xxs: 100,
  xs: 200,
  sm: 400,
  md: 690,
}

const sizeImagePath = (path, size, trim) => {
  if (!path || (!size && !trim)) return path

  let url = path
  const pixelRatio =
    window.devicePixelRatio && window.devicePixelRatio > 1 ? 2 : 1.5
  const width = widthBySize[size] && Math.floor(widthBySize[size] * pixelRatio)
  if (width) {
    url += '?w=' + width + '&crop'
    trim && (url += '&trim=true')
  } else if (trim) {
    url += '?trim=true'
  }

  return url
}

export default sizeImagePath
