import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

const styles = {
  selectWrapper: {
    verticalAlign: 'middle',
    '&:before,&:after': {
      display: 'none',
    },
  },
  selectMenu: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    padding: 0,
    minWidth: 28,
    height: 28,
    fontSize: 16,
    color: 'black',
  },
  hidden: {
    opacity: 0,
    width: 0,
    position: 'relative',
    left: -28,
  },
  menuItem: {
    height: '1.25em',
  },
  icon: {
    display: 'none',
  },
}

function EditorMenuSelect(props) {
  const { classes, hidden, disabled, items, value, open, onChange, onClose, onOpen } = props

  return (
    <Select
      className={[classes.selectWrapper, hidden && classes.hidden].filter(Boolean).join(' ')}
      classes={{ selectMenu: classes.selectMenu, icon: classes.icon }}
      value={value}
      disabled={disabled}
      onChange={onChange}
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      MenuProps={{
        MenuListProps: {
          dense: true,
          disablePadding: true,
        },
      }}
    >
      {items.map((name, index) => (
        <MenuItem key={name} value={index} classes={{ root: classes.menuItem }}>
          <b>{name}</b>
        </MenuItem>
      ))}
    </Select>
  )
}

export default withStyles(styles)(EditorMenuSelect)
