import get from 'lodash/get'

export function getJsonPath(parentPath = '', fieldIndex = 0, fieldName = '') {
  return [parentPath, `[${fieldIndex}]`, fieldName].filter(Boolean).join('.')
}

export function getJsonValue(jsonPath = '', jsonData = []) {
  return get(jsonData, jsonPath)
}

export function getDefaultJsonValue(schema) {
  const value = {}
  for (const [key, val] of Object.entries(schema)) {
    switch (val?.type) {
      case 'string':
      case 'enum':
        value[key] = ''
        break
      case 'number':
        value[key] = 0
        break
      case 'boolean':
        value[key] = false
        break
      case 'array':
        value[key] = [getDefaultJsonValue(val.schema || {})]
        break
      default:
        // In case the type is unknown nothing will be rendered
        break
    }
  }
  return value
}
