import React from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import AppFrame from './AppFrame'
import PopupWrapper from './shared/components/popup/PopupWrapper'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import PortalTheme, { THEME } from './shared/assets/styles/theme'
import { ApiProvider } from './shared/api/ApiContext'
import ApiConnector from './shared/api/ApiConnector'
import AdminApi from './api/AdminApi'
import SnackMessages from './components/molecules/SnackMessages'
import ErrorHandler from './shared/components/pages/ErrorHandler'
import { createPageWrapper } from './shared/components/pages'
import captcha from './shared/utils/captcha'

import createGenerateClassName from '@material-ui/core/styles/createGenerateClassName'
import jssPreset from '@material-ui/core/styles/jssPreset'
import { create } from 'jss'
import rtl from 'jss-rtl'
import JssProvider from 'react-jss/lib/JssProvider'
import { SnackbarProvider } from 'notistack'
import ModelDetails from './components/page/ModelViewWrapper'

import ModelList from './components/page/ModelViewWrapper'
import AdminLoginPage from './components/page/AdminLoginPage'
import AdminLoginResetPage from './shared/components/pages/ResetPasswordPage'
import AdminLoginResetPasswordPage from './components/page/AdminLoginSetPasswordPage'
import AdminDashboard from './components/page/AdminDashboard'
import AtlasOverviewPage from './components/page/AtlasDashboards/AtlasOverviewPage'
import AtlasDashboardPage from './components/page/AtlasDashboards/AtlasDashboardPage'
import AdminVersionControlPage from './components/page/AdminVersionControlPage'
import GenericMessagePage from './shared/components/templates/GenericMessagePage'

const jss = create({ plugins: [...jssPreset().plugins, rtl()] })
const generateClassName = createGenerateClassName()

const apiConnector = new ApiConnector(process.env.REACT_APP_API_URL)
const adminApi = new AdminApi(apiConnector)

const componentByName = {
  ModelDetails,
  ModelList,
  AdminLoginPage,
  AdminLoginResetPage,
  AdminLoginResetPasswordPage,
  AdminDashboard,
  AtlasOverviewPage,
  AtlasDashboardPage,
  AdminVersionControlPage,
  GenericMessagePage,
}

const getComponentByName = (name) => {
  // console.log('App.js -> getComponentByName -> ', name)
  return componentByName[name]
}

// Yo dawg, I heard you liked wrappers, so I wrapped your wrapper in another wrapper
const PopupWrapperWrapper = (props) => <PopupWrapper {...props} maxWidth={'90vw'} maxHeight={'90vh'} />

let PageWrapper = createPageWrapper({
  getComponentByName,
  AppFrame,
  PopupWrapper: PopupWrapperWrapper,
  locale: 'en',
})

class App extends React.PureComponent {
  state = {}

  constructor(props) {
    super(props)

    this.state = {
      theme: createMuiTheme(THEME),
    }
  }

  handleRouteChange = ({ pageData: { appFrame } = {} }) => {
    captcha(appFrame)
  }

  handleThemeChange = (newTheme) => {
    if (newTheme.direction) document.body.dir = newTheme.direction
    this.setState({ theme: createMuiTheme({ ...THEME, ...newTheme }) })
  }

  render() {
    const { theme } = this.state

    return (
      <JssProvider jss={jss} generateClassName={generateClassName}>
        <PortalTheme.Provider value={this.handleThemeChange}>
          <MuiThemeProvider theme={theme}>
            <BrowserRouter>
              <ErrorHandler>
                <ApiProvider api={adminApi}>
                  <SnackbarProvider maxSnack={4} dense>
                    <SnackMessages />
                    <Route
                      render={(props) => <PageWrapper {...props} onRouteChange={this.handleRouteChange} />}
                    />
                  </SnackbarProvider>
                </ApiProvider>
              </ErrorHandler>
            </BrowserRouter>
          </MuiThemeProvider>
        </PortalTheme.Provider>
      </JssProvider>
    )
  }
}

export default App
