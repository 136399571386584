import React from 'react'
import Icon from '@material-ui/core/Icon'
import withStyles from '@material-ui/core/styles/withStyles'
import HeaderItemTemplate from './HeaderItemTemplate'

const styles = ({ spacing: { unit } }) => ({
  image: {
    width: unit * 2,
    height: unit * 2,
    borderRadius: '50%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
  },
})

const HeaderItem = withStyles(styles)(({ icon, classes, ...props }) => (
  <HeaderItemTemplate {...props}>
    {icon && icon.indexOf('/') === 0 ? (
      <div
        className={classes.image}
        style={{ backgroundImage: `url("${props.icon}")` }}
      />
    ) : (
      <Icon color={'primary'}>{icon}</Icon>
    )}
  </HeaderItemTemplate>
))

export default HeaderItem
