import React, { useEffect, useState } from 'react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import Table from './Table'
import List from './List'
import Chart from './Chart'
import { withApi } from '../../../shared/api/ApiContext'
// import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import CircularProgress from '@material-ui/core/CircularProgress'

const renderSection = (item, data) => {
  if (!data) data = {}
  switch (item.type) {
    case 'table':
      return <Table {...item} data={data} />
    case 'list':
      return <List {...item} data={data} />
    case 'chart':
      return <Chart {...item} data={data} />
    default:
      break
  }
}

const styles = () => ({
  root: {
    padding: '2rem',
    height: '100%',
    boxSizing: 'border-box',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 300,
    width: '100%',
  },
})

const DashboardTile = (props) => {
  const { classes, api, title, data = [] } = props

  let [dataIdx, setDataIdx] = useState(0)
  let [currentData, setCurrentData] = useState(null)
  let [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)

    const dataSet = data[dataIdx]
    if (!dataSet) {
      return
    }

    const { route } = dataSet
    let cancel
    let request
    let response

    const loadData = async () => {
      ;({ request, cancel } = api.connector.makeRequest(route, 'get'))

      try {
        response = await request
      } catch (e) {
        console.error('e:', e)
      }

      setIsLoading(false)
      setCurrentData(response ? response.data : null)
    }

    loadData().then(/*noop*/)

    return () => {
      if (cancel) cancel()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataIdx])

  const handleChange = (event) => {
    setDataIdx(parseInt(event.target.value))
  }

  return (
    <Paper className={classes.root}>
      <div className={classes.titleContainer}>
        <Typography variant="h5">{title}</Typography>

        {data.length > 1 && (
          <Select value={dataIdx} onChange={handleChange}>
            {data.map(({ title, route }, i) => {
              return <MenuItem key={title} value={i}>{title}</MenuItem>
            })}
          </Select>
        )}
      </div>
      {isLoading ? (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      ) : (
        renderSection(props, currentData)
      )}
    </Paper>
  )
}

export default withApi(withStyles(styles)(DashboardTile))
