import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import EditorMenuSelect from './EditorMenuSelect'

export default function EditorMenuTable({ editor }) {
  const [open, setOpen] = useState(false)

  const handleClose = () => setOpen(false)

  const handleOpen = () => setOpen(true)

  const options = [
    {
      title: 'Insert table',
      onSelect: () => editor.commands.insertTable(),
    },
    {
      title: 'Add column',
      onSelect: () => editor.commands.addColumnAfter(),
    },
    {
      title: 'Add row',
      onSelect: () => editor.commands.addRowAfter(),
    },
    {
      title: 'Remove column',
      onSelect: () => editor.commands.deleteColumn(),
    },
    {
      title: 'Remove row',
      onSelect: () => editor.commands.deleteRow(),
    },
    {
      title: 'Merge cells',
      onSelect: () => editor.commands.mergeCells(),
    },
    {
      title: 'Split cell',
      onSelect: () => editor.commands.splitCell(),
    },
  ]

  return (
    <>
      <Button
        size="small"
        style={{
          margin: 0,
          padding: 0,
          minWidth: 28,
          height: 28,
        }}
        onClick={handleOpen}
      >
        <Icon fontSize="small">table_chart</Icon>
      </Button>
      <EditorMenuSelect
        items={options.map((option) => option.title)}
        // value={'0'}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        hidden
        onChange={(e) => {
          const index = Number(e.target.value)
          const { onSelect } = options[index] || {}
          onSelect && onSelect()
        }}
      />
    </>
  )
}
