import React from 'react'
import Checkbox from '@material-ui/core/Checkbox/Checkbox'
import ListItemText from '@material-ui/core/ListItemText/ListItemText'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import withStyles from '@material-ui/core/styles/withStyles'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const FONT_SIZE = '13px'

const styles = (theme) => ({
  menuLabel: {
    paddingLeft: 0,
    '&:first-child': {
      paddingLeft: '72px',
      fontSize: FONT_SIZE,
    },
  },
  menuItem: {
    padding: '5px 0',
  },
  menuText: {
    fontSize: FONT_SIZE,
  },
})

export default withStyles(styles)(
  ({ classes, value, label, checked, ...rest }) => (
    <MenuItem key={value} value={value} className={classes.menuItem} {...rest}>
      <Checkbox checked={checked} color={'primary'} />
      <ListItemText
        primary={label}
        className={classes.menuLabel}
        primaryTypographyProps={{ className: classes.menuText }}
      />
    </MenuItem>
  ),
)

const MenuProps = {
  PaperProps: {
    style: {
      fontSize: FONT_SIZE,
      maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export { MenuProps }
