import React from 'react'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import Colors from '../../../assets/styles/colors'
import Icon from '@material-ui/core/Icon/Icon'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import Typography from '@material-ui/core/Typography'

const styles = ({ spacing: { unit } }) => ({
  button: {
    width: '100%',
    padding: 0,
    marginLeft: -3,
    marginBottom: unit * 2,
    color: Colors.Aqua,
    justifyContent: 'inherit',
  },
  leftIcon: {
    marginRight: unit * 3,
  },
  text: {
    color: Colors.Aqua,
    textTransform: 'initial',
  },
})

const SmallButton = withStyles(styles)((props) => {
  const {
    text,
    icon,
    classes,
    href,
    onClick,
    disabled,
    history,
    className,
  } = props

  const handleClick = (e) => {
    if (onClick) onClick(e)

    if (href) history.push(href)
  }

  return (
    <Button
      size="small"
      className={classNames(classes.button, className)}
      onClick={handleClick}
      disabled={disabled}
    >
      <Icon style={{ color: Colors.Brand }} className={classes.leftIcon}>
        {icon}
      </Icon>
      <Typography variant="body2" className={classes.text}>
        {text}
      </Typography>
    </Button>
  )
})

export default withRouter(SmallButton)
