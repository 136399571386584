import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Modal from '@material-ui/core/Modal'
import Icon from '@material-ui/core/Icon'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import FormHelperText from '@material-ui/core/FormHelperText'
import { styles } from './Styles'
import SwipeableViews from 'react-swipeable-views'
import PreviewMedia from './PreviewMedia'
import ListMediaContainer from './ListMediaContainer'
import UploadMediaContainer from './UploadMediaContainer'
import ImageSelection from './ImageSelection'
import sizeImagePath from '../../../../shared/utils/sizeImagePath'
import { withApi } from '../../../../shared/api/ApiContext'

const imageMaxSize = 1000000000 // bytes
// const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif'
// const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => { return item.trim() })

class CustomMedia extends React.Component {
  constructor(props) {
    super(props)

    let multilang = props[`${props.name}__multilang`] || false

    this.state = {
      multilang,
      prevMultilang: multilang,
      upstreamFiles: [],
      files: [],
      open: false,
      showFullImage: false,
      value: 0,
      types: [],
      tags: [],
      tagsOnUpload: [],
      updateMediaList: false,
      isPlaying: false,
      createdNewOption: false,
      filters: {
        valueType: [],
        valueTag: [],
        valueName: '',
      },
    }

    this.tagsOnUpload = []

    this.parsedTags = []

    // A clone of the medias from the server
    this.medias = []

    this.nextProps = {}
  }

  static getDerivedStateFromProps(props, state) {
    const propsMultilang = props[`${props.name}__multilang`]

    if (propsMultilang !== state.prevMultilang) {
      return {
        multilang: propsMultilang,
        prevMultilang: propsMultilang,
      }
    }
    return null
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.nextProps = nextProps
  }

  componentDidMount() {
    if (this.props.isRichText) {
      this.handleOpen()
    }
  }

  async fetchAllMedias() {
    let response, newTypeMedia
    try {
      const { api, typeMedia, sectionType } = this.props

      newTypeMedia = typeMedia

      if (this.nextProps && this.nextProps.typeMedia && this.nextProps.typeMedia !== typeMedia) {
        newTypeMedia = this.nextProps.typeMedia
      }

      const { request } = api.getMedias({
        type: newTypeMedia,
        filterByTags: [sectionType],
      })

      response = await request

      this.setState(
        {
          upstreamFiles: response.mediaItems,
          types: response.filter.types,
          tags: response.filter.tags,
          showFullImage: false,
          visible: false,
        },
        () => {
          this.parsedTags = this.state.tags.map((t) => {
            return { value: t, label: t }
          })
        },
      )

      this.medias = response.mediaItems
    } catch (e) {
      console.error(e)
    }
  }

  verifyFile = (files) => {
    // if (files && files.length > 0) {
    //   files.forEach(file => {
    //     const currentFile = files[0]
    //     const currentFileType = currentFile.type
    //     const currentFileSize = currentFile.size

    //     if (currentFileSize > imageMaxSize) {
    //       alert("This file is not allowed. " + currentFileSize + " bytes is too large")
    //       return false
    //     }

    //     if (!acceptedFileTypesArray.includes(currentFileType)) {
    //       alert("This file is not allowed. Only images are allowed.")
    //       return false
    //     }
    //   })

    return true
    // }
  }

  handleOnDrop = (files, rejectedFiles) => {
    let allFiles = [...this.state.files, ...files]

    if (rejectedFiles && rejectedFiles.length > 0) {
      this.verifyFile(rejectedFiles)
    }

    function readAndPreview(file, index) {
      if (allFiles && allFiles.length > 0) {
        const isVerified = this.verifyFile(allFiles)
        if (isVerified) {
          const reader = new FileReader()
          reader.onload = function () {
            allFiles[index]['src'] = reader.result

            this.setState({
              files: allFiles,
            })
          }.bind(this)

          reader.readAsDataURL(file)
        }
      }
    }

    if (files) {
      allFiles.forEach((file, i) => readAndPreview.bind(this, file, i)())
    }
  }

  handleOpen = () => {
    const { disabled } = this.props

    if (disabled) {
      return
    }

    this.setState({
      open: true,
      value: 0,
    })

    this.fetchAllMedias()
  }

  handleClose = () => {
    if (this.state.visible) {
      return
    }

    this.setState({ open: false })

    this.props.handleCloseParent && this.props.handleCloseParent()
  }

  async _processUpload(file, k, onlyOne) {
    const { api } = this.props

    this.setState({
      visible: true,
    })

    const { request } = api.uploadMedia(null, file, this.tagsOnUpload)

    await request

    this.removePreviewFile(file)

    if (onlyOne) {
      this.setState({
        visible: false,
      })
    }

    if (!this.state.files.length) {
      this.fetchAllMedias()
      this.handleChange(null, 0)
    }
  }

  async _processLocalise(file, originalFileId, selectedLanguage, updateField = 'localisation') {
    const { api } = this.props
    // const { currentFile } = this.state

    const { request } = api.enhanceMedia(null, originalFileId, file, selectedLanguage, updateField)

    await request

    this.removePreviewFile(file)

    this.fetchAllMedias()
    this.handleChange(null, 0)
  }

  async _processPatch(language, updateField) {
    if (!window.confirm('Do you really want to remove this localisation?')) {
      return
    }

    const { currentFile } = this.state
    const { api } = this.props

    this.setState({
      visible: true,
    })

    const { request } = api.updateMedia(currentFile._id, {
      set: {
        [`${updateField}.${language}`]: null,
      },
    })

    await request

    this.fetchAllMedias()
    this.handleChange(null, 0)
  }

  uploadFile(file, k) {
    try {
      this._processUpload(file, k, true)
    } catch (e) {
      console.error(e)
    }
  }

  localiseFile(file, mediaId, selectedLanguage, updateField) {
    try {
      this._processLocalise(file, mediaId, selectedLanguage, updateField)
    } catch (e) {
      console.error(e)
    }
  }

  uploadAllFiles() {
    try {
      const { files } = this.state

      files.map((file, k) => this._processUpload(file, k))
    } catch (e) {
      console.error(e)
    }
  }

  removePreviewFile(file) {
    this.setState({
      files: this.state.files.filter(function (item, k) {
        return file.name !== item.name
      }),
    })
  }

  removeLocalisedFile(language, type) {
    try {
      this._processPatch(language, type)
    } catch (e) {
      console.error(e)
    }
  }

  _handleAddChipOnUpload = (tags = []) => {
    if (tags.length) {
      this.tagsOnUpload = tags
    }
  }

  async _handleAddChip(tags = []) {
    // e.persist()

    const { currentFile } = this.state
    const { api } = this.props

    // if (e.key === 'Enter' && e.target.value !== '') {
    try {
      if (!currentFile.tags) {
        currentFile.tags = []
      }

      // if (currentFile.tags.includes(e.target.value)) return

      currentFile.tags = tags

      const { request } = api.updateMedia(currentFile._id, {
        set: {
          tags: tags,
        },
      })

      await request
    } catch (e) {
      console.error(e)
    }

    this.setState({
      currentFile: currentFile,
      updateMediaList: true,
    })
  }

  _handleCreateOption = (inputValue, parsedValues, isUpload) => {
    const newOption = { label: inputValue, value: inputValue }
    this.parsedTags.push(newOption)

    if (isUpload) {
      return this._handleAddChipOnUpload(parsedValues)
    }

    this._handleAddChip(parsedValues)
  }

  searchMedias(e) {
    const { filters } = this.state

    // keep the filters value in the state to apply the filter after open the media and return to the list view
    const valueType = (e && e.valueType) || filters.valueType
    const valueTag = (e && e.valueTag) || filters.valueTag
    const valueName = (e && e.valueName) || filters.valueName

    let finalMedias = this.medias.filter((file, i) => {
      return (
        (valueType.length === 0 || (Array.isArray(valueType) && valueType.includes(file.type))) &&
        (valueTag.length === 0 ||
          (Array.isArray(file.tags) && file.tags.some((tag) => valueTag.includes(tag)))) &&
        (valueName === '' || file.name.indexOf(valueName) !== -1)
      )
    })

    this.setState({
      upstreamFiles: finalMedias,
      filters: { valueType, valueTag, valueName },
    })
  }

  viewFullImage(file) {
    this.setState({
      currentFile: file,
      showFullImage: true,
      value: 2,
    })
  }

  async backToFilesList() {
    if (this.state.updateMediaList) {
      await this.fetchAllMedias()
      this.searchMedias({})
    }

    this.setState({
      currentFile: null,
      showFullImage: false,
      value: 0,
      updateMediaList: false,
    })
  }

  async deleteFile(file, i) {
    if (!window.confirm('Do you really want to delete this file?')) {
      return
    }

    const { api } = this.props

    this.medias = this.medias.filter(function (item, k) {
      return k !== i
    })

    try {
      const { request } = api.deleteMedia(file._id)

      await request
    } catch (e) {
      return
    }

    this.setState({
      upstreamFiles: this.medias,
    })
  }

  useThisMedia(file, k) {
    const { isList } = this.props

    if (isList) return

    const { upstreamFiles } = this.state

    if (this.props.value !== file._id) {
      file.selected = true

      this.setState({
        upstreamFiles: upstreamFiles,
        open: false,
        selectedFile: file,
      })

      this.props.onChange.apply(this, [
        {
          //enviar algo asi para el switch
          target: {
            name: this.props.name,
            value: [file._id],
          },
        },
      ])
    }
  }

  handleChange = (event, value) => {
    this.setState({ value: value })
  }

  handleChangeIndex = (index) => {
    this.setState({ value: index })
  }

  /*  handleMultiSwitchChange = event => {
    const {onChange, name} = this.props

    const {value} = event.target
    this.setState({multilang: value})

    onChange && onChange({
      target: {
        name: `${name}__multilang`,
        value,
      }
    })
  }*/

  handleAutoplayChange = async (event) => {
    const { api } = this.props
    const { currentFile } = this.state
    const newFile = { ...currentFile }
    if (newFile.autoplay) {
      newFile.autoplay = !newFile.autoplay
    } else {
      newFile.autoplay = event.target.value
    }
    const { request } = api.updateMedia(currentFile._id, {
      set: { autoplay: newFile.autoplay },
    })
    await request
    this.setState({
      currentFile: newFile,
      updateMediaList: true,
    })
  }

  _handleDeleteImageFromItem = () => {
    const { onChange } = this.props
    this.setState({
      selectedFile: null,
    })

    onChange &&
      onChange({
        target: {
          name: this.props.name,
          value: [],
        },
      })
  }

  _handleClickOnVideo = (e) => {
    const { isPlaying } = this.state

    this.setState({
      isPlaying: !isPlaying,
    })
  }

  checkContentType = (file, base64) => {
    if (/image/.test(file.type)) {
      return base64 ? file.src : sizeImagePath(file.path, 'xxs')
    } else if (/pdf/.test(file.type)) {
      return '/static/images/pdf_black.png'
    } else if (/video/.test(file.type)) {
      return '/static/images/video_black.png'
    }
  }

  shouldHasModal(children) {
    const { classes, isList } = this.props

    // const {
    //   visible
    // } = this.state

    if (isList) {
      return children
    }

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.state.open}
        onClose={this.handleClose}
      >
        <div className={classes.paper}>
          {/* {visible && <div className={classes.overlayUpload} />} */}
          <div className={classes.close} onClick={this.handleClose}>
            <Icon>close</Icon>
          </div>
          {children}
        </div>
      </Modal>
    )
  }

  render() {
    const {
      classes,
      theme,
      value: propsValue,
      isRichText,
      useThisMedia,
      label,
      helperText,
      disabled,
      columnSize,
      isList,
      sectionType,
    } = this.props

    let { selectedFile } = this.state

    let value = selectedFile !== undefined ? selectedFile : propsValue

    const {
      files,
      upstreamFiles,
      showFullImage,
      currentFile,
      types,
      tags,
      // tagsOnUpload,
      visible,
      isPlaying,
      localisedMedia,
    } = this.state

    const showUploadContainer = !(sectionType === 'planogram')
    const mediaContent = (
      <React.Fragment>
        {!showFullImage && (
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="MEDIA" />
            {showUploadContainer && <Tab label="UPLOAD" />}
          </Tabs>
        )}

        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={this.state.value}
          onChangeIndex={this.handleChangeIndex}
        >
          <ListMediaContainer
            classes={classes}
            searchMedias={this.searchMedias.bind(this)}
            contentType={this.checkContentType}
            upstreamFiles={upstreamFiles}
            deleteFile={this.deleteFile.bind(this)}
            useThisMedia={useThisMedia || this.useThisMedia.bind(this)}
            viewFullImage={this.viewFullImage.bind(this)}
            types={types}
            tags={tags}
            isList={isList}
          />

          {showUploadContainer ? (
            <UploadMediaContainer
              classes={classes}
              contentType={this.checkContentType}
              imageMaxSize={imageMaxSize}
              handleOnDrop={this.handleOnDrop.bind(this)}
              uploadAllFiles={this.uploadAllFiles.bind(this)}
              removePreviewFile={this.removePreviewFile.bind(this)}
              uploadFile={this.uploadFile.bind(this)}
              previewFiles={files}
              tagsOnUpload={this.tagsOnUpload}
              handleAddChipOnUpload={this._handleAddChipOnUpload}
              handleDeleteChipOnUpload={this._handleDeleteChipOnUpload}
              visible={visible}
              onHandleCreate={this._handleCreateOption}
              parsedTags={this.parsedTags}
            />
          ) : (
            <React.Fragment />
          )}

          {showFullImage ? (
            <PreviewMedia
              currentFile={currentFile}
              {...this.props}
              onClickHandle={this.backToFilesList.bind(this)}
              onHandleAddChip={this._handleAddChip.bind(this)}
              useThisMedia={useThisMedia || this.useThisMedia.bind(this)}
              parsedTags={this.parsedTags}
              localiseFile={this.localiseFile.bind(this)}
              localisedMedia={localisedMedia}
              onHandleCreate={this._handleCreateOption}
              onAutoplayChange={this.handleAutoplayChange}
              isList={isList}
              handleOnDrop={this.handleOnDrop.bind(this)}
              removePreviewFile={this.removePreviewFile.bind(this)}
              removeLocalisedFile={this.removeLocalisedFile.bind(this)}
              previewFiles={files}
              contentLocalisedType={this.checkContentType}
              contentType={this.checkContentType}
              imageMaxSize={imageMaxSize}
            />
          ) : (
            <React.Fragment />
          )}
        </SwipeableViews>
      </React.Fragment>
    )

    return (
      <React.Fragment>
        <Typography>{label}</Typography>
        {!isRichText && (
          <ImageSelection
            classes={classes}
            value={value}
            handleOpen={this.handleOpen}
            disabled={disabled}
            columnSize={columnSize}
            handleDeleteImageFromItem={this._handleDeleteImageFromItem}
            clickedOnVideo={this._handleClickOnVideo}
            isPlaying={isPlaying}
          />
        )}
        {this.shouldHasModal(mediaContent)}

        {helperText && <FormHelperText>{helperText}</FormHelperText>}
        {/*        {!isList &&
        <CustomSwitchField
          name='multilanguage'
          label='Localization'
          helperText='Use different media for different languages'
          helperTextClass={classes.multilang}
          disabled={disabled}
          value={multilang}
          onChange={this.handleMultiSwitchChange}
        />}*/}
      </React.Fragment>
    )
  }
}

CustomMedia.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(withApi(CustomMedia))
