import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import Avatar from '@material-ui/core/Avatar'
import Icon from '@material-ui/core/Icon'
import ListItemText from '@material-ui/core/ListItemText'
import MuiList from '@material-ui/core/List'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = () => ({
  items: {
    paddingLeft: '1rem',
    cursor: 'pointer',
  },
})

const List = (props) => {
  const { classes, items } = props

  return (
    <MuiList>
      {items.map(({ link, icon, primary, secondary }) => {
        return (
          <ListItem component={'a'} button className={classes.items} href={link} target="blank">
            <Avatar>
              <Icon>{icon}</Icon>
            </Avatar>
            <ListItemText primary={primary} secondary={secondary} />
          </ListItem>
        )
      })}
    </MuiList>
  )
}

export default withStyles(styles)(List)
