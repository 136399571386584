import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography/Typography'
import Icon from '@material-ui/core/Icon/Icon'
import PointsSummary from './PointsSummary'
import classNames from 'classnames'

const styles = (theme) => ({
  root: {},
})

const componentByName = {
  PointsSummary,
}

class ModelInfoTile extends React.Component {
  state = {
    isExpanded: true,
  }

  handleExpansion = () => {
    this.setState((prevState) => ({ isExpanded: !prevState.isExpanded }))
  }

  render() {
    const { classes, component, props, className, title } = this.props
    const { isExpanded } = this.state
    const Component = componentByName[component]

    return (
      <ExpansionPanel
        expanded={isExpanded}
        onChange={this.handleExpansion}
        className={classNames(classes.root, className)}
      >
        <ExpansionPanelSummary expandIcon={<Icon>expand_more</Icon>}>
          {title && (
            <Typography variant="h5" align="left" color={'primary'}>
              {title}
            </Typography>
          )}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Component {...props} />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }
}

export default withStyles(styles)(ModelInfoTile)
