import React, { useCallback } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'

const styles = {
  button: {
    margin: 0,
    padding: 0,
    minWidth: 28,
    height: 28,
  },
}

function EditorMenuButton(props) {
  const { classes, editor, type } = props

  let title
  let icon
  let IconComponent
  let active
  let disabled
  let onClick

  const toggleLink = useCallback(() => {
    const previousUrl = editor.getAttributes('link').href
    const url = window.prompt('URL', previousUrl)
    if (url === null) return

    if (url === '') {
      // remove link
      editor.chain().focus().extendMarkRange('link').unsetLink().run()
    } else {
      // update link
      const target = /^https?:\/\//.test(url) ? '_blank' : '_self'
      editor.chain().focus().extendMarkRange('link').setLink({ href: url, target }).run()
    }
  }, [editor])

  switch (type) {
    case 'bold':
      title = 'Bold'
      icon = 'format_bold'
      onClick = () => editor.chain().toggleBold().focus().run()
      disabled = !editor.can().toggleBold()
      active = editor.isActive('bold')
      break

    case 'italic':
      title = 'Italic'
      icon = 'format_italic'
      onClick = () => editor.chain().toggleItalic().focus().run()
      disabled = !editor.can().toggleItalic()
      active = editor.isActive('italic')
      break

    case 'small':
      title = 'Small'
      IconComponent = () => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          enable-background="new 0 0 24 24"
          viewBox="0 0 24 24"
          height="18px"
          width="18px"
          fill="currentColor"
          style={{ pointerEvents: 'none' }}
        >
          <polygon points="20.79,9.23 18.79,5.77 14,8.54 14,3 10,3 10,8.54 5.21,5.77 3.21,9.23 8,12 3.21,14.77 5.21,18.23 10,15.46 10,21 14,21 14,15.46 18.79,18.23 20.79,14.77 16,12" />
        </svg>
      )
      onClick = () => editor.chain().toggleSmall().focus().run()
      disabled = !editor.can().toggleSmall()
      active = editor.isActive('small')
      break

    case 'superscript':
      title = 'Superscript'
      icon = 'superscript'
      onClick = () => editor.chain().toggleSuperscript().focus().run()
      disabled = !editor.can().toggleSuperscript()
      active = editor.isActive('superscript')
      break

    case 'bullet-list':
      title = 'Bullet list'
      icon = 'format_list_bulleted'
      onClick = () => editor.chain().toggleBulletList().focus().run()
      disabled = !editor.can().toggleBulletList()
      active = editor.isActive('bulletList')
      break

    case 'ordered-list':
      title = 'Numbered list'
      icon = 'format_list_numbered'
      onClick = () => editor.chain().toggleOrderedList().focus().run()
      disabled = !editor.can().toggleOrderedList()
      active = editor.isActive('orderedList')
      break

    case 'link':
      title = 'Link'
      icon = 'link'
      onClick = toggleLink
      disabled = !editor.can().toggleLink()
      active = editor.isActive('link')
      break

    case 'undo':
      title = 'Undo'
      icon = 'undo'
      onClick = () => editor.chain().undo().focus().run()
      disabled = !editor.can().undo()
      break

    case 'redo':
      title = 'Redo'
      icon = 'redo'
      onClick = () => editor.chain().redo().focus().run()
      disabled = !editor.can().redo()
      break

    default:
  }

  return (
    <>
      <Button
        title={title}
        size="small"
        className={[classes.button, active && 'is-active'].filter(Boolean).join(' ')}
        disabled={disabled}
        onClick={onClick}
      >
        {typeof IconComponent === 'function' ? <IconComponent /> : <Icon fontSize="small">{icon}</Icon>}
      </Button>
    </>
  )
}

export default withStyles(styles)(EditorMenuButton)
