import React, { Component } from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import withStyles from '@material-ui/core/styles/withStyles'
import Colors from '../../../assets/styles/colors'
import { htmlTextAnchorParser } from '../../../utils/index'

class CustomCheckBox extends Component {
  state = {}

  constructor(props) {
    super(props)

    let { text } = props

    if (!text || typeof text !== 'string') {
      this.state = { text }
      return
    }

    const textChildren = htmlTextAnchorParser(text)

    this.state = { text: <React.Fragment>{textChildren}</React.Fragment> }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.checked !== undefined) {
      return {}
    }
    return { checked: props.value }
  }

  handleChange = (e) => {
    const { onChange } = this.props
    const {
      target: { checked },
    } = e

    this.setState({ checked }, () => {
      onChange && onChange(e)
    })
  }

  render() {
    const { id, classes, invalid, className } = this.props
    const { checked, text } = this.state
    return (
      <FormControlLabel
        className={`${className || ''} ${classes.checkBoxControl}`}
        control={
          <Checkbox
            id={id}
            checked={checked}
            onChange={this.handleChange}
            color={'secondary'}
            className={`${classes.checkBox} ${invalid ? classes.error : ''}`}
          />
        }
        label={text}
      />
    )
  }

  checkValidity = () => !this.props.required || this.state.checked

  getValue = () => this.state.checked
}

const styles = ({ spacing: { unit } }) => ({
  checkBoxControl: {
    alignItems: 'inherit',
    margin: 0,
    paddingRight: unit * 3,
    boxSizing: 'border-box',
  },
  error: {
    color: Colors.Red,
  },
  checkBox: {
    // color: Colors.Grey + '!important',
    marginTop: -unit,
    marginLeft: -unit - 3,
    marginRight: unit * 2,
  },
})

export default withStyles(styles)(CustomCheckBox)
