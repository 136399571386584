import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Divider from '@material-ui/core/Divider/Divider'
import { Menu } from './components/molecules/Menu'
import Breadcrumbs from './shared/components/Breadcrumbs'
import Header from './components/molecules/Header/Header'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import PageProgress from './shared/components/PageProgress'
import EnvBar from './shared/components/atoms/EnvBar'
import Colors from './shared/assets/styles/colors'
import { LanguageProvider } from './LanguageContext'
import classnames from 'classnames'

const styles = ({ spacing: { unit } }) => ({
  '@media print': {
    content: {
      backgroundColor: Colors.White,
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.White,
  },
  contentWrapper: {
    paddingTop: 64, // refers to Header height
    display: 'flex',
  },
  contentWrapperHeadless: {
    paddingTop: '0 !important',
  },
  content: {
    height: 'calc(100vh - 64px)', // 64px refers to Header height
    overflow: 'hidden',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  contentHeadless: {
    height: '100vh !important', // 64px refers to Header height
  },
  loader: {
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 9999,
  },
  breadcrumbs: {
    minHeight: unit * 4,
  },
  page: {
    overflow: 'scroll',
    height: '100%',
    background: Colors.gradientBackground,
  },
  pagePadding: {
    padding: unit,
    paddingBottom: unit * 5,
  },
  fadeEnter: {
    opacity: 0,
    transform: `translate3d(0, ${-unit * 2}px, 0)`,
  },
  fadeEnterActive: {
    opacity: 1,
    transform: 'translate3d(0,0,0)',
    transition: `opacity 350ms 100ms,
       transform 350ms ease-out 100ms
      `,
  },
})

class AppFrame extends React.Component {
  constructor(props) {
    super(props)
    const cookies = document.cookie.split(/;\s?/).reduce((data, pair) => {
      const [key, value] = pair.split('=')
      return { ...data, [key]: value }
    }, {})

    this.state = {
      language: (cookies.language || navigator.language || '').toLowerCase().substr(0, 2),
      languageSwitchInterceptors: [],
      showMenu: false,
    }

    this.languageSwitchInterceptors = []
  }

  handleLanguageChange = (selectedLanguage) => {
    if (this.languageSwitchInterceptors.some((interceptor) => interceptor(selectedLanguage))) return

    document.cookie = `language=${selectedLanguage.id};path=/`
    this.setState({ selectedLanguage })
  }

  onRegisterLanguageSwitchInterceptor = (interceptor) => {
    this.languageSwitchInterceptors.push(interceptor)

    return () => {
      const i = this.languageSwitchInterceptors.indexOf(interceptor)
      if (i >= 0) this.languageSwitchInterceptors.splice(i, 1)
    }
  }

  handleDrawerToggle = () => {
    this.setState((prevState) => {
      return { showMenu: !prevState.showMenu }
    })
  }

  render() {
    let {
      classes,
      member,
      api,
      busy,
      children,
      data: {
        version,
        settings: { languages: supportedLanguages = [], general: { platformName } = {} } = {},
        menu,
        header,
        env: { color } = {},
      } = {},
      routeKey,
      contentKey,
      componentName,
    } = this.props

    const padding = componentName && componentName.includes('Login') ? '' : classes.pagePadding

    let { selectedLanguage, language, showMenu } = this.state
    if (!selectedLanguage && supportedLanguages.length > 0) {
      selectedLanguage =
        // languages which are not available for the portal should not be preselected
        // because in this case most of the collections will have no use for it
        supportedLanguages.find(({ id, hideOnPortal }) => id === language && !hideOnPortal) ||
        supportedLanguages[0]
    }

    platformName = platformName || ''

    return (
      <div className={classes.root}>
        {color && <EnvBar color={color} />}

        <LanguageProvider
          value={{
            supportedLanguages,
            selectedLanguage,
            registerLanguageSwitchInterceptor: this.onRegisterLanguageSwitchInterceptor,
            onLanguageChange: this.handleLanguageChange,
          }}
        >
          {header && (
            <Header
              data={header}
              member={member}
              api={api}
              version={version}
              platformName={platformName}
              handleDrawerToggle={this.handleDrawerToggle}
            />
          )}

          <div className={classnames(classes.contentWrapper, { [classes.contentWrapperHeadless]: !header })}>
            {menu && (
              <Menu
                menu={menu}
                header={header}
                member={member}
                platformName={platformName}
                children={this.props.children}
                showMenu={showMenu}
                handleDrawerToggle={this.handleDrawerToggle}
              />
            )}
            <main className={classnames(classes.content, { [classes.contentHeadless]: !header })}>
              {menu && (
                <React.Fragment>
                  <Breadcrumbs className={classes.breadcrumbs} menu={menu} />
                  <Divider />
                </React.Fragment>
              )}
              <TransitionGroup className={classnames(classes.page, padding)}>
                <CSSTransition
                  key={routeKey}
                  timeout={{ enter: 1000, exit: 0 }}
                  classNames={{
                    enter: classes.fadeEnter,
                    enterActive: classes.fadeEnterActive,
                  }}
                >
                  <div key={contentKey}>{children}</div>
                </CSSTransition>
              </TransitionGroup>
            </main>
          </div>

          <PageProgress visible={busy} className={classes.loader} />
        </LanguageProvider>
      </div>
    )
  }
}

AppFrame.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AppFrame)
