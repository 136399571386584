import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'
import hotkeys from 'hotkeys-js'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Icon from '@material-ui/core/Icon'

import Factory from '../../atom/FieldFactory/FieldFactory'
import ModelActionBar from '../../molecules/ModelActionBar/ModelActionBar'
import { processActionRequest } from '../ModelViewWrapper/ModelViewWrapper'
import ACTION_NAMES from '../ModelViewWrapper/actionNames'
import { withLanguage } from '../../../LanguageContext'
import Colors from '../../../shared/assets/styles/colors'
import { Prompt } from 'react-router-dom'

const styles = ({ spacing: { unit } }) => ({
  root: {},
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'sticky',
    top: -unit,
    zIndex: 999,
    backgroundColor: Colors.White,
    padding: unit,
    paddingTop: unit * 0.5,
    paddingBottom: unit * 0.5,
    // borderRadius: unit * 0.5,
    margin: -unit,
    marginBottom: unit,
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2)',
  },
})

const nonToolbarActions = [ACTION_NAMES.TEST]

class ModelDetails extends React.Component {
  constructor(props) {
    super(props)

    const { structure } = props

    this.state = {
      id: 0,
      ...props,
      expanded: structure.map(({ optional }) => !optional),
      changed: {},
    }
  }

  componentDidMount() {
    const { actions, registerLanguageSwitchInterceptor } = this.props

    // create an object with the hotkey as key and the element
    let hotkeysActions = actions.reduce((acc, elem) => {
      if (elem.shortCut) {
        elem.shortCut.forEach((h) => (acc[h] = elem))
      }
      return acc
    }, {})

    // get a string of all hotkeys separated by comma
    const shortCutsList = [].concat(...Object.keys(hotkeysActions)).join(',')

    hotkeys(shortCutsList, (event, handler) => {
      event.preventDefault()
      const action = hotkeysActions[handler.shortcut]
      this.handleActionClick(action)
    })
    // this is to enable hotkeys for inputs and other interactive elements
    hotkeys.filter = () => true

    this.unregisterInterceptor = registerLanguageSwitchInterceptor(this.checkChangedLangFields)

    // listener for input errors
    const { api: { connector } = {} } = this.props
    connector && (this.errorSubscription = connector.listenToInputErrors(this.onInputError))
  }

  componentWillUnmount() {
    this.unregisterInterceptor && this.unregisterInterceptor()
    this.errorSubscription && this.errorSubscription.unsubscribe()
  }

  static getChangedLangFields = (changed, schemeData) => {
    return Object.keys(changed).filter((key) => {
      const scheme = schemeData.find((s) => s.name === key)
      return scheme && scheme.settings && scheme.settings.multilang
    })
  }

  checkChangedLangFields = () => {
    return ModelDetails.getChangedLangFields(this.state.changed, this.props.schemeData).length > 0
      ? !window.confirm('You have unsaved changes, are you sure you want to discard them and switch language?')
      : false
  }

  onInputError = (error) => {
    // console.log('onInputError - error', error)
    this.setState((prevState) => {
      const newId = prevState.id + 1
      const schemeData = [...prevState.schemeData]
      schemeData.forEach((item) => {
        if (error.some((n) => n === item.name)) {
          item.error = true
        }
      })
      return { schemeData, id: newId }
    })
  }

  static getDerivedStateFromProps(props, prevState) {
    if (props.selectedLanguage !== prevState.selectedLanguage) {
      let changed = { ...prevState.changed }
      const { schemeData } = props

      ModelDetails.getChangedLangFields(changed, schemeData).forEach((field) => delete changed[field])

      return {
        changed,
        selectedLanguage: props.selectedLanguage,
        id: prevState.id + 1,
      }
    }

    return null
  }

  handleActionClick = async (action) => {
    const { api } = this.props
    const { pendingPatch } = this.state
    const { action: actionName, url } = action

    switch (actionName) {
      case ACTION_NAMES.PATCH: {
        if (pendingPatch) return
        await this.saveChanges(action)
        break
      }
      case ACTION_NAMES.SAVE_FROM_DRAFT: {
        return api.createModelEntry(url, this.state.changed)
      }
      default:
        const { rawData } = this.props
        return processActionRequest(action, api, rawData)
    }
  }

  handleChange = async (event) => {
    const { selectedLanguage } = this.props
    const { target } = event
    let { value, name } = target

    this.setState((prevState) => {
      const schemeDataItem = prevState.schemeData.find((item) => item.name === name) || {}

      let { settings: { multilang: itemMultilang } = {} } = schemeDataItem
      let fieldName = name

      if (itemMultilang) fieldName += `__${selectedLanguage.id}`

      let initialValue = prevState.rawData[fieldName]

      let hasChanged = value !== initialValue

      let { changed } = prevState
      let updateValue = {}

      if (!hasChanged) {
        changed = { ...changed }
        delete changed[name]
        return { changed }
      }

      return {
        ...updateValue,
        changed: {
          ...changed,
          [name]: value,
        },
      }
    })
  }

  saveChanges = async (action) => {
    const {
      api,
      rawData: { _id },
      selectedLanguage,
    } = this.props

    const { changed } = this.state

    const hasChanges = Object.keys(changed).length > 0
    if (!hasChanges) {
      return
    }

    let { url, method } = action
    url = url.replace('[id]', _id)

    let response
    let result
    try {
      this.setState({ pendingPatch: true })
      const lang = selectedLanguage.locale && selectedLanguage.locale.substring(0, 2)
      const { request } = api.updateModelEntry(url, changed, method, lang)

      response = await request
      ;({ result } = response)
    } catch (e) {
      this.setState({ pendingPatch: false })
      console.error('HIER')
      return
    }

    if (!result) {
      this.setState({ pendingPatch: false })
      return
    }

    this.setState({
      ...result,
      changed: {},
      pendingPatch: false,
    })
  }

  handleTrigger = async (actionId, responseCallback) => {
    const { api, rawData, actions } = this.props
    const action = actions.find(({ id }) => id === actionId)
    if (action) {
      try {
        const result = await processActionRequest(action, api, rawData)
        if (typeof result === 'object') await result.request
      } catch (e) {
        console.error('e:', e)
      }
    }
    responseCallback && responseCallback()
  }

  checkFieldVisibility(visibleIf) {
    if (!visibleIf) return true
    // check condition
    const { rawData, changed } = this.state

    const matchCond = (c) => {
      let [name, targetValue] = c.split('.')
      let not = false
      name.startsWith('!') && (name = name.slice(1)) && (not = true)
      const value = name in changed ? changed[name] : rawData[name] || false
      return not
        ? value.toString() !== targetValue
        : value.toString() === targetValue || (targetValue === 'notNull' && !!value)
    }

    return visibleIf.split('||').some((val) => {
      if (val.includes('&')) return val.split('&').every(matchCond)
      else return val.split('|').some(matchCond)
    })
  }

  renderFields(items) {
    const { api } = this.props
    const { schemeData, rawData, changed } = this.state
    const { selectedLanguage, supportedLanguages, sectionType } = this.props

    const multilang = supportedLanguages.length > 1

    return items.map((itemName, i) => {
      const item = schemeData.find((item) => item.name === itemName)

      if (!item) return null

      let { name, label, type, settings: { multilang: itemMultilang } = {}, visibleIf } = item

      if (type === 'media') {
        item[`${name}__multilang`] = rawData[`${name}__multilang`]
      }

      if (!this.checkFieldVisibility(visibleIf)) return null

      let itemNameMultilang = itemName

      if (itemMultilang || (type === 'media' && rawData[`${name}__multilang`]))
        itemNameMultilang += `__${selectedLanguage.id}`

      let value = itemName in changed ? changed[itemName] : rawData[itemNameMultilang]

      if (multilang && itemMultilang) label = `${label || name} (${selectedLanguage.id})`

      const { columnSize = 12 } = item

      return (
        <Grid item xs={columnSize <= 3 ? 6 : 12} md={columnSize} key={i}>
          {Factory.getFieldByType({
            ...item,
            label,
            onChange: this.handleChange,
            onUpdate: this.handleChange,
            onTrigger: this.handleTrigger,
            api,
            selectedLanguage,
            value,
            sectionType,
            controlled: true,
            rawData,
          })}
        </Grid>
      )
    })
  }

  handleExpansion = (event) => {
    const { currentTarget: { id } = {} } = event
    const index = parseInt(id, 10)

    this.setState((prevSate) => {
      const expanded = [...prevSate.expanded]
      expanded[index] = !expanded[index]
      return { expanded }
    })
  }

  render() {
    const {
      structure,
      classes,
      actions,
      history,
      versionHistory,
      rawData: { _id },
      className,
      isDraft,
    } = this.props
    const { expanded, changed, id, pendingPatch } = this.state
    const actionsFiltered = actions && actions.filter(({ id }) => !nonToolbarActions.includes(id))

    const hasChanges = Object.keys(changed).length > 0

    return (
      <React.Fragment>
        {!isDraft && (
          <Prompt
            when={hasChanges}
            message={(location) => {
              return location.pathname === window.location.pathname
                ? true
                : `You have unsaved changes. Are you sure you want to leave this page?`
            }}
          />
        )}

        {actionsFiltered.length > 0 && (
          <div className={classes.actions}>
            <ModelActionBar
              modelId={_id}
              history={history}
              versionHistory={versionHistory}
              actions={actionsFiltered}
              disabled={{
                patch: !hasChanges || pendingPatch,
                saveFromDraft: Object.keys(changed).length === 0,
              }}
              onActionClick={this.handleActionClick}
            />
          </div>
        )}

        <Grid container spacing={8} className={className} key={id}>
          {structure.map((section, i) => {
            const { name, label, hide, hidden, fields, columnSize = 12, visibleIf } = section

            if (hide || hidden) return null
            if (!this.checkFieldVisibility(visibleIf)) return null

            return (
              <Grid item xs={columnSize} key={i}>
                <ExpansionPanel expanded={expanded[i] === true} onChange={this.handleExpansion}>
                  <ExpansionPanelSummary id={i} expandIcon={<Icon>expand_more</Icon>}>
                    <Typography variant="h5" align="left" color={'primary'}>
                      {label || name}
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid container spacing={24}>
                      {this.renderFields(fields)}
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Grid>
            )
          })}
        </Grid>
      </React.Fragment>
    )
  }
}

export default withLanguage(withStyles(styles)(ModelDetails))
