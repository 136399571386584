import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import ImageSelection from './ImageSelection'
import { styles } from './Styles'
import sizeImagePath from '../../../../shared/utils/sizeImagePath'

class CustomUserMedia extends React.Component {
  render() {
    const { classes, rawData, settings: { height: customHeight } = {}, value } = this.props
    const mediaId = window.location.pathname.split('/').pop()
    const fileExtension = rawData.mimetype.split('/').pop()
    const mediaPath = value.path + `/${mediaId}.${fileExtension}`
    const isVideo = /video/.test(rawData.mimetype)
    value.type = rawData.mimetype
    value.path = isVideo ? mediaPath : sizeImagePath(mediaPath, 'sm')
    return (
      <ImageSelection
        showControls={false}
        classes={classes}
        value={value}
        handleOpen={undefined}
        disabled
        handleDeleteImageFromItem={undefined}
        clickedOnVideo={undefined}
        isPlaying={false}
        customUserMedia={!isVideo}
        customHeight={customHeight}
      />
    )
  }
}

export default withStyles(styles)(CustomUserMedia)
