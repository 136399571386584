import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'

const styles = (theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  backButton: {
    marginLeft: theme.spacing.unit * 0.5,
    marginRight: theme.spacing.unit * 1.5,
  },
})

class Breadcrumbs extends React.Component {
  constructor(props) {
    super(props)

    this.crumbs = []
    this.renderCrumbs = this.renderCrumbs.bind(this)
  }

  getItemLabel(item) {
    return item.label.charAt(0).toUpperCase() + item.label.slice(1)
  }

  addCrumbs(entry, item, useLink = false, key) {
    this.crumbs.push(entry.label)
    if (!useLink) {
      this.crumbs.push(this.getItemLabel(item))
    } else {
      this.backUrl = item.to
      this.addItemLink(item, key)
    }
  }

  addItemLink(item, key) {
    let linkText = this.getItemLabel(item)
    this.crumbs.push(
      <Link to={item.to || '#'} key={key}>
        {linkText}
      </Link>,
    )
  }

  renderCrumbs() {
    this.crumbs = []
    let path = this.props.location.pathname

    // Search for current menu item
    this.props.menu.find((parent) => {
      return parent.items.find((item, k) => {
        // First level children - no link needed
        if (item.to === path) {
          this.addCrumbs(parent, item, false, k)
          return true
        }

        // Second level children - link to first level
        let pathRegex = RegExp(item.to + '/.+')
        let referencePathRegex = RegExp(item.referenceUrl + '/.+')
        if (path.match(pathRegex) || path.match(referencePathRegex)) {
          this.addCrumbs(parent, item, true, k)
          this.crumbs.push(path.split('/').pop())
          return true
        }

        return false
      })
    })

    // Join crumbs using separator
    let separetedCrumbs = []
    this.crumbs.map((item, index) => {
      separetedCrumbs.push(<span key={index}>{item}</span>)
      if (index + 1 < this.crumbs.length) {
        separetedCrumbs.push(' / ')
      }
      return null
    })

    return separetedCrumbs
  }

  handleBack = () => {
    this.props.history.push(this.backUrl)
  }

  render() {
    const { classes, className, style } = this.props

    this.backUrl = null
    const breadCrumbs = this.renderCrumbs()

    if (!breadCrumbs || !breadCrumbs.length) return null

    return (
      <div className={classNames(classes.root, className)} style={style}>
        <IconButton
          className={classes.backButton}
          disabled={!this.backUrl}
          onClick={this.handleBack}
        >
          <Icon>arrow_back</Icon>
        </IconButton>

        <Typography variant="subtitle1" color="inherit">
          {breadCrumbs}
        </Typography>
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(Breadcrumbs))
