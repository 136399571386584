import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import FormHelperText from '@material-ui/core/FormHelperText'
import Icon from '@material-ui/core/Icon/Icon'
import MaskedInput from 'react-text-mask'

const MaskContext = React.createContext(null)

class TextInputField extends React.Component {
  constructor(props) {
    super(props)
    this.state = { value: props.value }
  }

  render() {
    const {
      classes,
      data,
      value,
      className,
      invalid,
      onBlur,
      onFocus,
      tabIndex,
      tipRender,
      fullWidth,
      disabled,
    } = this.props

    const {
      id,
      readOnly,
      placeholder,
      required,
      pattern,
      multiline,
      type,
      hidden,
      rows,
      mask,
    } = data

    let errorText
    if (this.props.errorText) {
      errorText = this.props.errorText
    } else {
      errorText = data.errorText
    }

    const isPassword = data.type === 'password'

    const shrinkLabel = type === 'date' || !!this.state.value

    const form = (
      <FormControl
        style={{ display: hidden && 'none' }}
        fullWidth={fullWidth}
        key={id}
        className={className}
        required={required}
        aria-describedby={`${id}-error-text`}
        disabled={readOnly || disabled}
        onBlur={onBlur}
        onFocus={onFocus}
        error={invalid && !disabled}
      >
        <InputLabel htmlFor={id} required={false} shrink={shrinkLabel}>
          {placeholder}
        </InputLabel>
        <Input
          id={data.id}
          multiline={multiline}
          rows={rows || 4}
          rowsMax="8"
          type={isPassword && this.state.showPassword ? 'text' : type}
          value={isPassword ? this.state.password : undefined}
          defaultValue={value}
          onChange={this._handleChange}
          onKeyUp={(e) => e.keyCode === 27 && e.target.blur()}
          inputRef={(ref) => (this.inputRef = ref)}
          inputComponent={mask ? TextMaskCustom : undefined}
          inputProps={{
            tabIndex: tabIndex,
            pattern: pattern,
            className:
              isPassword && !this.state.showPassword
                ? classes.passwordBullets
                : multiline
                ? classes.inputTextMulti
                : classes.inputText,
          }}
          endAdornment={
            isPassword ? (
              <InputAdornment position="end">
                <IconButton
                  className={classes.visibilityButton}
                  aria-label="Toggle password visibility"
                  onClick={this._handleClickShowPassword}
                  onMouseDown={this._handleMouseDownPassword}
                >
                  <Icon>
                    {this.state.showPassword ? 'visibility_off' : 'visibility'}
                  </Icon>
                </IconButton>
              </InputAdornment>
            ) : null
          }
        />
        <FormHelperText id={`${id}-error-text`}>
          {errorText && invalid ? errorText : ''}
        </FormHelperText>
        {tipRender && tipRender({ className: classes.tipBox })}
      </FormControl>
    )

    if (Array.isArray(mask))
      return (
        <MaskContext.Provider value={mask.map((m) => (m === '#' ? /\d/ : m))}>
          {form}
        </MaskContext.Provider>
      )

    return form
  }

  blur = () => this.inputRef && this.inputRef.blur()

  _handleChange = (e) => {
    this.setState({ value: e.target.value })

    const { onChange } = this.props
    onChange && onChange(e)
  }

  _handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  _handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }))
  }

  checkValidity = () => {
    return this.inputRef && this.inputRef.checkValidity()
  }

  getValue = () => this.state.value
}

const styles = ({ spacing: { unit } }) => ({
  passwordBullets: {
    letterSpacing: 3.8,
  },
  inputTextMulti: {
    marginTop: unit * 2,
  },
  inputText: {},
  visibilityButton: {
    right: -unit / 2,
  },
  tipBox: {
    position: 'absolute',
    top: '100%',
    right: 0,
    marginTop: -20,
    zIndex: 100,
  },
})

const TextMaskCustom = (props) => {
  let { inputRef, ...other } = props

  return (
    <MaskContext.Consumer>
      {(mask) => (
        <MaskedInput
          {...other}
          ref={(ref) => {
            inputRef(ref ? ref.inputElement : null)
          }}
          mask={mask}
          placeholderChar={'\u2000'}
          placeholder={mask
            .map((m) => (typeof m === 'object' ? '\u2000' : m))
            .join('')}
        />
      )}
    </MaskContext.Consumer>
  )
}

export default withStyles(styles)(TextInputField)
