import React from 'react'
import { withRouter } from 'react-router-dom'

const ApiContext = React.createContext(null)

class ApiProvider extends React.Component {
  constructor(props) {
    super(props)

    props.api.connector.onNavigateTo = this.navigateTo

    // if (process.env.NODE_ENV === 'development')
    window.navigateTo = this.navigateTo
  }

  navigateTo = (href) => {
    if (!href) return
    const isSilent = href.startsWith('$currentSilently')
    const isReload = href.startsWith('$current')
    const isBack = href === '$back'
    const isParent = href === '$parent'
    const isPopup = href.startsWith('#')
    const {
      history,
      location: { state: { code: currentCode } = {} },
    } = this.props
    let code = currentCode
    let notClosable = href.endsWith('!')
    if (notClosable) href = href.slice(0, -1)

    if (isBack) {
      return window.history.back()
    }

    if (isParent) {
      const newPathName = window.location.pathname.substring(
        0,
        window.location.pathname.lastIndexOf('/'),
      )
      return (window.location = window.location.origin + newPathName)
    }

    if (/^http/.test(href)) {
      return (window.location.href = href)
    }

    if (isSilent || isReload) {
      code = parseInt((Math.random() * 99999).toFixed(0), 10).toString(36)

      let [, search, hash] = /(\?.+?)?(#.*?)?$$/.exec(href) || []

      href =
        window.location.pathname +
        (search || window.location.search) +
        (hash || window.location.hash)
    } else if (isPopup) {
      href = window.location.pathname + window.location.search + href
    }

    history.push(href, { code, isSilent, notClosable })
  }

  render() {
    const { api, children } = this.props
    return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>
  }
}

const ApiConsumer = ApiContext.Consumer

const withApi = (Component) => (props) => {
  return (
    <ApiConsumer>{(api) => <Component {...props} api={api} />}</ApiConsumer>
  )
}

ApiProvider = withRouter(ApiProvider)

export { ApiProvider, ApiConsumer, withApi }
