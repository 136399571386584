import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import LoginForm from '../../../shared/components/organisms/Form'
import SystemPageTemplate from '../../../shared/components/templates/SystemPageTemplate'

const fields = [
  {
    value: '',
    name: 'emailAddress',
    type: 'email',
    defaultColumn: true,
  },
  {
    name: 'password',
    label: 'Password',
    type: 'password',
    value: '',
  },
]

class AdminLoginPage extends React.Component {
  state = { schemeData: fields }

  render() {
    const { api, pageTitle, loginForm, classes } = this.props

    return (
      <SystemPageTemplate title={pageTitle} className={classes.container}>
        <LoginForm
          {...loginForm}
          onSubmitClick={(data) => {
            api.login({ form: data })
          }}
        />
      </SystemPageTemplate>
    )
  }
}

const styles = ({ spacing: { unit } }) => ({
  root: {},
  container: {
    height: 'inherit',
    '& > *': {
      position: 'relative',
    },
  },
})

export default withStyles(styles)(AdminLoginPage)
