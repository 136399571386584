import React, { Component } from 'react'
import CreatableSelect from 'react-select/lib/Creatable'
import { styles } from './Styles'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'

class TagSelection extends Component {
  state = {
    value: [],
    inputValue: '',
  }

  componentDidMount() {
    const { defaultValue = [] } = this.props

    this.setState({
      value: defaultValue,
    })
  }

  render() {
    const {
      classes,
      parsedTags,
      isDisabled,
      defaultValue = [],
      isFullWidth,
      onHandleCreate = () => {},
      onHandleAddChip = () => {},
      isUpload,
    } = this.props

    const { value, inputValue } = this.state

    return (
      <CreatableSelect
        defaultValue={defaultValue}
        isMulti
        isValidNewOption={(inputValue, selectValue, selectOptions) => {
          const isNotDuplicated = !selectOptions
            .map((option) => option.label)
            .includes(inputValue)
          const isNotEmpty = inputValue !== ''
          return isNotEmpty && isNotDuplicated
        }}
        onCreateOption={(inputValue) => {
          const newValues = [
            ...this.state.value,
            { label: inputValue, value: inputValue },
          ]
          this.setState({
            inputValue: '',
            value: newValues,
          })

          const parsedNewValues = newValues.map((t) => t.value)

          return (
            onHandleCreate &&
            onHandleCreate(inputValue, parsedNewValues, isUpload)
          )
        }}
        value={value}
        inputValue={inputValue}
        classNamePrefix="react-select"
        menuPlacement="bottom"
        // menuIsOpen
        className={classNames(
          classes.menuMultiChip,
          isFullWidth && 'fullWidth',
        )}
        options={parsedTags}
        isDisabled={isDisabled}
        onInputChange={(inputValue) => {
          this.setState({ inputValue })
        }}
        onChange={(value = []) => {
          this.setState({ value })
          return onHandleAddChip && onHandleAddChip(value.map((t) => t.value))
        }}
      />
    )
  }
}

export default withStyles(styles)(TagSelection)
