import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'

const styles = () => ({
  root: {},
  select: {
    minHeight: 34,
  },
  paddingSelect: {
    paddingTop: 0,
  },
})

class DropDown extends React.Component {
  render() {
    const { classes, value, options, onChange, label, placeholder, ...rest } = this.props

    return (
      <FormControl fullWidth>
        {label && <InputLabel shrink>{label}</InputLabel>}
        <Select
          className={classes.select}
          value={value || ''}
          displayEmpty
          onChange={onChange}
          MenuProps={{ MenuListProps: { classes: { padding: classes.paddingSelect } } }}
          {...rest}
        >
          {!value && (
            <MenuItem value={''} disabled>
              {placeholder ? placeholder : 'Please select'}
            </MenuItem>
          )}
          {options &&
            options.map((option) => {
              if (!option) return null

              let { value, label } = option

              return (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              )
            })}
        </Select>
      </FormControl>
    )
  }
}

export default withStyles(styles)(DropDown)
