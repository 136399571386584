import Colors from '../../../shared/assets/styles/colors'

const iconAndColorByStatus = {
  edit: { icon: 'edit', color: Colors.DarkGrey }, // 'hourglass_empty' 'more_horiz'
  running: { icon: 'hourglass_empty', color: Colors.DarkGrey }, // 'hourglass_empty' 'more_horiz'
  new: { icon: 'hourglass_empty', color: Colors.DarkGrey }, // 'hourglass_empty' 'more_horiz'
  processing: { icon: 'find_in_page', color: Colors.DarkGrey }, //'search' 'pageview' 'supervisor_account'
  onhold: { icon: 'pause_circle_outline_outlined', color: Colors.DarkGrey },
  dispatched: { icon: 'local_shipping', color: Colors.DarkGrey },
  returned: { icon: 'production_quantity_limits', color: Colors.Red },
  cancelled: { icon: 'block', color: Colors.Red },
  rejected: { icon: 'block', color: Colors.Red },
  inactive: { icon: 'block', color: Colors.Red },
  active: { icon: 'check_circle_outline', color: Colors.Brand },
  deleted: { icon: 'delete', color: Colors.Red },

  publiconly: { icon: 'flight_land', color: Colors.GrassGreen },
  public: { icon: 'lock_open', color: Colors.GrassGreen },
  restricted: { icon: 'lock', color: Colors.DarkGrey },

  purchase: { icon: 'money', color: Colors.DarkGrey },
  percentage: { icon: 'money_off', color: Colors.DarkGrey },

  member: { icon: 'how_to_reg', color: Colors.CorporateGreen },
  phishy: { icon: 'person', color: Colors.Orange },
  test: { icon: 'person', color: Colors.CoolGrey },
  blocked: { icon: 'error', color: Colors.PaleRed },

  draft: { icon: 'create', color: Colors.DarkGrey },
  activated: { icon: 'send', color: Colors.PaleRed },
  completed: { icon: 'check_circle', color: Colors.GrassGreen },

  delivered : { icon: 'check_circle', color: Colors.GrassGreen },
  approved : { icon: 'check_circle', color: Colors.GrassGreen },
  sent : { icon: 'check_circle', color: Colors.LightGrey },
  failed: { icon: 'block', color: Colors.Red },

  // Veriff
  abandoned: { icon: 'history_toggle_off', color: Colors.Red },
  declined: { icon: 'block', color: Colors.Red },
  expired: { icon: 'schedule', color: Colors.Red },
  resubmission_requested: { icon: 'warning', color: Colors.Orange },

  OutOfStock: { icon: 'error', color: Colors.Red },
  LimitedAvailability: { icon: 'warning', color: Colors.Orange },
  InStock: { icon: 'check_circle', color: Colors.GrassGreen },

  email: { icon: 'email', color: Colors.DarkGrey },
  sms: { icon: 'sms', color: Colors.DarkGrey },
  push: { icon: 'tab', color: Colors.DarkGrey },
  webhook: { icon: 'code', color: Colors.DarkGrey },
  selfmailer: { icon: 'markunread_mailbox', color: Colors.DarkGrey },

  exportdata: { icon: 'cloud_upload' },
  changehook: { icon: 'rss_feed' },
  importdata: { icon: 'cloud_download' },
  syncfilessftp: { icon: 'file_copy' },

  article: { icon: 'notes' },
  quiz: { icon: 'question_answer' },
  upload: { icon: 'backup' },
}

const getIconAndColorByStatus = (status) => {
  return (
    iconAndColorByStatus[status] || { icon: 'flash_on', color: Colors.CoolGrey }
  )
}

export { getIconAndColorByStatus }
