import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import Modal from '@material-ui/core/Modal/Modal'
import Fade from '@material-ui/core/Fade/Fade'
import { duration } from '@material-ui/core/styles/transitions'

export const styles = ({ breakpoints, spacing: { unit } }) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    [breakpoints.up('sm')]: {
      padding: unit * 2,
    },
  },
  paper: {
    outline: 'none',
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: 'none',
    '& > *': {
      pointerEvents: 'all',
    },
  },
})

class Popup extends React.Component {
  render() {
    const {
      BackdropProps,
      children,
      classes,
      className,
      disableBackdropClick,
      disableEscapeKeyDown,
      onClose,
      onEnter,
      onEntered,
      onEntering,
      onEscapeKeyDown,
      onExit,
      onExited,
      onExiting,
      open,
      transitionDuration,
      TransitionProps,
      ...other
    } = this.props

    return (
      <Modal
        // disableAutoFocus
        disableEnforceFocus
        className={classNames(classes.root, className)}
        BackdropProps={{
          transitionDuration,
          ...BackdropProps,
        }}
        disableBackdropClick={disableBackdropClick}
        disableEscapeKeyDown={disableEscapeKeyDown}
        onEscapeKeyDown={onEscapeKeyDown}
        onClose={onClose}
        open={open}
        role="dialog"
        {...other}
      >
        <Fade
          appear
          in={open}
          timeout={transitionDuration}
          onEnter={onEnter}
          onEntering={onEntering}
          onEntered={onEntered}
          onExit={onExit}
          onExiting={onExiting}
          onExited={onExited}
          {...TransitionProps}
        >
          <div className={classes.paper}>{children}</div>
        </Fade>
      </Modal>
    )
  }
}

Popup.propTypes = {
  /**
   * @ignore
   */
  BackdropProps: PropTypes.object,
  /**
   * Popup children, usually the included sub-components.
   */
  children: PropTypes.node.isRequired,
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css-api) below for more details.
   */
  classes: PropTypes.object.isRequired,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * If `true`, clicking the backdrop will not fire the `onClose` callback.
   */
  disableBackdropClick: PropTypes.bool,
  /**
   * If `true`, hitting escape will not fire the `onClose` callback.
   */
  disableEscapeKeyDown: PropTypes.bool,
  /**
   * Callback fired when the backdrop is clicked.
   */
  onBackdropClick: PropTypes.func,
  /**
   * Callback fired when the component requests to be closed.
   *
   * @param {object} event The event source of the callback
   */
  onClose: PropTypes.func,
  /**
   * Callback fired before the dialog enters.
   */
  onEnter: PropTypes.func,
  /**
   * Callback fired when the dialog has entered.
   */
  onEntered: PropTypes.func,
  /**
   * Callback fired when the dialog is entering.
   */
  onEntering: PropTypes.func,
  /**
   * Callback fired when the escape key is pressed,
   * `disableKeyboard` is false and the modal is in focus.
   */
  onEscapeKeyDown: PropTypes.func,
  /**
   * Callback fired before the dialog exits.
   */
  onExit: PropTypes.func,
  /**
   * Callback fired when the dialog has exited.
   */
  onExited: PropTypes.func,
  /**
   * Callback fired when the dialog is exiting.
   */
  onExiting: PropTypes.func,
  /**
   * If `true`, the Popup is open.
   */
  open: PropTypes.bool.isRequired,
  /**
   * Properties applied to the [`Paper`](/api/paper) element.
   */
  PaperProps: PropTypes.object,
  /**
   * The duration for the transition, in milliseconds.
   * You may specify a single timeout for all transitions, or individually with an object.
   */
  transitionDuration: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({ enter: PropTypes.number, exit: PropTypes.number }),
  ]),
  /**
   * Properties applied to the `Transition` element.
   */
  TransitionProps: PropTypes.object,
}

Popup.defaultProps = {
  disableBackdropClick: false,
  disableEscapeKeyDown: false,
  transitionDuration: {
    enter: duration.enteringScreen,
    exit: duration.leavingScreen,
  },
}

export default withStyles(styles, { name: 'MuiPopup' })(Popup)
