import React from 'react'
import FormControl from '@material-ui/core/FormControl/FormControl'
import InputLabel from '@material-ui/core/InputLabel/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText'
import Input from '@material-ui/core/Input/Input'
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment'
import IconButton from '@material-ui/core/IconButton/IconButton'
import Icon from '@material-ui/core/Icon/Icon'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import { capitalize } from '@material-ui/core/utils/helpers'

const styles = ({ palette, spacing: { unit } }) => ({
  root: {},
  input: {
    textAlign: 'center',
  },
  button: {
    padding: 0,
    width: unit * 3,
    height: unit * 3,
  },
  buttonDisabled: {
    color: palette.action.disabled,
  },
  buttonStart: {
    marginLeft: -unit * 0.5,
    marginRight: 0,
  },
  buttonEnd: {
    marginRight: -unit * 0.5,
  },
})

class NumberField extends React.Component {
  handleBlur = (e) => {
    const { onBlur, type, name, min, max } = this.props
    let {
      target: { value },
    } = e

    value = parseFloat(value)
    if (isNaN(value)) {
      value = 0
    }

    if (max !== undefined) {
      value = Math.min(max, value)
    }
    if (min !== undefined) {
      value = Math.max(min, value)
    }

    onBlur &&
      onBlur({
        target: {
          type,
          name,
          value,
        },
      })
  }

  handleButtonDown = (e) => {
    e.preventDefault()
  }

  handleButtonClick = (add) => (e) => {
    const { step = 1, disabled } = this.props
    let { value } = this.input

    if (disabled) {
      return
    }

    this.input && this.input.focus()

    value = parseFloat(value)
    if (isNaN(value)) {
      value = 0
    }

    if (add) {
      value += step
    } else {
      value -= step
    }

    this._propagateValue(value)
  }

  _propagateValue = (value, validate = true) => {
    const { onChange, type, name, min, max } = this.props

    if (validate) {
      if (max !== undefined) {
        value = Math.min(max, value)
      }
      if (min !== undefined) {
        value = Math.max(min, value)
      }
    }

    onChange &&
      onChange({
        target: {
          type,
          name,
          value,
        },
      })
  }

  _getButtonProps = (disabled, position, handler) => {
    const { classes } = this.props

    return {
      className: classNames(
        classes.button,
        classes[`button${capitalize(position)}`],
        { [classes.buttonDisabled]: disabled },
      ),
      disableRipple: disabled,
      disableTouchRipple: disabled,
      onClick: handler,
      onMouseDown: this.handleButtonDown,
      onTouchStart: this.handleButtonDown,
      tabIndex: -1,
    }
  }

  render() {
    const {
      className,
      classes,
      label,
      value,
      name,
      min,
      max,
      onChange,
      helperText,
      disabled,
      inputProps,
      InputProps,
      fullWidth,
    } = this.props

    const addDisabled = max !== undefined && value >= max
    const subtractDisabled = min !== undefined && value <= min

    const { classes: inputPropsClasses = {} } = InputProps || {}

    return (
      <FormControl
        className={classNames(className, classes.root)}
        fullWidth={fullWidth}
      >
        {label && <InputLabel>{label}</InputLabel>}
        <Input
          inputRef={(ref) => (this.input = ref)}
          inputProps={inputProps}
          min={min}
          max={max}
          disabled={disabled}
          value={value}
          name={name}
          onChange={onChange}
          onBlur={this.handleBlur}
          startAdornment={
            <InputAdornment position="start" style={{ marginRight: 0 }}>
              <IconButton
                {...this._getButtonProps(
                  subtractDisabled || disabled,
                  'start',
                  this.handleButtonClick(false),
                )}
              >
                <Icon>remove</Icon>
              </IconButton>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end" style={{ marginLeft: 0 }}>
              <IconButton
                {...this._getButtonProps(
                  addDisabled || disabled,
                  'end',
                  this.handleButtonClick(true),
                )}
              >
                <Icon>add</Icon>
              </IconButton>
            </InputAdornment>
          }
          {...InputProps}
          classes={{ ...inputPropsClasses, input: classes.input }}
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    )
  }
}

export default withStyles(styles)(NumberField)
