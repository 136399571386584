import React from 'react'
import classNames from 'classnames'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import PendingButton from '../PendingButton/PendingButton'
import Colors from '../../../assets/styles/colors'
import Pill from '../Pills/Pill'
import { Link } from 'react-router-dom'

const styles = ({ breakpoints, spacing: { unit } }) => ({
  container: {
    boxSizing: 'border-box',
    padding: unit * 2,
    paddingTop: unit * 3,
    paddingBottom: unit * 3,
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.1)',
    borderRadius: 5,
    borderColor: Colors.Black012,
  },
  headline: {
    marginBottom: unit,
  },
  spacious: {
    padding: unit * 2,
  },
  button: {
    marginTop: unit * 2,
  },
  cancelButton: {
    color: Colors.White,
    marginRight: unit,
    paddingTop: unit,
    paddingBottom: unit,
    height: 'auto',
    [breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
  inactive: {
    pointerEvents: 'none',
    opacity: 0.7,
  },
  buttonPill: {
    marginTop: unit * 2,
    marginRight: 50,
    paddingTop: unit,
    paddingBottom: unit,
    // height: 36,
    paddingLeft: unit * 1.3333,
    paddingRight: unit * 1.3333,
    borderRadius: 2,
    position: 'relative',
    boxShadow:
      '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)',
  },
  pill: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    marginTop: 'auto',
    marginBottom: 'auto',
    left: '100%',
  },
  buttonContainer: {
    [breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  secondButton: {
    [breakpoints.down('xs')]: {
      marginTop: unit,
    },
  },
})

const DEFAULT_WIDTH = 360

const PaperBox = withStyles(styles)(
  ({
    classes,
    className = '',
    style,
    width,
    square = true,
    fixedWidth,
    buttonLabel,
    buttonCancelLabel,
    onButtonClick,
    onCancelButtonClick,
    title,
    children,
    elevation,
    buttonTo,
    buttonAlign = 'center',
    variant,
    disabled,
    pending,
    jpoints,
  }) => {
    const variantClass = classes[variant] || ''

    const buttonProps = {}
    if (onButtonClick) {
      buttonProps.onClick = onButtonClick
    } else if (buttonTo) {
      buttonProps.component = Link
      buttonProps.to = buttonTo
    }

    return (
      <Paper
        className={classNames(classes.container, variantClass, className, {
          [classes.inactive]: disabled || pending,
        })}
        square={square}
        style={
          fixedWidth
            ? { ...style, width: fixedWidth }
            : { ...style, width: '100%', maxWidth: width || DEFAULT_WIDTH }
        }
        elevation={0}
      >
        {/* TITLE */}
        {title && (
          <Typography variant="h5" className={classNames(classes.headline)}>
            {title}
          </Typography>
        )}

        {/* CONTENT */}
        {children}

        {/* BUTTON */}
        {buttonLabel && (
          <div align={buttonAlign} className={classes.buttonContainer}>
            {buttonCancelLabel && (
              <PendingButton
                type={'cancel'}
                pending={pending}
                variant="contained"
                color="primary"
                className={classNames(classes.button, classes.cancelButton)}
                onClick={onCancelButtonClick}
              >
                {buttonCancelLabel}
              </PendingButton>
            )}

            {jpoints ? (
              <Button
                variant={'contained'}
                color="secondary"
                type={'submit'}
                className={classNames(classes.buttonPill, classes.secondButton)}
                {...buttonProps}
              >
                {buttonLabel}
                <Pill label={jpoints} placement="l" className={classes.pill} />
              </Button>
            ) : (
              <PendingButton
                type={'submit'}
                pending={pending}
                variant="outlined"
                color="secondary"
                className={classNames(classes.button, classes.secondButton)}
                jpoints={jpoints}
                {...buttonProps}
              >
                {buttonLabel}
              </PendingButton>
            )}
          </div>
        )}
      </Paper>
    )
  },
)

export default PaperBox
