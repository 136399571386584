import React from 'react'
import { Doughnut, Pie, Line, Scatter, Bar, HorizontalBar, Radar, Polar, Bubble } from 'react-chartjs-2'

const componentByType = {
  doughnut: Doughnut,
  pie: Pie,
  line: Line,
  scatter: Scatter,
  bar: Bar,
  horizontalBar: HorizontalBar,
  radar: Radar,
  polar: Polar,
  bubble: Bubble,
}

const Chart = (props) => {
  const { data, chartType, options = {} } = props
  let Component = componentByType[chartType] || Pie

  return (
    <React.Fragment>
      <div style={{ width: '100%' }}>
        <Component data={data} height={300} options={{ maintainAspectRatio: false, ...options }} redraw />
      </div>
    </React.Fragment>
  )
}

export default Chart
