import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import InputLabel from '@material-ui/core/InputLabel'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import DropDown from './DropDown'
import MultiInput from './MultiInput'
import { withApi } from '../../../../shared/api/ApiContext'
import { insertBetween } from '../../../../shared/utils'
import NumberField from '../../../../shared/components/NumberField'
import CustomMultipleMenu from '../CustomMultipleMenu'
import CustomMenuField from '../CustomMenuField'
import TrueFalseSwitch from './TrueFalseSwitch'

const styles = () => ({
  root: {},
  select: {
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: 34,
  },
  condition: {
    width: '100%',
    margin: 0,
    borderRadius: 5,
    border: '1px solid rgba(0,0,0,0.1)',
  },
  and: {
    margin: `4px 0`,
  },
  addButton: {
    marginTop: 5,
  },
})

class SegmentationQuery extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  async componentDidMount() {
    const { api } = this.props

    let { request } = api.getSegmentationOptions()

    let options
    let groupedOptions
    try {
      options = await request

      const clusters = []
      groupedOptions = options.reduce((accu, opt) => {
        if (!clusters.includes(opt.cluster)) {
          clusters.push(opt.cluster)
          accu.push({
            value: opt.cluster,
            label: opt.cluster,
            disabled: true,
            isClusterName: true,
          })
        }
        accu.push(opt.field)
        return accu
      }, [])
    } catch (e) {
      return
    }

    this.setState({ options, groupedOptions })
  }

  _getConditionByIndex = (index) => {
    const { value: query } = this.props
    return query[index]
  }

  _updateCondition = (index, data) => {
    const { value: query } = this.props

    let condition = this._getConditionByIndex(index)

    const newQuery = [...query]
    condition = { ...condition, ...data }
    newQuery[index] = condition

    const { onChange } = this.props

    onChange({
      target: {
        value: newQuery,
        name: this.props.name,
      },
    })
  }

  handleFieldChange = (index) => (e) => {
    const { options } = this.state
    const condition = this._getConditionByIndex(index)
    const { value } = e.target

    let data = {
      field: value,
      value: null,
    }

    const option = options.find((o) => o.field === value)
    const { operators = [] } = option

    const { operator } = condition
    if (!operators.includes(operator))
      data.operator = operators[0] ? operators[0].value : null

    this._updateCondition(index, data)
  }

  handleOperatorChange = (index) => (e) => {
    const { value } = e.target
    this._updateCondition(index, { operator: value })
  }

  handleValueChange = (index) => (e) => {
    const { value } = e.target

    this._updateCondition(index, { value })
  }

  handleRemoveCondition = (index) => () => {
    const { value: query, onChange } = this.props

    const newQuery = [...query]
    newQuery.splice(index, 1)

    onChange({
      target: {
        value: newQuery,
        name: this.props.name,
      },
    })
  }

  handleAddCondition = () => {
    const { value: query, onChange } = this.props

    const newQuery = [...query, {}]

    onChange({
      target: {
        value: newQuery,
        name: this.props.name,
      },
    })
  }

  renderValueInput = (item, options, operator, index) => {
    const { classes } = this.props
    const { value = [] } = item
    const { type, values } = options

    if (operator === 'nullish')
      return null

    switch (type) {
      case 'date':
        return (
          <CustomMenuField
            style={{ minHeight: 34 }}
            value={value}
            options={values}
            onChange={this.handleValueChange(index)}
          />
        )
      case 'text':
        return (
          <MultiInput value={value} onChange={this.handleValueChange(index)} />
        )
      case 'enum':
      case 'multiple':
      case 'reference':
      case 'json':
        return (
          <CustomMultipleMenu
            classes={{ select: classes.select }}
            value={value}
            options={values}
            onChange={this.handleValueChange(index)}
          />
        )
      case 'boolean':
        return (
          <TrueFalseSwitch
            value={value}
            onChange={this.handleValueChange(index)}
          />
        )
      case 'integer':
      case 'float':
        return (
          <NumberField
            inputProps={{
              step: type === 'integer' ? 1.0 : undefined,
            }}
            // eslint-disable-next-line
            InputProps={{
              style: { minHeight: 34 },
            }}
            type={'number'}
            value={value || 0}
            fullWidth
            onChange={this.handleValueChange(index)}
          />
        )
      default:
        return null
    }
  }

  render() {
    let { classes, className, value = [{}], label } = this.props

    if (!value) value = [{}]
    if (!Array.isArray(value)) value = [value || {}]

    let disabledRemoving = value.length === 1

    const { options, groupedOptions } = this.state

    const optionsLoaded = !!options

    return (
      <div className={classNames(classes.root, className)}>
        <InputLabel style={{ position: 'inherit' }} shrink>
          {label}
        </InputLabel>
        {insertBetween(
          value.map((item, i) => {
            const { field, operator } = item

            const fieldOptions =
              options && options.find((o) => o.field === field)

            return (
              <Grid
                container
                spacing={16}
                alignItems={'center'}
                className={classes.condition}
                key={`condition_${i}`}
              >
                <Grid item xs={4}>
                  <DropDown
                    onChange={this.handleFieldChange(i)}
                    disabled={!optionsLoaded}
                    value={field}
                    options={groupedOptions}
                  />
                </Grid>

                <Grid item xs={2}>
                  {(!optionsLoaded ||
                    (fieldOptions && fieldOptions.operators)) && (
                    <DropDown
                      onChange={this.handleOperatorChange(i)}
                      disabled={!optionsLoaded}
                      value={operator}
                      options={fieldOptions && fieldOptions.operators}
                    />
                  )}
                </Grid>

                <Grid container item xs={6} direction="row">
                  <div
                    style={{ display: 'flex', flex: 1, alignItems: 'center' }}
                  >
                    {optionsLoaded &&
                      fieldOptions &&
                      this.renderValueInput(item, fieldOptions, operator, i)}
                  </div>

                  <IconButton
                    onClick={this.handleRemoveCondition(i)}
                    disabled={disabledRemoving}
                  >
                    <Icon>delete</Icon>
                  </IconButton>
                </Grid>
              </Grid>
            )
          }),
          (i) => (
            <Typography key={`and_${i}`} className={classes.and}>
              <b>AND</b>
            </Typography>
          ),
        )}

        <Button
          className={classes.addButton}
          variant={'outlined'}
          onClick={this.handleAddCondition}
        >
          Add Condition
        </Button>
      </div>
    )
  }
}

export default withApi(withStyles(styles)(SegmentationQuery))
