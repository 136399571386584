import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import IconButton from '@material-ui/core/IconButton/IconButton'
import Icon from '@material-ui/core/Icon/Icon'
import Colors from '../../assets/styles/colors'
import classNames from 'classnames'

const styles = ({ breakpoints, spacing: { unit } }) => ({
  root: {
    zIndex: 1,
    width: '100%',
    minHeight: unit * 4,
    backgroundColor: Colors.White,
    position: 'relative',
  },
  button: {
    position: 'absolute',
    right: unit / 3,
    top: unit / 3,
  },
  nakedButton: {
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    borderRadius: '0 0 0 50%',
  },
  content: {
    padding: unit,
    paddingBottom: unit * 1,
    paddingRight: unit * 3,
    [breakpoints.up('sm')]: {
      padding: unit * 2,
      paddingRight: unit * 5,
    },
    [breakpoints.down('xs')]: {
      paddingTop: unit * 3,
    },
  },
})

const PopuHeader = withStyles(styles)(
  ({
    classes,
    className,
    style,
    contentClassName,
    contentStyles,
    hideCloseButton,
    onClose,
    hideTitle,
    children,
  }) => {
    if (hideTitle) {
      return (
        !hideCloseButton && (
          <IconButton
            className={classNames(classes.button, classes.nakedButton)}
            onClick={onClose}
          >
            <Icon>close</Icon>
          </IconButton>
        )
      )
    }

    return (
      <div className={classNames(classes.root, className)} style={style}>
        {children && (
          <div
            className={classNames(classes.content, contentClassName)}
            style={contentStyles}
          >
            {children}
          </div>
        )}
        {!hideCloseButton && (
          <IconButton className={classes.button} onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        )}
      </div>
    )
  },
)

PopuHeader.propTypes = {
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  style: PropTypes.object,
}

export default PopuHeader
