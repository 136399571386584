import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'
import Colors from '../../../assets/styles/colors'

const styles = ({ spacing: { unit } }) => {
  const bRad = 0.5 * (unit * 3 - 4) + 'px'
  return {
    container: {
      overflow: 'hidden',
      backgroundColor: Colors.Aqua,
      color: Colors.White,
      borderRadius: bRad,
      padding: `0 ${unit}px`,
      height: unit * 3 - 4,
      minHeight: unit * 3 - 4,
    },
    pale: {
      backgroundColor: Colors.Aqua,
    },
    bright: {
      backgroundColor: Colors.GrassGreen,
    },
    label: {
      display: 'flex',
      alignItems: 'center',
    },
    hot: {
      backgroundColor: Colors.PaleRed,
    },
    alert: {
      backgroundColor: Colors.Yellow,
    },
    dull: {
      backgroundColor: Colors.DarkGrey,
    },
    square: {
      borderRadius: 0,
    },
    plus: {
      verticalAlign: 'middle',
      fontSize: 20,
      marginLeft: -5,
    },
    tl: { borderRadius: `0 0 ${bRad} 0` },
    t: { borderRadius: `0 0 ${bRad} ${bRad}` },
    tr: {},
    r: { borderRadius: `${bRad} 0 0 ${bRad}` },
    br: { borderRadius: `${bRad} 0 0 0` },
    b: { borderRadius: `${bRad} ${bRad} 0 0` },
    bl: { borderRadius: `0 ${bRad} 0 0` },
    l: { borderRadius: `0 ${bRad} ${bRad} 0` },
    c: { borderRadius: bRad },
  }
}

class Pill extends React.Component {
  render() {
    const {
      className,
      style,
      classes,
      label,
      hot,
      alert,
      dull,
      plus,
      bright,
      pale,
      placement,
      square,
    } = this.props

    const Component = label ? (props) => <Typography {...props} /> : 'div'
    const children = label || this.props.children

    if (!children) return null

    return (
      <Component
        style={style}
        className={classNames(
          classes.container,
          placement && classes[placement],
          {
            [classes.pale]: pale,
            [classes.hot]: hot,
            [classes.alert]: alert,
            [classes.dull]: dull,
            [classes.square]: square,
            [classes.label]: label,
            [classes.bright]: bright,
          },
          className,
        )}
      >
        {plus && <Icon classes={{ root: classes.plus }}>add</Icon>}
        {children}
      </Component>
    )
  }
}

Pill.propTypes = {
  pale: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  label: PropTypes.string,
  hot: PropTypes.bool,
  alert: PropTypes.bool,
  plus: PropTypes.bool,
  square: PropTypes.bool,
  placement: PropTypes.oneOf(['tl', 't', 'tr', 'r', 'br', 'b', 'bl', 'l', 'c']),
}

export default withStyles(styles)(Pill)
