import bgSquare from '../../../../shared/assets/img/bg-square.png'
import Colors from '../../../../shared/assets/styles/colors'

export const styles = (theme) => ({
  paper: {
    position: 'absolute',
    maxWidth: 900,
    height: 710,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // padding: theme.spacing.unit * 2,
    left: 0,
    right: 0,
    margin: '30px auto',
  },
  wrapper: {
    padding: 24,
    height: 520,
    overflow: 'auto',
    position: 'relative',
    // backgroundColor: '#eee'
  },
  wrapperIsList: {
    height: '100%',
  },
  wrapperDropzone: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapperBottom: {
    maxHeight: 575,
    overflow: 'auto',
    marginBottom: 10,
  },
  action: {
    float: 'right',
    backgroundColor: 'white',
    padding: '10px 9px',
    width: '100%',
  },
  actionButton: {
    margin: 0,
  },
  cardContent: {
    padding: 14,
  },
  overlay: {
    opacity: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    position: 'absolute',
    top: 0,
    left: 0,
    transition: 'opacity 0.5s',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    position: 'relative',
    backgroundImage: `url("${bgSquare}")`,
    backgroundSize: 18,
  },
  overlayUpload: {
    position: 'absolute',
    top: 0,
    left: 0,
    background: 'black',
    zIndex: 1110,
    width: '100%',
    height: '100%',
    opacity: 0.3,
  },
  cardUpload: {
    width: 195,
    height: 195,
  },
  cardList: {
    width: 122,
    height: 120,
    '&:hover $overlay': {
      opacity: 1,
    },
  },
  media: {
    height: '100%',
  },
  preview: {
    backgroundSize: 'contain',
  },
  previewImage: {
    height: 142,
  },
  previewImageLocalised: {
    height: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  previewDetails: {
    width: '100%',
    height: 240,
    // cursor: 'pointer',
  },
  chip: {
    margin: 2,
  },
  close: {
    cursor: 'pointer',
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 9999,
  },
  dropzone: {
    position: 'relative',
    width: 190,
    height: 195,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: '2px',
    borderColor: 'rgb(102, 102, 102)',
    borderStyle: 'dashed',
    borderRadius: '5px',
  },
  searchField: {
    marginTop: 1,
  },
  smallIcon: {
    fontSize: 20,
  },
  dropzoneContent: {
    // position: 'absolute',
    position: 'relative',
    top: 20,
    padding: 20,
    textAlign: 'center',
  },
  largeIconUpload: {
    fontSize: 60,
    textAlign: 'center',
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  iconSelect: {
    fontSize: theme.spacing.unit * 2.5,
  },
  actionsContent: {
    cursor: 'pointer',
    textAlign: 'center',
  },
  actionsIcons: {
    margin: '0 10px',
    color: 'white',
    '&:first-child': {
      margin: 0,
    },
    '&:last-child': {
      margin: 0,
    },
  },
  tagsContent: {
    float: 'left',
    width: '100%',
    overflow: 'auto',
    maxHeight: 450,
    paddingBottom: 20,
    marginBottom: 10,
  },
  fullImage: {
    width: '100%',
  },
  parentWrapper: {
    position: 'relative',
    minHeight: 710,
  },
  mediaDescription: {
    height: 680,
    minHeight: 350,
    overflow: 'auto',
    paddingBottom: 45,
    position: 'relative',
    padding: theme.spacing.unit,
    marginTop: 30,
  },
  mediaPreview: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mediaAnchor: {
    display: 'block',
    height: '100%',
    width: '100%',
    textAlign: 'center',
  },
  backAction: {
    position: 'absolute',
    color: 'white',
    zIndex: 9999,
    top: 10,
    left: 10,
  },
  actionsCurrentFile: {
    position: 'absolute',
    width: '100%',
    display: 'grid',
    bottom: 0,
    left: 0,
  },
  rootActionsCurrentFile: {
    height: 40,
  },
  center: {
    textAlign: 'center',
  },
  uploadAllButton: {
    margin: '16px 0',
    height: 63,
    display: 'flex',
  },
  filterWrapper: {
    marginTop: 0,
    height: 75,
    overflow: 'auto',
  },
  formControl: {
    margin: 10,
    maxWidth: 280,
  },
  formControlText: {
    // marginTop: 11
  },
  selectEmpty: {
    paddingBottom: 1,
  },
  selectMenu: {
    whiteSpace: 'normal',
  },
  multipleMenu: {
    whiteSpace: 'normal',
  },
  previewIcon: {
    fontSize: 60,
    color: 'black',
    position: 'absolute',
    top: 300,
  },
  imageWrapper: {
    marginLeft: 20,
    position: 'relative',
    height: '100%',
    minHeight: 110,
  },
  imgPreview: {
    width: 230,
    height: 130,
  },
  imageChange: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    padding: theme.spacing.unit / 2,
    backgroundColor: Colors.White,
    margin: 5,
    zIndex: 2,
    '&:hover': {
      backgroundColor: Colors.White,
    },
  },
  imageRemove: {
    bottom: theme.spacing.unit * 4.5,
  },
  tagContainer: {
    position: 'absolute',
    bottom: 5,
    left: 24,
    right: 24,
    textAlign: 'center',
  },
  tagsOnUploadtextField: {
    width: 180,
  },
  labelInfo: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    background: Colors.White040,
    zIndex: 1,
  },
  menuMultiChip: {
    '&.fullWidth': {
      width: '100%',
      maxWidth: '100%',
    },
    maxWidth: '75%',
    minWidth: '30%',
    '& .react-select__menu': {
      zIndex: 9999,
    },
  },
  imageCustomMediaUser: {
    height: 'inherit',
  },
  displayFlex: {
    display: 'flex',
    justifyContent: 'center',
  },
  label: {
    display: 'flex',
  },
  actionLabelWithIcon: {
    marginLeft: theme.spacing.unit / 2,
  },
  buttonRoot: {
    minWidth: theme.spacing.unit * 4.2,
    minHeight: theme.spacing.unit * 4.2,
    borderRadius: '50%',
    backgroundColor: Colors.White060,
  },
  multilangImage: {
    bottom: theme.spacing.unit * 9,
  },
  tooltip: {
    fontSize: '0.85rem',
  },
  multilangSwitch: {
    marginTop: -(theme.spacing.unit / 2),
  },
  localiseMedia: {
    marginTop: theme.spacing.unit * 5,
    justifyContent: 'start',
    display: 'flex',
    flexWrap: 'wrap',
  },
  localisedMediaPreview: {
    marginTop: '20px',
    width: 122 * 2,
    height: 120 * 2,
    '&:hover $overlay': {
      opacity: 1,
    },
  },
})
