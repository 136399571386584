import cloneDeep from 'lodash/cloneDeep'
import set from 'lodash/set'
import React, { useState } from 'react'

import StructuredJsonAccordion from './StructuredJsonAccordion'
import StructuredJsonTabs from './StructuredJsonTabs'
import { getDefaultJsonValue, getJsonPath, getJsonValue } from './jsonFieldHelpers'

export default function StructuredJsonBlock(props) {
  const { jsonData, jsonPath, name, schema, max, onUpdate } = props

  const blocks = jsonPath ? getJsonValue(jsonPath, jsonData) : jsonData
  const isTabs = !!jsonPath
  const StructuredJsonComponent = isTabs ? StructuredJsonTabs : StructuredJsonAccordion

  const [selected, setSelected] = useState(jsonPath ? name + '0' : '')

  const handleSelection = (id) => {
    if (!isTabs && selected === id) setSelected('')
    else setSelected(id)
  }

  const handleDataChange = (newData) => {
    if (JSON.stringify(jsonData) !== JSON.stringify(newData)) {
      onUpdate && onUpdate(newData)
    }
  }

  const handleAddItem = () => {
    const newData = cloneDeep(jsonData)
    const targetPath = getJsonPath(jsonPath, blocks.length)
    const defaultValue = getDefaultJsonValue(schema)
    set(newData, targetPath, defaultValue)
    handleDataChange(newData)
  }

  const handleDeleteItem = (index) => {
    const newData = cloneDeep(jsonData)
    if (jsonPath) {
      const newValue = getJsonValue(jsonPath, newData)
      newValue.splice(index, 1)
      set(newData, jsonPath, newValue)
    } else {
      newData.splice(index, 1)
    }
    handleDataChange(newData)
  }

  return (
    <StructuredJsonComponent
      selected={selected}
      name={name}
      blocks={blocks}
      max={max}
      schema={schema}
      jsonData={jsonData}
      jsonPath={jsonPath}
      onAddItem={handleAddItem}
      onDeleteItem={handleDeleteItem}
      onSelect={handleSelection}
      onUpdate={onUpdate}
    />
  )
}
