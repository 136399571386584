import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Chip from '@material-ui/core/Chip'
import FormHelperText from '@material-ui/core/FormHelperText'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  select: {
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: 34,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: `0 2px 2px`,
  },
})

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

class MultipleSelect extends React.Component {
  handleDelete = (item) => () => {
    let { value, disabled } = this.props
    if (disabled) {
      return
    }
    value = [...value]
    value.splice(value.indexOf(item), 1)

    this.props.onChange.apply(this, [
      {
        target: {
          name: this.props.name,
          value: value,
        },
      },
    ])
  }

  render() {
    let {
      inputProps,
      classes,
      theme,
      helperText,
      value,
      options,
      ...props
    } = this.props

    if (!value) value = []
    if (!Array.isArray(value)) value = [value]

    return (
      <FormControl className={classes.formControl} fullWidth {...props}>
        {this.props.label && (
          <InputLabel htmlFor="select-multiple-chip" shrink>
            {this.props.label}
          </InputLabel>
        )}
        <Select
          classes={{ select: classes.select }}
          multiple
          value={value}
          input={<Input id="select-multiple-chip" />}
          {...props}
          renderValue={(selected) => {
            if (!Array.isArray(selected)) selected = [selected]
            return (
              <div className={classes.chips}>
                {selected.map((value) => {
                  let option = options.find((o) => o.value === value)
                  return (
                    <Chip
                      key={value}
                      label={option ? option.label : value}
                      className={classes.chip}
                      onDelete={this.handleDelete(value)}
                    />
                  )
                })}
              </div>
            )
          }}
          MenuProps={MenuProps}
        >
          {options.map((name, k) => (
            <MenuItem
              key={k}
              value={name.value}
              style={{
                fontWeight:
                  value.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
              }}
            >
              {name.label}
            </MenuItem>
          ))}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    )
  }
}

MultipleSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(MultipleSelect)
