import React from 'react'
import Popup from '../organisms/Popup/index'
import PopupBox from './PopupBox'
import PopupContent from './PopupContent'
import PopupHeader from './PopupHeader'

const PopupContext = React.createContext(false)
const { Consumer: PopupConsumer } = PopupContext

const withPopup = (Component) => (props) => (
  <PopupConsumer>
    {(popup) => <Component {...props} popup={popup} />}
  </PopupConsumer>
)

export { withPopup }

export default ({
  wrap,
  onClose,
  children,
  isOpen,
  preventCickOutside,
  componentName,
  maxWidth,
  maxHeight,
}) => {
  return wrap ? (
    <Popup data-popup-id={componentName} onClose={onClose} open={isOpen}>
      <PopupBox onClose={onClose} maxWidth={maxWidth} maxHeight={maxHeight}>
        <PopupHeader />
        <PopupContent>
          <PopupContext.Provider value={true}>{children}</PopupContext.Provider>
        </PopupContent>
      </PopupBox>
    </Popup>
  ) : (
    <Popup
      data-popup-id={componentName}
      disableBackdropClick={preventCickOutside}
      disableEscapeKeyDown={preventCickOutside}
      onClose={onClose}
      open={isOpen}
    >
      <PopupContext.Provider value={true}>{children}</PopupContext.Provider>
    </Popup>
  )
}
