import axios from 'axios'
import queryString from 'query-string'

axios.defaults.withCredentials = true

export default class ApiConnector {
  alertSubscriptions = []
  alertListeners = []
  inputErrorSubscriptions = []
  inputErrorListeners = []
  onNavigateTo

  constructor(baseUrl) {
    this.baseUrl = baseUrl
  }

  listenToAlerts = (listener) => {
    const sub = {
      unsubscribe: () => {
        const i = this.alertSubscriptions.indexOf(sub)
        if (i >= 0) {
          this.alertSubscriptions.splice(i, 1)
          this.alertListeners.splice(i, 1)
        }
      },
    }

    this.alertSubscriptions.push(sub)
    this.alertListeners.push(listener)
    return sub
  }

  listenToInputErrors = (listener) => {
    const subscription = {
      unsubscribe: () => {
        const i = this.inputErrorSubscriptions.indexOf(subscription)
        if (i >= 0) {
          this.inputErrorSubscriptions.splice(i, 1)
          this.inputErrorListeners.splice(i, 1)
        }
      },
    }

    this.inputErrorSubscriptions.push(subscription)
    this.inputErrorListeners.push(listener)
    return subscription
  }

  makeRequest(url, method, config = {}, data) {
    const source = axios.CancelToken.source()
    config = { ...config, cancelToken: source.token }

    let request

    if (data !== undefined) {
      if (config && config.newTab) {
        window.open(
          `${this.baseUrl}${url}?${queryString.stringify(data)}`,
          '_blank',
        )
        return
      }

      if (method === 'post' && window.captchaByAction)
        request = window.captchaByAction('default')

      if (request)
        request = request.then((captchaToken) => {
          if (data instanceof FormData) data.append('captchaToken', captchaToken)
          else data = { ...data, captchaToken }

          if (!config.headers) config.headers = {}
          config.headers['x-captcha-token'] = captchaToken
          // console.log('request', captchaToken)

          return axios[method](this.baseUrl + url, data, config)
        })
      else request = axios[method](this.baseUrl + url, data, config)
    } else {
      request = axios[method](this.baseUrl + url, config)
    }

    request = request
      .then((response) => response.data)
      .then((data) => {
        const { navigateTo, alert } = data

        if (navigateTo) {
          this.onNavigateTo && this.onNavigateTo(navigateTo)
        }

        if (alert) {
          this.dispatchAlert(alert)
        }

        return data
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          // since this request is cancelled
          // we don't want it to resolve/reject anymore
          return new Promise(() => {})
        }

        const { response: { data: { navigateTo, alert, error: { inputError } } = {} } = {} } = error

        if (navigateTo) {
          this.onNavigateTo && this.onNavigateTo(navigateTo)
        }

        if (alert) {
          this.dispatchAlert(alert)
        }

        if (inputError) {
          // console.log(inputError)
          this.dispatchInputError(inputError)
        }

        throw error
      })

    return {
      request,
      cancel: () => {
        source.cancel('cancelled')
      },
    }
  }

  dispatchAlert = (alert) => {
    this.alertListeners.forEach((listener) => listener(alert))
  }

  dispatchInputError = (error) => {
    this.inputErrorListeners.forEach((listener) => listener(error))
  }

  get = (url, config, data) => {
    return this.makeRequest(url, 'get', config, data)
  }

  delete = (url, config) => {
    return this.makeRequest(url, 'delete', config)
  }

  post = (url, data, config) => {
    return this.makeRequest(url, 'post', config, data)
  }

  put = (url, data, config) => {
    return this.makeRequest(url, 'put', config, data)
  }

  patch = (url, data, config) => {
    return this.makeRequest(url, 'patch', config, data)
  }
}
