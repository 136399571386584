import React from 'react'
import { EditorContent, useEditor } from '@tiptap/react'
import extensions from 'jticxm-tiptap-extensions'

import EditorMenu from './EditorMenu'
import './editorStyles.css'

export default function EditorWrapper(props) {
  const { /*brand,*/ content, editable, toolbar, onUpdate } = props
  const editor = useEditor({
    content,
    editable,
    extensions,
    onUpdate: ({ editor }) => {
      const json = editor.getJSON()
      onUpdate && onUpdate(json)
    },
  })

  if (!editor) return null

  return (
    <div>
      <EditorMenu editor={editor} settings={toolbar} />
      <EditorContent editor={editor} />
    </div>
  )
}
