import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import { Typography } from '@material-ui/core'

const styles = (theme) => ({
  root: {},
  selectedOption: {
    fontWeight: 'bold',
  },
  nonSelectedOption: {
    fontWeight: 'lighter',
  },
  label: { marginLeft: 0 },
})

class TrueFalseSwitch extends Component {
  handleChange = (e) => {
    const { onChange, name } = this.props
    onChange && onChange({ target: { name, value: e.target.checked } })
  }

  render() {
    const { classes, helperText, disabled, value, name, label } = this.props
    return (
      <FormControl className={classes.root}>
        <FormControlLabel
          className={classes.label}
          disabled={disabled}
          control={
            <React.Fragment>
              <Typography
                className={
                  value ? classes.nonSelectedOption : classes.selectedOption
                }
              >
                False
              </Typography>
              <Switch
                checked={value}
                value={Boolean(value).toString()}
                onChange={this.handleChange}
                name={name}
              />
              <Typography
                className={
                  value ? classes.selectedOption : classes.nonSelectedOption
                }
              >
                True
              </Typography>
            </React.Fragment>
          }
          label={label}
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    )
  }
}

export default withStyles(styles)(TrueFalseSwitch)
