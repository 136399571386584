"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extensions = void 0;
const extension_bold_1 = require("@tiptap/extension-bold");
const extension_bullet_list_1 = require("@tiptap/extension-bullet-list");
const extension_document_1 = require("@tiptap/extension-document");
const extension_dropcursor_1 = require("@tiptap/extension-dropcursor");
const extension_gapcursor_1 = require("@tiptap/extension-gapcursor");
const extension_hard_break_1 = require("@tiptap/extension-hard-break");
const extension_heading_1 = require("@tiptap/extension-heading");
const extension_history_1 = require("@tiptap/extension-history");
const extension_italic_1 = require("@tiptap/extension-italic");
const extension_link_1 = require("@tiptap/extension-link");
const extension_list_item_1 = require("@tiptap/extension-list-item");
const extension_ordered_list_1 = require("@tiptap/extension-ordered-list");
const extension_paragraph_1 = require("@tiptap/extension-paragraph");
const extension_table_1 = require("@tiptap/extension-table");
const extension_table_cell_1 = require("@tiptap/extension-table-cell");
const extension_table_header_1 = require("@tiptap/extension-table-header");
const extension_table_row_1 = require("@tiptap/extension-table-row");
const extension_text_1 = require("@tiptap/extension-text");
const extension_text_align_1 = require("@tiptap/extension-text-align");
const extension_superscript_1 = require("@tiptap/extension-superscript");
const small_1 = require("./small");
exports.extensions = [
    extension_document_1.default,
    extension_text_1.default,
    extension_dropcursor_1.default,
    extension_gapcursor_1.default,
    extension_table_1.default,
    extension_table_row_1.default,
    extension_table_header_1.default,
    extension_table_cell_1.default,
    extension_heading_1.default,
    extension_paragraph_1.default,
    extension_text_align_1.default.configure({
        alignments: ['left', 'center', 'right'],
        types: ['heading', 'paragraph'],
    }),
    extension_ordered_list_1.default,
    extension_bullet_list_1.default,
    extension_link_1.default.configure({
        HTMLAttributes: {
            target: null,
            class: 'richtext-link',
        },
        openOnClick: false,
        protocols: ['mailto'],
    }),
    extension_list_item_1.default,
    extension_hard_break_1.default,
    extension_bold_1.default,
    extension_italic_1.default,
    extension_history_1.default,
    small_1.Small,
    extension_superscript_1.default,
];
