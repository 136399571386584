"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Small = void 0;
const core_1 = require("@tiptap/core");
exports.Small = core_1.Mark.create({
    name: 'small',
    addOptions() {
        return {
            HTMLAttributes: {},
        };
    },
    parseHTML() {
        return [
            {
                tag: 'small',
            },
            {
                style: 'font-size=smaller',
            },
        ];
    },
    renderHTML({ HTMLAttributes }) {
        return ['small', (0, core_1.mergeAttributes)(this.options.HTMLAttributes, HTMLAttributes), 0];
    },
    addCommands() {
        return {
            setSmall: () => ({ commands }) => {
                return commands.setMark(this.name);
            },
            toggleSmall: () => ({ commands }) => {
                return commands.toggleMark(this.name);
            },
            unsetSmall: () => ({ commands }) => {
                return commands.unsetMark(this.name);
            },
        };
    },
    addKeyboardShortcuts() {
        return {
            'Mod-Shift-s': () => this.editor.commands.toggleSmall(),
        };
    },
});
