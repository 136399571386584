const getSanitizedMediaPath = (media) => {
  if (Array.isArray(media)) {
    if (media.length < 1) {
      return undefined
    }

    media = media[0]
  }

  if (!media && !(typeof media === 'object')) {
    return undefined
  }

  const { path = '', mimetype = '' } = media
  const ext = mimetype.replace(/^([a-z-]+)\/([a-z-]+)$/gi, '$2')
  return path.replace(/^(.+?)\.([a-z]{1,6})$/gi, '$1.' + ext)
}

export default getSanitizedMediaPath
