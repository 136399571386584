import React from 'react'
import Icon from '@material-ui/core/Icon'
import EditorMenuSelect from './EditorMenuSelect'

export const options = ['left', 'center', 'right']

export default function EditorMenuTextAlign({ editor }) {
  const getCurrentTextAlign = () => {
    for (const index in options) {
      if (editor.isActive({ textAlign: options[index] })) return index
    }
    return 0 // default is "left"
  }

  const AlignmentIcon = ({ type }) => (
    <Icon fontSize="small" style={{ position: 'relative', top: 1, pointerEvents: 'none' }}>{`format_align_${type}`}</Icon>
  )

  return (
    <EditorMenuSelect
      items={options.map((item) => (
        <AlignmentIcon key={item} type={item} />
      ))}
      value={getCurrentTextAlign()}
      onChange={(e) => {
        const index = Number(e.target.value)
        editor.chain().focus().setTextAlign(options[index]).run()
      }}
    />
  )
}
