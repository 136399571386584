import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import error400 from '../../assets/img/error/error400.svg'
import error401 from '../../assets/img/error/error401.svg'
import error403 from '../../assets/img/error/error403.svg'
import error404 from '../../assets/img/error/error404.svg'
import error500 from '../../assets/img/error/error500.svg'
import maintenance from '../../assets/img/error/maintenance.svg'
import Colors from '../../assets/styles/colors'

const styles = ({ breakpoints, spacing: { unit } }) => ({
  image: {
    marginTop: unit * 5.4,
    marginBottom: unit * 5.4,
    textAlign: 'right',
    [breakpoints.down('sm')]: {
      textAlign: 'center',
    },
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
  message: {
    fontSize: '18px',
    fontFamily: 'Roboto',
    paddingRight: '10%',
    fontWeight: 'normal',
    lineHeight: '1.8',
    letterSpacing: '0.52px',
    [breakpoints.down('xs')]: {
      paddingLeft: '10%',
      marginTop: '10%',
      fontSize: '1rem',
    },
  },
  header: {
    color: Colors.White,
    marginTop: '32px',
    padding: '30px 0px 53px 32px',
    textAlign: 'left',
    [breakpoints.down('xs')]: {
      fontSize: '2rem',
    },
  },
  button: {
    marginTop: '5%',
  },
  titleContainer: {
    background: Colors.spectrum2,
  },
})

const createProps = (code) => {
  let image = ''
  let defaultMessage = ''
  let defaultTitle = ''

  // console.log('ErrorPage.js -> createProps -> ', code)

  switch (code) {
    case 400:
      image = error400
      defaultTitle = 'BAD REQUEST'
      defaultMessage = `
        <p>The server cannot process the request because of irregularities in it.<p>
        <p>Reasons for this could be:</p>
        <ul>
          <li>Malformed request syntax</li>
          <li>Invalid request message framing</li>
        </ul>
        <p>We recommend that you return to the home page.</p>
      `
      break
    case 401:
      image = error401
      defaultTitle = 'UNAUTHORIZED'
      defaultMessage = `
        <p>The request has not been applied because it lacks valid authentication credentials for the target resource.<p>
        <p>Reasons for this could be:</p>
        <ul>
          <li>You are not logged in</li>
          <li>Your user does not have authorization to make this request</li>
        </ul>
        <p>We recommend that you return to the home page.</p>
      `
      break
    case 403:
      image = error403
      defaultTitle = 'FORBIDDEN'
      defaultMessage = `
        <p>The server understood the request but refuses to authorize it.<p>
        <p>Reasons for this could be:</p>
        <ul>
          <li>The access is forbidden</li>
          <li>Your user has insufficient rights</li>
        </ul>
        <p>We recommend that you return to the home page.</p>
      `
      break
    case 404:
      image = error404
      defaultTitle = 'PAGE NOT FOUND'
      defaultMessage = `
        <p>The server can't find the requested resource.<p>
        <p>Reasons for this could be:</p>
        <ul>
          <li>The URL contains a typo</li>
          <li>The URL or link is obsolete</li>
        </ul>
        <p>We recommend that you return to the home page.</p>
      `
      break
    default:
      const isMaintenance = code === undefined || code === 503
      image = isMaintenance ? maintenance : error500
      defaultTitle = isMaintenance ? 'MAINTENANCE' : 'SERVER ERROR'
      defaultMessage = isMaintenance
        ? '<p>This site is currently down for maintenance. We expect to be back in a couple of hours. Thanks for your patience.<p>'
        : `
          <p>The server encountered an unexpected condition that prevented it from fulfilling the request.<p>
          <p>Reasons for this could be:</p>
          <ul>
            <li>An internal error occurred</li>
          </ul>
          `
      break
  }

  return { image, defaultMessage, defaultTitle }
}

const ErrorPageObj = ({ status, title, message, classes }) => {
  const { image, defaultTitle, defaultMessage } = createProps(status)

  return (
    <div>
      <Grid
        container
        spacing={8}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} className={classes.titleContainer}>
          <Typography variant="h2" align="center" className={classes.header}>
            {title || defaultTitle}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.image}>
          <img src={image} alt="error" />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.message}>
          <Typography variant="subtitle1">
            <div
              dangerouslySetInnerHTML={{ __html: message || defaultMessage }}
            />
          </Typography>
          {status && status < 500 && (
            <Button
              component={'a'}
              href={'/'}
              color={'secondary'}
              variant="outlined"
              className={classes.button}
            >
              HOME PAGE
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  )
}

const ErrorPage = withStyles(styles)(ErrorPageObj)
export { ErrorPage }
