import React from 'react'
import { Link } from 'react-router-dom'
import ButtonBase from '@material-ui/core/ButtonBase'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import Colors from '../../../shared/assets/styles/colors'

const styles = ({ breakpoints, spacing: { unit } }) => ({
  button: {
    paddingLeft: unit - 2,
    paddingRight: unit - 2,
    cursor: 'pointer',
    borderRadius: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: 'red',
  },
  xsOptional: {
    [breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  smOptional: {
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  content: {
    [breakpoints.up('md')]: {
      marginTop: unit * 0.5,
    },
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },
  caption: {
    textAlign: 'center',
    marginBottom: unit * 0.5,
  },
})

const HeaderItemTemplate = withStyles(styles)(
  ({
    className,
    label,
    classes,
    data,
    icon,
    xsIconVisible,
    onClick,
    to,
    children,
  }) => (
    <ButtonBase
      TouchRippleProps={{ style: { color: Colors.CoolGrey } }}
      onClick={onClick}
      component={to ? Link : undefined}
      to={to}
      className={`${className || ''} ${classes.button} ${
        !xsIconVisible ? classes.xsOptional : ''
      }`}
    >
      <div className={classes.content}>{children}</div>
      <Typography
        variant={'caption'}
        className={`${classes.caption} ${classes.smOptional}`}
      >
        {label}
      </Typography>
    </ButtonBase>
  ),
)

export default HeaderItemTemplate
