import React from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import classNames from 'classnames'
import TagSelection from './TagSelection' 
import LocaliseMedia from './LocaliseMedia'
// import CustomSwitchField from '../CustomSwitchField'

class PreviewMedia extends React.Component {
  handleDelete(key) {
    this.props.onHandleDeleteChip(key)
  }

  isImage = (type) => /image/.test(type)
  isPdf = (type) => /pdf/.test(type)
  isVideo = (type) => /video/.test(type)

  render() {
    const {
      classes,
      currentFile,
      onHandleAddChip,
      onClickHandle,
      useThisMedia,
      parsedTags = [],
      onHandleCreate,
      // onAutoplayChange,
      isList,
      localiseFile,
      handleOnDrop,
      removePreviewFile,
      previewFiles,
      contentLocalisedType,
      removeLocalisedFile,
      imageMaxSize,
    } = this.props

    const contentType = (file) => {
      switch (true) {
        case this.isImage(file.type):
          return <img style={{ maxWidth: '100%', maxHeight: '630px' }} src={file.path} alt="" />
        case this.isPdf(file.type):
          return (
            <a href={file.path} target="_blank" rel="noopener noreferrer" className={classes.mediaAnchor}>
              <Icon className={classes.previewIcon}>picture_as_pdf</Icon>
            </a>
          )
        case this.isVideo(file.type):
          return (
            <video width="100%" controls>
              <source src={file.path + '?opt'} type={file.type} />
              Your browser does not support HTML5 video.
            </video>
          )
        default:
          return 'format unrecoganized'
      }
    }

    const defaultValue = currentFile.tags.map((tag) => {
      return { value: tag, label: tag }
    })

    return (
      <React.Fragment>
        <Grid container className={classes.parentWrapper}>
          <Button
            variant="fab"
            color="primary"
            aria-label="Back"
            className={classes.backAction}
            onClick={onClickHandle}
          >
            <Icon>arrow_back</Icon>
          </Button>
          <Grid item xs={8} className={classNames(classes.mediaPreview, classes.card)}>
            {contentType(currentFile)}
          </Grid>
          <Grid item xs={4} className={classes.mediaDescription}>
            <Typography variant="h6" gutterBottom>
              {currentFile.name}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {currentFile.type}
            </Typography>
            <Typography>
              <TagSelection
                parsedTags={parsedTags}
                defaultValue={defaultValue}
                isFullWidth
                onHandleCreate={onHandleCreate}
                onHandleAddChip={onHandleAddChip}
              />
            </Typography>

            {/* {this.isVideo(currentFile.type) && (
              <CustomSwitchField
                name="autoplay"
                value={currentFile.autoplay || false}
                label="autoplay"
                onChange={onAutoplayChange}
              />
            )} */}
            <div className={classes.localiseMedia}>
              <LocaliseMedia
                classes={classes}
                contentLocalisedType={contentLocalisedType}
                contentType={contentType}
                currentFile={currentFile}
                handleOnDrop={handleOnDrop}
                imageMaxSize={imageMaxSize}
                localiseFile={localiseFile}
                previewFiles={previewFiles}
                removeLocalisedFile={removeLocalisedFile}
                removePreviewFile={removePreviewFile}
              />
            </div>
            <div className={classes.actionsCurrentFile}>
              {!isList && (
                <Button
                  color="default"
                  variant="contained"
                  className={classes.actionsIconsCurrentFile}
                  onClick={useThisMedia.bind(this, currentFile)}
                  classes={{
                    root: classes.rootActionsCurrentFile,
                  }}
                >
                  Use it
                </Button>
              )}
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

export default PreviewMedia
