import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import withWidth from '@material-ui/core/withWidth'
import classNames from 'classnames'
import PageProgress from '../PageProgress'
import Paper from '@material-ui/core/Paper/Paper'
import Colors from '../../assets/styles/colors'

const styles = ({ breakpoints, spacing: { unit } }) => ({
  root: {
    maxWidth: 688,
    maxHeight: 680,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    position: 'relative',
    backgroundColor: Colors.White,
    width: '100%',
    height: '100%',
    [breakpoints.down('xs')]: {
      maxWidth: '100vw',
      maxHeight: '100vh',
    },
  },
  progress: {
    position: 'absolute',
    zIndex: 9999,
    width: '100%',
    opacity: 0.5,
  },
})

export default withWidth()(
  withStyles(styles, { name: 'PopupBox' })(
    class PopupBox extends React.Component {
      state = { options: {} }

      handleCloseClick = (event) => {
        const { onClose } = this.props
        onClose && onClose(event)
      }

      render() {
        const {
          width,
          children: childrenProp,
          classes,
          className,
          isLoading,
          maxWidth,
          maxHeight,
          hideTitle,
        } = this.props

        let style
        if (width !== 'xs') {
          style = {}
          if (typeof maxWidth === 'string' || maxWidth > 0)
            style.maxWidth = maxWidth
          if (typeof maxHeight === 'string' || maxHeight > 0)
            style.maxHeight = maxHeight
        }

        const children = React.Children.map(childrenProp, (child) => {
          if (!child) {
            return null
          }

          let props = { hideTitle }
          if (!child.props.onClose) props.onClose = this.handleCloseClick

          return React.cloneElement(child, props)
        })

        return (
          <Paper
            square
            className={classNames(classes.root, className)}
            style={style}
          >
            <PageProgress visible={isLoading} className={classes.progress} />
            {children}
          </Paper>
        )
      }
    },
  ),
)
