import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import FormHelperText from '@material-ui/core/FormHelperText'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import PropTypes from 'prop-types'
import Icon from '@material-ui/core/Icon/Icon'
import Colors from '../../../shared/assets/styles/colors'

const styles = ({ spacing: { unit } }) => ({
  root: {},
  selectEmpty: {
    opacity: 0.5,
  },
  colorOption: {
    width: '100%',
    height: '100%',
    minHeight: unit + 3,
  },
})

class CustomMenuField extends Component {
  render() {
    const { inputProps, InputProps, classes, error, helperText, ...props } = this.props

    let { value } = props

    value = !props.options.map((op) => op.value?.toString()).includes(value) ? undefined : value

    return (
      <FormControl fullWidth {...props} classes={{ root: classes.root }} error={error}>
        {props.label && <InputLabel shrink>{props.label}</InputLabel>}
        <Select
          classes={{
            root: classes.root,
            select: value === undefined ? classes.selectEmpty : undefined,
          }}
          {...props}
          value={value || ''}
          displayEmpty
        >
          {!value && (
            <MenuItem value={''} disabled>
              Please select
            </MenuItem>
          )}
          {props.options.map((option) => (
            <MenuItem key={option.value} value={option.value?.toString()} disabled={option.disabled}>
              {this._renderOption(option)}
            </MenuItem>
          ))}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    )
  }

  _renderOption = (option) => {
    const { settings: { variant } = {}, classes } = this.props
    const { label } = option

    if (variant === 'icon') {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Icon>{label}</Icon>
          <span style={{ marginLeft: 8 }}>({label})</span>
        </div>
      )
    }
    if (variant === 'colorSpectrum') {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            background: Colors[label],
          }}
          className={classes.colorOption}
        />
      )
    }

    return label
  }
}

CustomMenuField.propTypes = {
  helperText: PropTypes.string,
}

export default withStyles(styles)(CustomMenuField)
