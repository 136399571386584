import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

const styles = ({ spacing: { unit } }) => ({
  bar: {
    marginTop: unit,
    backgroundColor: 'grey',
    width: '100%',
    height: '0.5rem',
  },
  barFill: {
    backgroundColor: 'rgba(255,255,255,0.76)',
    position: 'relative',
    height: '100%',
  },
  caption: {
    marginTop: unit * 0.5,
    width: '100%',
  },
  paper: {
    padding: unit * 2,
    boxSizing: 'border-box',
  },
})

const PasswordStrengthBox = withStyles(styles)(
  class Component extends React.Component {
    render() {
      const {
        classes,
        description,
        className,
        width,
        strength = 0,
        config,
      } = this.props
      const data =
        config[
          Math.max(
            0,
            Math.min(Math.round(config.length * strength), config.length - 1),
          )
        ]

      return (
        <Paper
          square
          className={`${classes.paper} ${className || ''}`}
          style={{ width }}
        >
          <Typography><span dangerouslySetInnerHTML={{ __html: description }} /></Typography>
          <div className={classes.bar} style={{ backgroundColor: data.color }}>
            <div
              className={classes.barFill}
              style={{ marginLeft: `${strength * 100}%` }}
            />
          </div>
          <Typography
            variant="caption"
            align={'left'}
            className={classes.caption}
          >
            {data.caption}
          </Typography>
        </Paper>
      )
    }
  },
)

export default PasswordStrengthBox
