import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import ScrollToBottom from '../molecules/ScrollToBottom/ScrollToBottom'

const styles = ({ breakpoints, spacing: { unit } }) => ({
  root: {
    boxSizing: 'border-box',
    padding: unit + 4,
    [breakpoints.up('sm')]: {
      // padding: unit * 2,
    },
    flex: 1,
  },
})

export default withStyles(styles)(
  ({ classes, className, style, children, onScrolledToBottom }) => {
    return (
      <ScrollToBottom
        className={classNames(classes.root, className)}
        onScrolledToBottom={onScrolledToBottom}
        style={style}
      >
        {children}
      </ScrollToBottom>
    )
  },
)
