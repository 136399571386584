import React from 'react'
import Alpha from 'react-color/lib/components/common/Alpha'
import Checkboard from 'react-color/lib/components/common/Checkboard'
import ColorWrap from 'react-color/lib/components/common/ColorWrap'
import Hue from 'react-color/lib/components/common/Hue'
import Saturation from 'react-color/lib/components/common/Saturation'
import SketchFields from 'react-color/lib/components/sketch/SketchFields'
import SketchPresetColors from 'react-color/lib/components/sketch/SketchPresetColors'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'

const styles = (theme) => ({
  picker: {
    padding: '10px 10px 0',
    boxSizing: 'initial',
    background: '#fff',
    borderRadius: '4px',
  },
  saturation: {
    width: '100%',
    paddingBottom: '75%',
    position: 'relative',
    overflow: 'hidden',
  },
  Saturation: {
    radius: '3px',
  },
  controls: {
    display: 'flex',
  },
  sliders: {
    padding: '4px 0',
    flex: '1',
  },
  color: {
    width: '24px',
    height: '24px',
    position: 'relative',
    marginTop: '4px',
    marginLeft: '4px',
    borderRadius: '3px',
  },
  activeColor: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    borderRadius: '2px',
  },
  hue: {
    position: 'relative',
    height: '10px',
    overflow: 'hidden',
  },
  Hue: {
    radius: '2px',
  },

  alpha: {
    position: 'relative',
    height: '10px',
    marginTop: '4px',
    overflow: 'hidden',
  },
  Alpha: {
    radius: '2px',
  },
})

export const Sketch = ({
  classes,
  width = 200,
  rgb,
  hex,
  hsv,
  hsl,
  onChange,
  onSwatchHover,
  disableAlpha,
  presetColors,
  renderers,
}) => {
  return (
    <Typography component="div" className={classes.picker} style={{ width }}>
      <div className={classes.saturation}>
        <Saturation
          className={classes.Saturation}
          hsl={hsl}
          hsv={hsv}
          onChange={onChange}
        />
      </div>
      <div className={classes.controls}>
        <div className={classes.sliders}>
          <div className={classes.hue}>
            <Hue className={classes.Hue} hsl={hsl} onChange={onChange} />
          </div>
          {!disableAlpha && (
            <div className={classes.alpha}>
              <Alpha
                className={classes.Alpha}
                rgb={rgb}
                hsl={hsl}
                renderers={renderers}
                onChange={onChange}
              />
            </div>
          )}
        </div>
        <div className={classes.color}>
          {!disableAlpha && <Checkboard />}
          <div
            className={classes.activeColor}
            style={{ background: `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})` }}
          />
        </div>
      </div>

      <SketchFields
        rgb={rgb}
        hsl={hsl}
        hex={hex}
        onChange={onChange}
        disableAlpha={disableAlpha}
      />
      <SketchPresetColors
        colors={presetColors}
        onClick={onChange}
        onSwatchHover={onSwatchHover}
      />
    </Typography>
  )
}

export default withStyles(styles)(ColorWrap(Sketch))
