let captchaLoaded = false

const setupCaptcha = (appFrame) => {
  if (captchaLoaded) return

  const {
    settings: { technical: { captchaId, captchaVisibility } = {} } = {},
  } = appFrame || {}

  if (captchaId) {
    captchaLoaded = true
    let script = document.createElement('script')
    script.setAttribute(
      'src',
      `https://www.google.com/recaptcha/api.js?render=${captchaId}`,
    )
    document.getElementsByTagName('head')[0].appendChild(script)

    script.onload = () => {
      window.grecaptcha.ready(async () => {
        hideCaptchaBadge(captchaVisibility)
        window.captchaByAction = async (action) =>
          window.grecaptcha.execute(captchaId, { action })
      })
    }
  }
}

const hideCaptchaBadge = (visibility) => {
  if (visibility) {
    const badge$ = document.querySelector('.grecaptcha-badge')

    if (badge$) badge$.style.visibility = 'hidden'
  }
}

export default setupCaptcha
