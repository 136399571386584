import React, { useContext, useState } from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'
import classNames from 'classnames'
import Dropzone from 'react-dropzone'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardActions from '@material-ui/core/CardActions'
import { LanguageContext } from 'admin/src/LanguageContext.js'
import DropDown from './DropDown'
import Grid from '@material-ui/core/Grid'

const LocaliseMedia = ({
  classes,
  contentLocalisedType,
  contentType,
  currentFile,
  handleOnDrop,
  imageMaxSize,
  localiseFile,
  previewFiles,
  removeLocalisedFile,
  removePreviewFile,
}) => {
  const localiseOptions = [
    { value: 'localisation', label: 'Localise media' },
    ...(currentFile?.type?.split('/')[0] === 'video' ? [{ value: 'poster', label: 'Localise poster' }] : []),
  ]

  const [selectedLanguage, setSelectedLanguage] = useState('')
  const { supportedLanguages } = useContext(LanguageContext)
  const [localiseType, setLocaliseType] = useState('')

  const filteredLanguages = supportedLanguages.map((lang) => {
    return { value: lang.id, label: `${lang.labelShort} ${lang.label}` }
  })

  const localisedMedias = filteredLanguages
    .map((lang) => {
      const language = lang.value
      const localised = currentFile.localisation[language]
      if (localised)
        return { file: { path: currentFile[`path__${language}`], language: language, type: currentFile.type } }
      return null
    })
    .filter((item) => item != null)

  const localisedPosters = filteredLanguages
    .map((lang) => {
      const language = lang.value
      const poster = currentFile.poster[language]
      if (poster)
        return { file: { path: currentFile[`poster__${language}`], language: language, type: `image/${poster.split('.').pop()}` } }
      return null
    })
    .filter((item) => item != null)

  const filteredLocalisedMedias = localisedMedias.filter(({ file }) => file.language === selectedLanguage)
  const filteredLocalisedPosters = localisedPosters.filter(({ file }) => file.language === selectedLanguage)

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value
    setSelectedLanguage(newLanguage)
  }

  const handleLocaliseTypeChange = (event) => {
    setLocaliseType(event.target.value)
  }

  const localisedMediasComponent = filteredLocalisedMedias.map(({ file }) => (
    <Card className={classNames(classes.localisedMediaPreview, classes.card)}>
      <CardMedia className={classNames(classes.media, classes.preview, classes.previewImageLocalised)}>
        {contentType(file)}
      </CardMedia>
      <CardActions className={classes.action}>
        <Button
          color="primary"
          onClick={() => removeLocalisedFile(file.language, 'localisation')}
          className={classes.actionButton}
        >
          Remove
        </Button>
      </CardActions>
    </Card>
  ))

  const localisedPosterComponent = filteredLocalisedPosters.map(({ file }) => (
    <Card className={classNames(classes.localisedMediaPreview, classes.card)}>
      <CardMedia className={classNames(classes.media, classes.preview, classes.previewImageLocalised)}>
        {contentType(file)}
      </CardMedia>
      <CardActions className={classes.action}>
        <Button
          color="primary"
          onClick={() => removeLocalisedFile(file.language, 'poster')}
          className={classes.actionButton}
        >
          Remove
        </Button>
      </CardActions>
    </Card>
  ))

  const hasLocalisedContent = (language, localiseType) => {
    if (localiseType === 'localisation') {
      const localisedMediaExists = currentFile.localisation && currentFile.localisation[language]
      return localisedMediaExists && localisedMediasComponent
    } else if (localiseType === 'poster') {
      const localisedPosterExists = currentFile.poster && currentFile.poster[language]
      return localisedPosterExists && localisedPosterComponent
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <DropDown
            placeholder="Select localise option"
            value={localiseType}
            options={localiseOptions}
            onChange={handleLocaliseTypeChange}
          />
        </Grid>
        <Grid item xs={12} style={{ marginTop: '20px' }}>
          {localiseType && (
            <DropDown
              placeholder={'Select language to localise to'}
              value={selectedLanguage}
              options={filteredLanguages}
              onChange={handleLanguageChange}
            />
          )}
        </Grid>
      </Grid>
      {selectedLanguage &&
        (hasLocalisedContent(selectedLanguage, localiseType) || (
          <Grid container>
            <Grid item xs={12} style={{ marginTop: '20px' }}>
              <Dropzone onDrop={handleOnDrop} maxSize={imageMaxSize} className={classes.dropzone}>
                {({ getRootProps, getInputProps }) => (
                  <div className={classes.dropzoneContent} {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Icon className={classes.largeIconUpload}>cloud_upload</Icon>
                    <Typography variant="subtitle1">Drag and drop your localised media or browse</Typography>
                  </div>
                )}
              </Dropzone>
            </Grid>
            <Grid item xs={12}>
              {previewFiles.map((file, k) => (
                <Card key={k} className={classNames(classes.cardUpload, classes.card)}>
                  <CardMedia
                    src={contentLocalisedType(file, true)}
                    image={contentLocalisedType(file, true)}
                    className={classNames(classes.media, classes.preview, classes.previewImage)}
                    title={file.name}
                  />
                  <CardActions className={classes.action}>
                    <Button
                      color="primary"
                      onClick={() => removePreviewFile(file)}
                      className={classes.actionButton}
                    >
                      REMOVE
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => localiseFile(file, currentFile._id, selectedLanguage, localiseType)}
                      className={classes.actionButton}
                    >
                      UPLOAD
                    </Button>
                  </CardActions>
                </Card>
              ))}
            </Grid>
          </Grid>
        ))}
    </>
  )
}

export default LocaliseMedia
