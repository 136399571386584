import React, { useContext, useEffect, useRef } from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import CardMedia from '@material-ui/core/CardMedia'
import Card from '@material-ui/core/Card'
import Icon from '@material-ui/core/Icon'
import classNames from 'classnames'
import getSanitizedMediaPath from '../../../../shared/utils/getSanitizedMediaPath'
import sizeImagePath from '../../../../shared/utils/sizeImagePath'
import Tooltip from '@material-ui/core/Tooltip'
import { LanguageContext } from 'admin/src/LanguageContext.js'

function ImageSelection(props) {
  const {
    classes,
    value,
    handleOpen,
    disabled,
    handleDeleteImageFromItem,
    clickedOnVideo,
    showControls = true,
    isPlaying,
    customUserMedia,
    customHeight,
  } = props
  const { selectedLanguage } = useContext(LanguageContext)
  const selectedLangCode = selectedLanguage.id

  const videoRef = useRef(null)

  useEffect(() => {
    if (!customUserMedia && !isPlaying && videoRef.current) {
      videoRef.current.load()
    }
  }, [value, customUserMedia, isPlaying])

  const mediaPath = value?.localisation?.[selectedLangCode]
    ? value?.[`path__${selectedLangCode}`]
    : value?.path

  if (mediaPath && !customUserMedia) {
    sizeImagePath(getSanitizedMediaPath(mediaPath), 'sm')
  }

  const isVideo = value && /video/.test(value.type)

  let style
  if (customHeight) style = { height: customHeight }

  return (
    <>
      <Grid container>
        <Grid item xs={12} style={{ position: 'relative', zIndex: 0 }}>
          <Card className={classNames(classes.cardList, classes.card, classes.previewDetails)} style={style}>
            <CardMedia
              image={!isVideo && (mediaPath || '')}
              className={classNames(classes.media, classes.preview, customUserMedia && classes.displayFlex)}
            >
              {!customUserMedia && isVideo && mediaPath && (
                <video
                  height="100%"
                  width="100%"
                  controls
                  onPlaying={clickedOnVideo}
                  onPause={clickedOnVideo}
                  ref={videoRef}
                >
                  <source src={mediaPath} type={value.type} />
                  Your browser does not support HTML5 video.
                </video>
              )}
            </CardMedia>
          </Card>
          {showControls && mediaPath && !isPlaying && (
            <>
              <Tooltip title="Remove Image" placement="right" classes={{ tooltip: classes.tooltip }}>
                <Button
                  variant="outlined"
                  onClick={handleDeleteImageFromItem}
                  className={classNames(classes.imageChange, classes.imageRemove)}
                  disabled={disabled}
                  classes={{ root: classes.buttonRoot }}
                >
                  <Icon className={classes.iconSelect}>clear</Icon>
                </Button>
              </Tooltip>
            </>
          )}
          {showControls && !isPlaying && (
            <>
              <Tooltip title="Search Image" placement="right" classes={{ tooltip: classes.tooltip }}>
                <Button
                  variant="outlined"
                  onClick={handleOpen}
                  className={classes.imageChange}
                  disabled={disabled}
                  classes={{ root: classes.buttonRoot }}
                >
                  <Icon className={classes.iconSelect}>image_search</Icon>
                </Button>
              </Tooltip>

              <div className={classes.labelInfo}>
                <Typography variant="body2" style={{ padding: 12 }}>
                  {value && value._id && value.mimetype
                    ? `${value._id} / ${value.mimetype}`
                    : 'No media selected'}
                </Typography>
              </div>
            </>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default ImageSelection
