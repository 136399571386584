/* eslint-disable react-hooks/exhaustive-deps */
import ChartsEmbeddedSDK from '@mongodb-js/charts-embed-dom'
import React, { useRef, useState, useEffect } from 'react'

import withStyles from '@material-ui/core/styles/withStyles'
import { getToken, loadFiltersFromLocalStorage } from './atlasHelper'
import DashboardFilters from './DashboardFilters'

const styles = () => ({
  atlasDashboardWrapper: {
    height: 'calc(100vh - 140px)',
  },
})

function AtlasDashboardPage({ data, classes, api }) {
  // Renders an Atlas Chart
  const dashboardDiv = useRef(null)
  const [atlasDashboard, setAtlasDashboard] = useState()

  const { baseUrl, dashboard } = data
  const dashboardId = dashboard?.dashboardId
  const sdk = new ChartsEmbeddedSDK({
    baseUrl,
    getUserToken: async () => getToken(api),
    showAttribution: false,
  })

  useEffect(() => {
    ;(async () => {
      let dash = sdk.createDashboard({
        background: 'transparent',
        dashboardId,
        widthMode: 'scale',
        filter: loadFiltersFromLocalStorage(dashboardId) || {},
      })
      try {
        await dash.render(dashboardDiv.current)
        setAtlasDashboard(dash)
      } catch (err) {
        console.error(err)
      }
    })()
  }, [dashboardId])

  return (
    <div className={classes.atlasDashboardWrapper}>
      <DashboardFilters dashboard={atlasDashboard} dashboardId={dashboardId} />
      <div ref={dashboardDiv} style={{ height: '100%' }} />
    </div>
  )
}

export default withStyles(styles)(AtlasDashboardPage)
