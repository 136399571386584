/* eslint-disable react-hooks/exhaustive-deps */
import cloneDeep from 'lodash/cloneDeep'
import set from 'lodash/set'
import React, { useEffect, useState } from 'react'

import CustomMenuField from '../CustomMenuField'
import CustomNumber from '../CustomNumber'
import CustomSwitchField from '../CustomSwitchField'
import CustomTextField from '../CustomTextField'

export default function JsonField(props) {
  const { jsonData, jsonPath, label, value: initialValue, type, onUpdate } = props
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  function handleUpdate(update) {
    const newData = cloneDeep(jsonData)
    setValue(update)
    set(newData, jsonPath, update)
    if (JSON.stringify(jsonData) !== JSON.stringify(newData)) {
      onUpdate && onUpdate(newData)
    }
  }

  function handleChange({ target: { value: update } }) {
    handleUpdate(update)
  }

  let Component
  let InputProps = {}
  let options

  switch (type) {
    case 'number':
      Component = CustomNumber
      InputProps.type = 'number'
      break
    case 'boolean':
      Component = CustomSwitchField
      break
    case 'string':
      Component = CustomTextField
      InputProps.type = 'text'
      break
    case 'enum':
      Component = CustomMenuField
      options = props.type.split('|').map((op) => ({ label: op, value: op.trim() }))
      break
    default:
      // In case the type is unknown nothing will be rendered
      return null
  }

  return (
    <div style={{ marginBottom: 12 }}>
      <Component
        fullWidth
        label={label}
        value={value}
        InputProps={InputProps}
        InputLabelProps={{
          shrink: true,
        }}
        options={options}
        onChange={handleChange}
        onUpdate={handleUpdate}
      />
    </div>
  )
}
