import React from 'react'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import withStyles from "@material-ui/core/styles/withStyles";

const styles = () => ({
  atlasDashboardTitle: {},
  atlasDashboardDescription: {},
  atlasDashboardContainer: {
    width: '100%',
  },
  atlasDashboardWrapper: {
    height: '100%',
    boxSizing: 'border-box',
  },
  atlasDashboardTitleContainer: {
    padding: '2.4rem 1.6rem 1.6rem 1.6rem',
  },
  atlasDashboardIcon: {
    width: '100%',
    maxHeight: '300px',
    aspectRatio: '3 / 2',
    objectFit: 'cover',
  },
  atlasDashboardCopy: { paddingTop: '1rem' },
})

function AtlasOverviewPage(props) {
  const { allDashboards } = props.data
  const { classes } = props

  return (
    <Grid container columns={12} justify="center" spacing={24}>
      {allDashboards?.map(({ dashboardId, name, description, dashboardMedia }) => {
        return (
          <Grid
            className={classes.atlasDashboardContainer}
            item
            component={'a'}
            href={`/atlas-dashboards/${dashboardId}`}
            lg={6}
            sm={6}
            md={6}
          >
            <Paper className={classes.atlasDashboardWrapper}>
              <img
                className={classes.atlasDashboardIcon}
                src={dashboardMedia?.path || '/static/images/default-dashboard.png'}
                alt={name}
              />
              <div className={classes.atlasDashboardTitleContainer}>
                <Typography variant="h4">{name}</Typography>

                <Typography variant="body1" className={classes.atlasDashboardCopy}>
                  {description}
                </Typography>
              </div>
            </Paper>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default withStyles(styles)(AtlasOverviewPage)
