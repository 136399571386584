import React, { Component } from 'react'
import classNames from 'classnames'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import Colors from '../../../assets/styles/colors'
import PageData from '../../../utils/PageData'

class SystemPageTemplate extends Component {
  render() {
    const { classes, children, title, subheading, className } = this.props
    let { settings: { general: { backgroundLoginImage } = {} } = {} } = PageData.appFrame || {}

    const background = backgroundLoginImage?.path
      ? `url(${backgroundLoginImage.path})`
      : Colors.gradientBackground

    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classNames(classes.container, className)}
        justify={'center'}
        style={{ backgroundImage: background }}
      >
        <div className={classes.textContainer}>
          <Typography variant="h2" color="primary" className={classes.text}>
            {title}
          </Typography>
          {subheading ? (
            <Typography className={classNames(classes.subheading, classes.text)}>{subheading}</Typography>
          ) : null}
        </div>

        {children}
      </Grid>
    )
  }
}

const styles = ({ breakpoints, spacing: { unit } }) => ({
  container: {
    paddingTop: unit * 4,
    paddingBottom: unit * 6,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
  },
  title: {
    maxWidth: 640,
    marginBottom: unit * 3,
  },
  text: {
    color: Colors.Black,
  },
  textContainer: {
    width: '100%',
    maxWidth: 640,
    marginBottom: unit * 3,
    textAlign: 'center',
    paddingLeft: unit,
    paddingRight: unit,
    boxSizing: 'border-box',
  },
  subheading: {
    maxWidth: 640,
    marginTop: unit * 0.5,
  },
})

export default withStyles(styles)(SystemPageTemplate)
