import React from 'react'
import EditorMenuSelect from './EditorMenuSelect'

export default function EditorMenuHeading({ editor }) {
  const getCurrentLevel = () => {
    let level = 0
    while (!editor.isActive('heading', { level }) && level <= 6) {
      level++
    }
    return level > 6 ? 0 : level
  }

  return (
    <EditorMenuSelect
      items={['P', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6']}
      value={getCurrentLevel()}
      onChange={(e) => {
        let level = Number(e.target.value)
        if (!level) level = getCurrentLevel()
        editor.chain().focus().toggleHeading({ level }).run()
      }}
    />
  )
}
