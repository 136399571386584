import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import React from 'react'
import JsonField from './JsonField'
import StructuredJsonBlock from './StructuredJsonBlock'
import { getJsonPath } from './jsonFieldHelpers'

export default function StructuredJsonBlockFields(props) {
  const { index, fields, schema, jsonData, jsonPath, onDeleteItem, onUpdate } = props
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {onDeleteItem && (
          <Button
            disableRipple
            onClick={() => onDeleteItem(index)}
            variant="outlined"
            style={{ padding: 8, borderRadius: '50%', minWidth: 0 }}
          >
            <DeleteIcon fontSize="small" />
          </Button>
        )}
      </div>
      {Object.entries(schema).map((item) => {
        const [fieldName, fieldProps] = item
        const fieldsPath = getJsonPath(jsonPath, index, fieldName)
        if (fieldProps?.type === 'array') {
          return (
            <div style={{ marginBottom: 12 }}>
              <StructuredJsonBlock
                jsonData={jsonData}
                jsonPath={fieldsPath}
                schema={fieldProps?.schema || {}}
                max={fieldProps?.max}
                name={fieldName}
                onUpdate={onUpdate}
              />
            </div>
          )
        } else if (!!fieldProps) {
          return (
            <JsonField
              jsonData={jsonData}
              jsonPath={fieldsPath}
              label={fieldName}
              value={fields[fieldName]}
              onUpdate={onUpdate}
              {...fieldProps}
            />
          )
        } else {
          return null
        }
      })}
    </>
  )
}
