export async function getToken(api) {
  // either requests a new valid token or uses existing valid token
  const tokenName = 'mongoAtlasJWT'
  const storedJwtData = window.localStorage.getItem(tokenName)

  if (storedJwtData) {
    try {
      const data = JSON.parse(storedJwtData)
      const { token, requestedAt, ttl } = data
      const validUntil = requestedAt + ttl * 1000 * 60
      if (Date.now() < validUntil) {
        return token
      }
      return fetchNewToken()
    } catch (err) {
      return fetchNewToken()
    }
  }

  return fetchNewToken()

  async function fetchNewToken() {
    /* Gets a new token from CXM and writes it to localStorage*/
    const requestedAt = Date.now()
    const { request: jwtRequest } = api.getMongoAtlasJWT()
    const { token, ttl } = await jwtRequest
    window.localStorage.setItem(tokenName, JSON.stringify({ token, requestedAt, ttl }))
    return token
  }
}

export function loadFiltersFromLocalStorage(dashboardId) {
  const raw = window.localStorage.getItem(`dashboard-filters:${dashboardId}`)
  if (raw) {
    try {
      const parsed = JSON.parse(raw)
      if (parsed.date) {
        if (parsed.date.$gte) parsed.date.$gte = new Date(parsed.date.$gte)
        if (parsed.date.$lt) parsed.date.$lt = new Date(parsed.date.$lt)
      }
      return parsed
    } finally {
    }
  }
  return null
}
export function storeFiltersToLocalStorage(dashboardId, filters) {
  window.localStorage.setItem(`dashboard-filters:${dashboardId}`, JSON.stringify(filters))
}
