import React from 'react'
import EnhancedTable from '../../molecules/EnhancedTable'
import {
  findAction,
  processActionRequest,
} from '../ModelViewWrapper/ModelViewWrapper'
import ACTION_NAMES from '../ModelViewWrapper/actionNames'
import { withLanguage } from '../../../LanguageContext'
import debounce from 'lodash/debounce'
import ModelNote from '../../molecules/ModelNote'
import {
  encodeFilters,
  getEncodedFilterFromQuery,
  getUpdatedQuery,
  decodeFilters,
} from './searchHelper'
import queryString from 'query-string'

const nonToolbarActions = [
  ACTION_NAMES.PATCH,
  ACTION_NAMES.DUPLICATE,
  ACTION_NAMES.DELETE_SINGLE,
  ACTION_NAMES.OPEN_DETAIL,
]

const isSelectionAction = ({ action }) =>
  action.toLowerCase().indexOf(ACTION_NAMES.SUFFIX_SELECTED) !== -1

class ModelList extends React.Component {
  constructor(props) {
    super(props)

    let {
      actions = [],
      columnData,
      rowData,
      numRows,
      page,
      rowsPerPage,
    } = props

    const openDetailAction = findAction(actions, ACTION_NAMES.OPEN_DETAIL)
    let detailUrl = openDetailAction ? openDetailAction.url : undefined

    const patchAction = findAction(actions, ACTION_NAMES.PATCH)
    const inlineEditableColumns = patchAction && (patchAction.columns || 'all')

    const encodedSearchFilters = getEncodedFilterFromQuery()
    const searchFilters = decodeFilters(encodedSearchFilters)

    this.state = {
      selected: [],
      actionsFiltered:
        actions &&
        actions
          .filter(({ action }) => !nonToolbarActions.includes(action))
          .map((action) => ({
            ...action,
            selectable: isSelectionAction(action),
          })),
      isRowDeletable: Boolean(findAction(actions, ACTION_NAMES.DELETE_SINGLE)),
      isRowDuplicatable: Boolean(findAction(actions, ACTION_NAMES.DUPLICATE)),
      isRowSelectable:
        actions && Boolean(actions.find((action) => isSelectionAction(action))),
      isRowDetailLink: Boolean(openDetailAction),
      inlineEditableColumns,
      columnData,
      rowData,
      numRows,
      searchFilters,
      encodedSearchFilters,
      page,
      rowsPerPage,
      detailUrl,
      disabledActions: this._updateSaveFiltersActionState(encodedSearchFilters),
    }
  }

  _updateSaveFiltersActionState = (encodedSearchFilters) => {
    const { savedFilters } = this.props
    return {
      [ACTION_NAMES.SAVE_FILTERS]:
        !encodedSearchFilters ||
        !!(
          savedFilters &&
          savedFilters.find(
            ({ filterString }) => filterString === encodedSearchFilters,
          )
        ),
    }
  }

  fetchRows = async (data) => {
    let { searchFilters, disabledActions } = this.state

    const params = queryString.parse(window.location.search)
    let state = {}

    if (data) {
      const { page, rowsPerPage, ...rest } = data
      data = { ...searchFilters, ...rest }

      Object.keys(data).forEach((key) => {
        if (data[key] === undefined) delete data[key]
      })

      if (page !== undefined) state.page = params.p = page
      if (rowsPerPage !== undefined) state.rowsPerPage = params.r = rowsPerPage
    } else data = {}

    let encodedSearchFilters = null

    if (Object.keys(data).length > 0) {
      encodedSearchFilters = encodeFilters(data)
      params.s = encodeFilters(data)
    } else {
      delete params.s
    }

    let query = queryString.stringify(params)
    window.history.replaceState(
      null,
      '',
      `${window.location.pathname}${query ? `?${query}` : ''}`,
    )

    this.setState({
      ...state,
      encodedSearchFilters,
      searchFilters: data,
      isLoading: true,
      disabledActions: {
        ...disabledActions,
        ...this._updateSaveFiltersActionState(encodedSearchFilters),
      },
    })

    this._requestRows(query)
  }

  handleInlineChange = async (e) => {
    const { target, ref } = e
    const { row, col } = ref.props
    const { api, actions, selectedLanguage } = this.props

    let { name } = col
    const { settings: { multilang } = {} } = col
    if (multilang) name = `${name}__${selectedLanguage.id}`

    let initialValue = name in row ? row[name] : col.defaultValue
    if (target.value === initialValue) return

    const patchAction = findAction(actions, ACTION_NAMES.PATCH)

    let apiPath
    let actionUrl
    let actionMethod = 'PATCH'
    if (patchAction) {
      actionUrl = patchAction.url
      actionMethod = patchAction.method
    } else return

    if (!actionUrl) return

    apiPath = actionUrl.replace(/\[id\]/gi, row._id)

    let result

    try {
      const { request } = api.updateModelEntry(
        apiPath,
        {
          [col.name]: target.value,
        },
        actionMethod,
        selectedLanguage.id,
      )

      ;({ result } = await request)
    } catch (e) {
      return
    }

    if (!result) return

    this.updateRow(col, row, { name: name, value: result.rawData[name] })
  }

  updateRow = (col, row, update) => {
    let { rowData } = this.state
    const index = rowData.findIndex((r) => r._id === row._id)

    rowData = [...rowData]
    let data = { ...rowData[index] }

    let { name, value } = update
    rowData[index] = { ...data, [name]: value }

    this.setState({ rowData })
  }

  handleActionRequest = async (action) => {
    const { api } = this.props
    const actionResult = await processActionRequest(action, api)
    if (actionResult === true) return

    let { selected } = this.state
    let { action: actionName, url, confirm, method = 'POST' } = action

    switch (actionName) {
      case ACTION_NAMES.CREATE: {
        return api.createModelEntry(url)
      }
      case ACTION_NAMES.CREATE_FROM_FILE: {
        if (this.fileUploadInput) {
          this.handleUpload = this._handleFileUpload
          this.fileUploadInput.click()

          return new Promise((resolve) => {
            this._uploadResolver = resolve
          })
        }
        break
      }
      case ACTION_NAMES.DOWNLOAD_CSV: {
        const { encodedSearchFilters } = this.state
        if (encodedSearchFilters)
          url = url + '&' + getUpdatedQuery(encodedSearchFilters)
        return api.downloadModel(url)
      }

      case ACTION_NAMES.UPLOAD_FILES: {
        if (this.fileUploadInput) {
          this.handleUpload = this._handleFilesUpload
          this.fileUploadInput.click()

          return new Promise((resolve) => (this._uploadResolver = resolve))
        }
        break
      }

      case ACTION_NAMES.UPLOAD_CSV: {
        if (this.fileUploadInput) {
          this.handleUpload = this._handleCSVUpload
          this.fileUploadInput.click()

          return new Promise((resolve) => {
            this._uploadResolver = resolve
          })
        }
        break
      }
      case ACTION_NAMES.POST_SELECTED: {
        if (confirm && !window.confirm(confirm.replace('%s', selected.length)))
          return
        api.connector[method.toLowerCase()](url, { [actionName]: selected })
        break
      }
      case ACTION_NAMES.DELETE_SELECTED: {
        this.handleRowsDelete(action, selected)
        break
      }
      case ACTION_NAMES.SAVE_FILTERS: {
        let title = window.prompt(
          'Please enter a title',
          'Unnamed search filter',
        )
        if (title === null) return

        const { modelName } = this.props
        const { encodedSearchFilters } = this.state

        const { request } = api.connector[method.toLowerCase()](url, {
          data: {
            title,
            model: modelName,
            filterString: encodedSearchFilters,
          },
        })

        return request
      }
      default:
        break
    }
  }

  handleToggleRow = (row) => {
    this.setState((prevState) => {
      let prevSelected = prevState.selected
      let selected = [...prevSelected]
      let index = prevSelected.indexOf(row._id)
      if (index === -1) {
        selected.push(row._id)
      } else {
        selected.splice(index, 1)
      }

      return { selected }
    })
  }

  handleSelectAll = (e, visibleRows = []) => {
    this.setState((prevState) => {
      const { selected, rowData = [] } = prevState
      const numRows = rowData.length

      if (
        selected.length === numRows ||
        visibleRows.length === selected.length
      ) {
        return { selected: [] }
      }

      return { selected: visibleRows.map((row) => row._id) }
    })
  }

  handleRowDelete = async (row) => {
    const { api, actions } = this.props
    const action = actions.find(
      ({ action }) => action === ACTION_NAMES.DELETE_SINGLE,
    )

    const actionRequest = await processActionRequest(action, api, row)

    if (!actionRequest) return
    await actionRequest.request

    let { rowData } = this.state
    rowData = [...rowData]
    const index = rowData.findIndex((otherRow) => row._id === otherRow._id)

    if (index >= 0) {
      rowData.splice(index, 1)
      this.setState({ rowData })
    }
  }

  handleRowDuplicate = async (row) => {
    const { api, actions } = this.props
    const action = actions.find(
      ({ action }) => action === ACTION_NAMES.DUPLICATE,
    )

    processActionRequest(action, api, row)
  }

  handleRowsDelete = async (action, selected) => {
    const confirm = window.confirm(
      'Are you sure you want to delete the selected rows?',
    )

    if (!confirm) {
      return
    }

    const { api } = this.props
    let { rowData = [] } = this.state
    const { action: actionName, url, method = 'POST' } = action

    const { request } = api.connector[method.toLowerCase()](url, {
      data: { [actionName]: selected },
    })
    const response = await request

    if (!response) {
      return
    }

    rowData = rowData.filter(({ _id }) => !selected.includes(_id))

    this.setState({ selected: [] })
    this.setState({ rowData })
  }

  handleUpload = async () => {} // to be dynamically assigned

  _handleCSVUpload = async () => {
    if (
      !this.fileUploadInput ||
      !this.fileUploadInput.files ||
      !this.fileUploadInput.files.length
    )
      return

    const { api, actions } = this.props

    const action = actions.find(
      ({ action }) => action === ACTION_NAMES.UPLOAD_CSV,
    )
    const { url } = action

    const file = this.fileUploadInput.files[0]
    this.fileUploadInput.value = ''

    this._resolveUpload(api.uploadModelData(url, file))
  }

  _handleFilesUpload = async () => {
    if (
      !this.fileUploadInput ||
      !this.fileUploadInput.files ||
      !this.fileUploadInput.files.length
    )
      return

    const { api, actions, modelName = [] } = this.props

    const action = actions.find(
      ({ action }) => action === ACTION_NAMES.UPLOAD_FILES,
    )
    const { url } = action

    const files = [...this.fileUploadInput.files]
    this.fileUploadInput.value = ''
    const uploadTags = Array.isArray(modelName) ? modelName : [modelName]

    let req = {
      request: Promise.all(
        files.map((file) => {
          const { request } = api.uploadMedia(url, file, uploadTags)
          return request
        }),
      ),
    }

    this._resolveUpload(req)
  }

  _handleFileUpload = async () => {
    const { api, actions } = this.props

    const action = actions.find(
      ({ action }) => action === ACTION_NAMES.CREATE_FROM_FILE,
    )
    const { url } = action

    const file = this.fileUploadInput.files[0]
    this.fileUploadInput.value = ''

    this._resolveUpload(api.createModelEntryFromFile(url, file))
  }

  _resolveUpload = (req) => {
    if (this._uploadResolver) {
      this._uploadResolver(req)
      this._uploadResolver = undefined
    }
  }

  _requestRows = debounce(async (query) => {
    const { api, modelName, onAPICall } = this.props
    const { request, cancel } = api.getModelListData(modelName, query || '')

    if (this.pendingRowRequestCancel) this.pendingRowRequestCancel()

    this.pendingRowRequestCancel = cancel

    try {
      let data = await request

      if (onAPICall) onAPICall(data)

      let { rowData, page, rowsPerPage, numRows } = data

      this.setState({ isLoading: false, rowData, page, rowsPerPage, numRows })
    } catch (e) {
      this.setState({ isLoading: false, error: e })
    }
  }, 500)

  handlePageChange = async (event, page) => {
    this.fetchRows({ page })
  }

  handleSelectFilters = (filters) => {
    this.fetchRows(filters ? decodeFilters(filters) : null)
  }

  handleSearchReqest = async (search) => {
    let {
      searchFilters,
      searchFilters: { search: prevSearch = '' },
    } = this.state

    if (search.length < 3 && prevSearch.length < 3) {
      this.setState({ searchFilters: { ...searchFilters, search } })
      return
    }
    this.fetchRows({ search })
  }

  handleSortRequest = async (orderBy, orderDir) => {
    this.fetchRows({ orderBy, orderDir })
  }

  handleColumnsChangeRequest = async (columns) => {
    const { api, modelName } = this.props
    api.customizeColumns(columns, modelName)
  }

  handleFilterRequest = async (filter) => {
    let {
      searchFilters: { filters },
    } = this.state
    filters = { ...filters, ...filter }

    Object.keys(filters).forEach((key) => {
      if (filters[key] === undefined || filters[key].length === 0)
        delete filters[key]
    })

    if (Object.keys(filters).length === 0) filters = undefined

    this.fetchRows({ filters })
  }

  handleRowsPerPageChange = (event) => {
    const rowsPerPage = event.target.value
    this.fetchRows({ rowsPerPage })
  }

  render() {
    const {
      className,
      isRowDeletable,
      isRowDuplicatable,
      isRowSelectable,
      selected,
      actionsFiltered,
      rowData,
      columnData,
      inlineEditableColumns,
      numRows,
      page,
      rowsPerPage,
      searchFilters,
      isLoading,
      detailUrl,
      encodedSearchFilters,
      disabledActions,
    } = this.state

    const { filters, search, orderBy, orderDir } = searchFilters

    const {
      selectedLanguage,
      supportedLanguages,
      selectMultiple = false,
      notes,
      history,
      isCMS,
      actions,
      savedFilters,
    } = this.props

    const saveFiltersAction = findAction(actions, ACTION_NAMES.SAVE_FILTERS)

    return (
      <div className={className}>
        {notes && <ModelNote {...notes} />}

        <EnhancedTable
          inlineEditableColumns={inlineEditableColumns}
          onInlineChange={this.handleInlineChange}
          isRowSelectable={isRowSelectable}
          isRowDuplicatable={isRowDuplicatable}
          isRowDeletable={isRowDeletable}
          columnData={columnData}
          rowData={rowData}
          actions={actionsFiltered}
          disabledActions={disabledActions}
          savedFilters={saveFiltersAction && savedFilters}
          encodedSearchFilters={encodedSearchFilters}
          onActionRequested={this.handleActionRequest}
          onRowToggled={this.handleToggleRow}
          onDeleteRowRequested={this.handleRowDelete}
          onDuplicateRowRequested={this.handleRowDuplicate}
          onSelectAllRequested={this.handleSelectAll}
          onSelectFiltersRequested={this.handleSelectFilters}
          selected={selected}
          supportedLanguages={supportedLanguages}
          selectedLanguage={selectedLanguage}
          page={page}
          history={history}
          rowsPerPage={rowsPerPage}
          numRows={numRows}
          orderBy={orderBy}
          orderDir={orderDir}
          filters={filters}
          detailUrl={detailUrl}
          onRowsPerPageChangeRequest={this.handleRowsPerPageChange}
          onPageChangeRequest={this.handlePageChange}
          onSearchRequested={this.handleSearchReqest}
          onSortRequest={this.handleSortRequest}
          onFilterRequest={this.handleFilterRequest}
          onColumnsChangeRequest={this.handleColumnsChangeRequest}
          searchText={search}
          isLoading={isLoading}
          isCMS={isCMS}
        />
        <input
          ref={(ref) => (this.fileUploadInput = ref)}
          style={{ display: 'none' }}
          type="file"
          multiple={selectMultiple}
          onChange={(...params) => this.handleUpload(...params)}
        />
      </div>
    )
  }
}

export default withLanguage(ModelList)
