import React, { useState } from 'react'
import FieldFactory from '../../atom/FieldFactory/FieldFactory'
import TablePagination from '@material-ui/core/TablePagination'

const entriesPerPage = 5

const Table = (props) => {
  const { value, value: { rowData = [] } = {}, ...rest } = props
  const [pageIndex, setPageIndex] = useState(0)
  const slicedRowData = rowData.slice(entriesPerPage * pageIndex, entriesPerPage * (pageIndex + 1))

  const _handleChangePage = (e, pageIndex) => {
    setPageIndex(pageIndex)
  }

  return (
    <React.Fragment>
      {FieldFactory.getFieldByType({
        ...rest,
        value: { ...value, rowData: slicedRowData },
      })}
      <TablePagination
        component="div"
        count={rowData.length}
        rowsPerPage={5}
        rowsPerPageOptions={[5]}
        page={pageIndex}
        onChangePage={_handleChangePage}
      />
    </React.Fragment>
  )
}

export default Table
