import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@material-ui/core/TextField'
import Chip from '@material-ui/core/Chip'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import { insertBetween } from '../../../../shared/utils'
import colors from '../../../../shared/assets/styles/colors'

const styles = (theme) => ({
  root: {
    position: 'relative',
    minHeight: 34,
  },
  pseudo: {
    visibility: 'hidden',
    position: 'absolute',
    whiteSpace: 'pre',
  },
  textField: {
    minHeight: 34,
  },
  Input: {
    display: 'inline',
  },
  input: {
    display: 'inline',
    marginLeft: 5,
    minHeight: 21,
  },
  chip: {
    marginRight: 2,
    marginBottom: 2,
  },
  or: {
    color: colors.DarkGrey,
    marginRight: 2,
    display: 'inline',
  },
})

const minWidth = 50

class MultiInput extends React.Component {
  state = { input: '', width: minWidth }

  constructor(props) {
    super(props)

    this.refPseudo = React.createRef()
    this.refInput = React.createRef()
  }

  handleChange = (e) => {
    this._updateInput(e.target.value)
  }

  handleDelete = (i) => () => {
    let { value, onChange } = this.props
    if (!Array.isArray(value)) value = [value]

    value = [...value]
    value.splice(i, 1)

    onChange &&
      onChange({
        target: {
          value,
        },
      })
  }

  handleKeyDown = (e) => {
    let { input } = this.state

    switch (e.keyCode) {
      case 13: //Enter
        e.preventDefault()
        this._addValue(input)
        break
      case 8: //Backspace
        let { value = [] } = this.props
        if (input === '' && value.length > 0) {
          e.preventDefault()
          value = [...value]
          input = value.pop()
          this._updateInput(input.slice(0, -1))
          this._updateValues(value)
        }
        break
      default:
        break
    }
  }

  handleBlur = () => {
    const { input } = this.state
    this._addValue(input)
  }

  _updateInput = (input) => {
    if (input === '') {
      this.setState({ input, width: minWidth })
      return
    }

    let style = window.getComputedStyle(this.refInput.current)
    this.refPseudo.current.style['font'] = style['font']

    this.refPseudo.current.innerHTML = input
    let { width } = this.refPseudo.current.getBoundingClientRect()

    this.setState({ input, width: Math.max(minWidth, Math.ceil(width + 2)) })
  }

  _addValue(input) {
    if (!input) return

    let { value = [] } = this.props

    if (!Array.isArray(value)) value = value ? [value] : []

    this._updateInput('')
    this._updateValues([...value, input])
  }

  _updateValues(values) {
    let { onChange } = this.props

    onChange &&
      onChange({
        target: {
          value: values,
        },
      })
  }

  render() {
    const { input, width } = this.state
    let { classes, value = [] } = this.props

    if (!Array.isArray(value)) value = value ? [value] : []

    return (
      <FormControl fullWidth className={classes.root}>
        <span className={classes.pseudo} ref={this.refPseudo}>
          {input}
        </span>

        <TextField
          className={classes.textField}
          onKeyDown={this.handleKeyDown}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          value={input}
          inputProps={{
            style: { width },
            className: classes.input,
            ref: this.refInput,
          }}
          // eslint-disable-next-line
          InputProps={{
            className: classes.Input,
            startAdornment: (
              <span>
                {insertBetween(
                  value.map((v, i) => (
                    <Chip
                      className={classes.chip}
                      label={v}
                      id={'dude3'}
                      name={'dude'}
                      onDelete={this.handleDelete(i)}
                    />
                  )),
                  <Typography className={classes.or}>OR</Typography>,
                )}
              </span>
            ),
          }}
        ></TextField>
      </FormControl>
    )
  }
}

export default withStyles(styles)(MultiInput)
