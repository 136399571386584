import React from 'react'
import { createRoot } from 'react-dom/client'
import './shared/assets/styles/global.css'
import App from './App'

const root = createRoot(document.getElementById('root'))
root.render(<App />)

const errorsToWarn = ['Warning:']
const oldConsError = console.error
console.error = function (...args) {
  let toWarn = false

  if (typeof args[0] === 'string') {
    errorsToWarn.forEach(function (_s) {
      if (args[0].startsWith(_s)) {
        toWarn = true
      }
    })
  }

  toWarn ? console.warn(...args) : oldConsError(...args)
}
