import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from './TableBody'
import TablePagination from '@material-ui/core/TablePagination'
import EnhancedTableHead from './EnhancedTableHead'
import EnhancedTableToolbar from './EnhancedTableToolbar'
import PageProgress from '../../../shared/components/PageProgress'
import Colors from '../../../shared/assets/styles/colors'

const styles = (theme) => ({
  root: {
    position: 'relative',
  },
  tableWrapper: {
    maxHeight: `calc(100vh - ${theme.spacing.unit * 26}px)`,
    overflowX: 'scroll',
    '-ms-overflow-style': 'scrollbar',
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: 10,
      height: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 5,
      backgroundColor: 'rgba(0,0,0,.5)',
      '-webkit-box-shadow': '0 0 1px rgba(255,255,255,.5)',
    },
    [theme.breakpoints.down('xs')]: {
      maxHeight: '100%',
    },
  },
  table: { tableLayout: 'auto' },
  rowLink: {
    cursor: 'pointer',
  },
  cell: {
    maxWidth: '20vW',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  chip: {
    marginBottom: 1,
  },
  chipLabel: {
    paddingTop: theme.spacing.unit * 0.5,
    paddingBottom: theme.spacing.unit * 0.5,
    display: 'block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  colorDrop: {
    width: theme.spacing.unit * 2,
    height: theme.spacing.unit * 2,
  },
  progress: {
    width: '100%',
    position: 'absolute',
  },
  paginationToolbar: {
    paddingRight: '62px',
  },
  draft: {
    borderLeft: `7px solid ${Colors.Orange}`,
  },
  published: {
    borderLeft: `7px solid ${Colors.Aqua}`,
  },
  notVisible: {
    borderLeft: `7px solid ${Colors.CoolGrey}`,
    opacity: 0.5,
  },
})

class EnhancedTable extends React.Component {
  constructor(props) {
    super(props)

    const { columnData } = this.props

    let selectedColumnsDefault = columnData
      .filter((col) => col.defaultColumn === true)
      .map((col) => col.name)

    this.state = {
      searchText: '',
      selectedColumns: selectedColumnsDefault,
    }
  }

  handleInlineFieldClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  handleRequestSort = (event, property) => {
    let { orderDir, orderBy } = this.props

    orderDir = orderBy === property ? -orderDir : -1

    const { onSortRequest } = this.props
    onSortRequest && onSortRequest(property, orderDir)
  }

  handleColumnsChange = (value) => {
    const { selectedColumns } = this.state
    this.setState({ selectedColumns: value })

    const { onColumnsChangeRequest } = this.props

    let columns = selectedColumns.reduce((acc, column) => {
      acc[column] = false
      return acc
    }, {})

    value.forEach((column) => {
      if (column in columns) delete columns[column]
      else columns[column] = true
    })

    onColumnsChangeRequest && onColumnsChangeRequest(columns)
  }

  handleEnumFilterChange = (colName, selectedOptions) => {
    const { onFilterRequest } = this.props
    onFilterRequest({ [colName]: selectedOptions })
  }

  handleDeleteRow = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const row = this.findRowByEvent(e)

    const { onDeleteRowRequested } = this.props
    row && onDeleteRowRequested && onDeleteRowRequested(row)
  }

  handleDuplicateRow = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const row = this.findRowByEvent(e)

    const { onDuplicateRowRequested } = this.props
    row && onDuplicateRowRequested && onDuplicateRowRequested(row)
  }

  handleToggleRow = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const row = this.findRowByEvent(e)

    const { onRowToggled } = this.props
    row && onRowToggled && onRowToggled(row)
  }

  findRowByEvent = (e) => {
    const { currentTarget: { id } = {} } = e

    if (!id) return undefined

    const { rowData } = this.props

    return rowData.find((r) => r._id === id)
  }

  render() {
    const { classes } = this.props
    const { selectedColumns } = this.state

    const {
      rowData,
      columnData,
      actions,
      onActionRequested,
      onSelectFiltersRequested,
      isRowDeletable,
      isRowSelectable,
      isRowDuplicatable,
      selected,
      onSelectAllRequested,
      onSearchRequested,
      inlineEditableColumns,
      onInlineChange,
      supportedLanguages,
      selectedLanguage,
      numRows,
      rowsPerPage,
      page,
      history,
      onRowsPerPageChangeRequest,
      onPageChangeRequest,
      orderDir,
      orderBy,
      searchText,
      isLoading,
      filters,
      detailUrl,
      isCMS,
      savedFilters,
      encodedSearchFilters,
      disabledActions,
    } = this.props

    return (
      <Paper className={classes.root}>
        <PageProgress visible={isLoading} className={classes.progress} />

        <EnhancedTableToolbar
          selectedColumns={selectedColumns}
          numSelectedRows={selected ? selected.length : 0}
          columnData={columnData}
          searchText={searchText}
          onColumnsChange={this.handleColumnsChange}
          onSearchChange={onSearchRequested}
          actions={actions}
          savedFilters={savedFilters}
          encodedSearchFilters={encodedSearchFilters}
          onActionRequested={onActionRequested}
          onSelectFiltersRequested={onSelectFiltersRequested}
          disabledActions={disabledActions}
        />

        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <EnhancedTableHead
              isRowSelectable={isRowSelectable}
              isRowDeletable={isRowDeletable}
              isRowDuplicatable={isRowDuplicatable}
              onSelectAllRequested={onSelectAllRequested}
              onEnumFilterChangeRequested={this.handleEnumFilterChange}
              selectedEnumFilters={filters}
              numSelectedRows={selected ? selected.length : 0}
              numRows={rowData && rowData.length}
              columnData={columnData}
              selectedColumns={selectedColumns}
              orderDir={orderDir || -1}
              orderBy={orderBy || columnData.find(col => col.defaultColumn).name}
              onRequestSort={this.handleRequestSort}
              supportedLanguages={supportedLanguages}
              selectedLanguage={selectedLanguage}
              visibleRows={rowData}
            />
            <TableBody
              history={history}
              classes={classes}
              columnData={columnData}
              rowData={rowData}
              isRowDeletable={isRowDeletable}
              isRowDuplicatable={isRowDuplicatable}
              isRowSelectable={isRowSelectable}
              selectedColumns={selectedColumns}
              inlineEditableColumns={inlineEditableColumns}
              selectedLanguage={selectedLanguage}
              onInlineChange={onInlineChange}
              selected={selected}
              detailUrl={detailUrl}
              onDeleteRow={this.handleDeleteRow}
              onDuplicateRow={this.handleDuplicateRow}
              onToggleRow={this.handleToggleRow}
              onInlineFieldClick={this.handleInlineFieldClick}
              isCMS={isCMS}
            />
          </Table>
        </div>
        <TablePagination
          component="div"
          count={numRows}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[25, 50, 100, 200].filter(
            (n) => n === 25 || n / numRows < 2,
          )}
          page={page}
          onChangePage={onPageChangeRequest}
          onChangeRowsPerPage={onRowsPerPageChangeRequest}
          classes={{ toolbar: classes.paginationToolbar }}
        />
      </Paper>
    )
  }
}

export default withStyles(styles)(EnhancedTable)
