import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'
import Select from '@material-ui/core/Select/Select'
import IconButton from '@material-ui/core/IconButton/IconButton'
import Icon from '@material-ui/core/Icon/Icon'

const styles = ({ spacing: { unit } }) => ({
  inputRoot: {
    maxHeight: '2rem',
  },
  select: {
    padding: 0,
    marginLeft: -unit,
  },
  button: {
    width: unit * 2.5,
    height: unit * 2.5,
    padding: 0,
  },
  icon: {
    fontSize: 'inherit',
  },
})

class SelectWithIconButton extends React.Component {
  render() {
    let { classes, icon, renderButton, children, ...rest } = this.props

    return (
      <Select
        classes={{ select: classes.select }}
        disableUnderline
        IconComponent={() => null}
        displayEmpty
        inputProps={{ classes: { root: classes.inputRoot } }}
        renderValue={
          renderButton
            ? () => renderButton({ classes })
            : () => (
                <IconButton className={classes.button}>
                  <Icon className={classes.icon}>{icon}</Icon>
                </IconButton>
              )
        }
        {...rest}
      >
        {children}
      </Select>
    )
  }
}

export default withStyles(styles)(SelectWithIconButton)
